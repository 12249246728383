<!--
/****
/* Individual products are loaded with the getProduct mixin.
/* For instructions related to connecting your invetory to
/* Nacelle, please refer to:
/*
/* https://docs.getnacelle.com/getting-started.html#_2-product-settings
/****
-->
<template>
  <div class="p-product-page">
    <div
      class="nosto_page_type"
      style="display: none;"
    >
      product
    </div>
    <section class="u-margin-bottom-xx-large">
      <product-info
        :product="product"
        :products="myCollectionData.products"
        :size.sync="selectedRingSize"
        :grid-layout="true"
        @update:variant="currentProductVariant = $event"
      >
        <template v-slot:default="slotProps">
          <!--
            uses scoped slots to pass the props back up to this scope
            @link https://vuejs.org/v2/guide/components-slots.html#Scoped-Slots

            geofence is assumed denied until we get a confirmed positive
            response geoip api

            if a product is NOT in stock, show back in stock notification prompt
          --> 
          <back-in-stock
            v-if="showBackInStock(slotProps, variantAvailable)"
            :product="slotProps.product"
            :variant="slotProps.variant"
          />
          <product-add-to-cart-button
            v-else
            v-bind="{ 
              ...slotProps,
              allOptionsSelected: true,
              variant: undefined,
            }"
          />
          <!--
            don't show this until we have a confirmed negative response from
            geoip api
          -->
          <template v-if="geofenceAllowed === false">
            {{ collectionLabel }} {{ geofenceCodes | formatCodes }}
          </template>
        </template>
        <template v-slot:rating>
          <!-- Yotpo Star Ratings -->
          <star-rating
            :product="product"
            class="u-margin-bottom-x-small"
            style="display: inline-block"
          />
        </template>

        <template v-slot:recommendations>
          <rebuy-related-products
            :product="product"
          />
        </template>
      </product-info>
    </section>
    <a v-utm-adder id="more" />
    <page-content
      v-if="showPageContent"
      :page="productPageContent"
      :products="pdpCollectionData.products"
    >
      <rebuy-trending-products-carousel
        slot="on-the-other-hand"
        class="l-container u-margin-bottom-x-large"
        title="On the Other Hand"
        :product="product"
      />
      <section
        slot="reviews"
        class="l-container u-margin-bottom-x-large"
      >
        <client-only>
          <!-- Yotpo Reviews -->
          <reviews-widget
            v-if="product"
            :product="product"
            :pathname="$route.fullPath"
          />
        </client-only>
      </section>
    </page-content>
  </div>
</template>

<script>
import BackInStock from '~/components/nacelle/BackInStock'
import geofenceProduct from '~/mixins/geofenceProduct'
import get from 'lodash/get'
import gtmProductEvents from '~/mixins/gtmProductEvents'
import nmerge from '~/utils/merge-requests'
import nosto from '~/mixins/nosto'
import productHandle from '~/mixins/productHandle'
import productMetafields from '~/mixins/productMetafields'
import productPageDetails from '~/mixins/productPageDetails'
import ProductRecommendations from '~/components/products/ProductRecommendations'
import productVariantAvailable from '~/mixins/productVariantAvailable'
import Row from '@/components/layout/Row'
import sezzle from '~/mixins/sezzle'
import viewEvent from '~/mixins/viewEvent'
import { getCollectionFromProps } from '~/mixins/getCollectionFromProps'
import getProductInCard from '~/mixins/getProductInCard'
import { RebuyTrendingProductsCarousel, RebuyRelatedProducts } from '~/components/integrations/rebuy'
export default nmerge({
  components: {
    RebuyRelatedProducts,
    RebuyTrendingProductsCarousel,
    BackInStock,
    Row,
    ProductRecommendations
  },
  mixins: [
    getCollectionFromProps('myCollection'),
    sezzle(),
    productMetafields,
    productPageDetails,
    viewEvent('product'),
    productHandle,
    gtmProductEvents,
    // defines geofenceAllowed, geofenceCodes
    geofenceProduct,
    // defines variantAvailable
    productVariantAvailable,
    getProductInCard
  ],
  computed: {
    pdpCollectionTitle() {
      return get(this.myCollectionData, 'collection.title')
    },
    rowData() {
      if (this.productPageContent) {
        let sections = this.productPageContent.sections
        for (let section of sections) {
          if (section.contentType == 'row') {
            return section
          }
        }
        return this.productPageContent[1]
      }
    }
  },
  // head() is defined in ~/mixins/productHandle.js
})
</script>

<style lang="scss" scoped>
.p-product-page {
  padding: $section-padding;
  &__details {
    @include themify($themes) {
      background-color: themed('background', 'xx-light');
    }
  }
}
</style>
