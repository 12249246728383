<template>
  <div
    v-if="showRecos"
    class="c-rebuy-trending-products-carousel u-margin-bottom-medium"
  >
    <h3 class="t-heading-3 u-text-align-center u-margin-bottom-small">{{ title }}</h3>
    <product-carousel
      :products="products"
    />
  </div>
</template>

<script>
import get from 'lodash/get'
import ProductCarousel from '@/components/carousel/ProductCarousel'
import uniqBy from 'lodash/uniqBy'
export default {
  name: 'RebuyTrendingProductsCarousel',
  props: {
    title: {
      type: String,
      default: 'Trending Products'
    },
  },
  components: {
    ProductCarousel
  },
  data() {
    return {
      recos: null
    }
  },
  computed: {
    showRecos() {
      return !!(this.recos && this.recos.length)
    },
    products() {
      const recos = this.recos
      return uniqBy(recos, 'handle')
    }
  },
  async fetch() {
    this.recos = await this.$api.getRecosTrending()
  },
  // mounted() { this.$fetch() }
}
</script>

<style lang="scss" scoped>
.c-rebuy-trending-products-carousel {
  margin-top: rem(20px);
}
</style>
