<template>
    <div class="c-engraving-screen">
      <div
        class="c-engraving-screen__ring-count"
      >
        <button
          class="c-engraving-screen__exit-button"
          aria-label="close engraving dialog."
          @click="closeModal"
        >
          &#215;
        </button>
      </div>
      <div
        ref="wrapper"
        class="c-engraving-screen__wrapper"
        :class="{
          'c-engraving-screen__wrapper--scrollable': !scrolledToBottom
        }"
      >
        <div class="c-engraving-screen__heading">
          <h2
            v-if="mappedEngraving && mappedEngraving.data "
            class="t-heading-5 u-margin-bottom-xx-small"
          >
            {{ mappedEngraving.data.engravingTitle }}
          </h2>
          <!-- eslint-disable vue/no-v-html -->
          <p
            v-if="mappedEngraving && mappedEngraving.data "
            class="t-heading-9 t-heading-9--light u-margin-bottom-xx-small "
            v-html="mappedEngraving.data.engravingParagraph"
          />
          <div v-if="productImage" :class="dropdownTheme && !isMobile ? 'l-grid l-grid--2-col-fixed' : ''">
            <div
              class="c-engraving-screen__product-image"
              v-if="(isMobile && !showBack) || !isMobile"
            >
              <template v-if="engravingImage">
                <div class="c-engraving-screen__engraving-image-wrapper">
                  <nuxt-img
                    provider="shopify"
                    :src="engravingImage.src"
                    :alt="engravingImage.altText || 'Engraving image'"
                    width="400"
                    class="c-engraving-screen__engraving-image"
                  />
                </div>
                <div
                  v-if="this.eLocation.toLowerCase() === 'exterior'"
                  class="c-engraving-screen__product-text-exterior"
                  :style="{ fontFamily: `Enso ${eFont}` }"
                >
                    <div
                      v-for="(eTextVal, index) in visualTextVal"
                      :key="index"
                      class="c-engraving-screen__product-text-exterior-line"
                      :style="{
                        transform: `rotate(${
                          -1 * rotateLetter(
                            eTextVal,
                            eTextVal.length - 1
                          ) / 2
                        }deg)`
                      }"
                    >
                      <span
                        v-for="(eLetter, letterIndex) in eTextVal.split('')"
                        :key="letterIndex"
                        class="c-engraving-screen__product-letter"
                        :style="{
                          transform: `rotate(${rotateLetter(eTextVal, letterIndex)}deg) translate(-50%)`,
                          backgroundColor: engravingColor,
                          textShadow: engravingTextShadow
                        }"
                      >
                        <span
                          class="c-engraving-screen__product-letter-child"
                          :style="{transform: `rotate() rotateY()`,backgroundColor: engravingColor,backgroundClip: 'text'}"
                        >
                          {{ eLetter }}
                        </span>
                      </span>
                    </div>
                </div>
              </template>
              <template v-else-if="fontImage">
                <font-image
                  :src="fontImage.src"
                  :alt-text="fontImage.altText"
                />
              </template>
            </div>
            <div v-if="showThemeImage" class="c-engraving-screen__theme-image-wrapper">
              <nuxt-img
                provider="shopify"
                :src="selectedTheme.themeImage"
                :alt="engravingImage ? engravingImage.altText : 'Engraving theme image'"
                width="300"
                class="c-engraving-screen__theme-image"
              />
              <p
                v-if="!isMobile"
                class="t-paragraph c-engraving-screen__theme-image-label"
              >
                Back
              </p>
            </div>
            <btn
              @click="showBack = !showBack"
              v-if="isMobile && themeable && dropdownTheme"
              :button-size="['small', 'thin']"
            >
              View {{ showBack ? "Front" : "back" }}
            </btn>
          </div>
          <p
            v-if="engravingMessage"
            class="c-engraving-screen__message"
          >
            {{ engravingMessage }}
          </p>
        </div>
        <div class="c-engraving-screen__step">
          <h3 class="u-margin-bottom-x-small c-engraving-screen__step-subtitle">
            <span class="t-paragraph c-engraving-screen__step-subtitle-span">Text</span>
            <span
              class="u-text-align-right c-engraving-screen__step-subtitle-spantext"
              v-html="charCountText"
            ></span>
          </h3>
          <engraving-text-input
            v-for="(i,index) in numberOfEngravingLines"
            :key="keyVal + i"
            :line-number="index"
            :length-limit="lengthLimit"
            :value="textVal[index]"
            :focus="cursorPosition.lineLocation == index ? focus : false"
            :cursor-position.sync="cursorPosition"
            :font="`Enso ${eFont}`"
            :allowed-symbols="symbols.map(({ symbol }) => symbol)"
            :required-text="requiredText"
            :charCountVisible="false"
            @input="textUpdate"
            @cursorPosition="updateCursorPosition"
            @charCount="updateCharCountText"
            :current-theme="selectedTheme"
            />
        </div>
        <div class="c-engraving-screen__step">
          <h3 class="t-paragraph u-margin-bottom-xx-small c-engraving-screen__step-subtitle">
            <span class="t-paragraph c-engraving-screen__step-subtitle-span">Location</span>
          </h3>
          <!-- FIXME this should use the v-model protocol. it appears that this
            component is managing state that it shouldn't be concerned with -->
          <radio-button
            :options="locations"
            @radioChange="locationChange"
           />
        </div>
        <div class="c-engraving-screen__step">
          <h3 class="u-margin-bottom-xx-small c-engraving-screen__step-subtitle">
            <span class="t-paragraph c-engraving-screen__step-subtitle-span">Font </span>
            <span class="c-engraving-screen__step-subtitle-spantext">{{ dropdownFont }}</span>
          </h3>
          <engraving-font-dropdown
            :key="currentRingIndex"
            :value="dropdownFont"
            :fonts="fonts"
            @input="fontChange"
          />
          <div
            v-if="requiredFont"
            class="c-engraving-screen__error"
          >
            * Font is required.
          </div>
        </div>
        <div
          class="c-engraving-screen__step"
          v-if="themeable"
        >
          <h3 class="u-margin-bottom-xx-small c-engraving-screen__step-subtitle">
            <span class="t-paragraph c-engraving-screen__step-subtitle-span">Theme</span>
            <span class="c-engraving-screen__step-subtitle-spantext">
              {{ dropdownTheme ? dropdownTheme : 'Select optional engraving theme' }}
            </span>
          </h3>
          <!-- :value="dropdownTheme" -->
          <engraving-theme-dropdown
            v-model="dropdownTheme"
            :themes="allowedThemes"
            />
            <!-- @input="themeChange" -->
        </div>
        <div class="c-engraving-screen__step">
          <h3 class="t-paragraph u-margin-bottom-xx-small c-engraving-screen__step-subtitle">
            <span class="t-paragraph c-engraving-screen__step-subtitle-span">Symbols</span>
          </h3>
          <engraving-keyboard
            type="circle"
            :font="`Enso ${eFont}`"
            :buttons="symbols"
            @buttonClick="addKeyText"
          />
        </div>
        <div class="c-engraving-screen__add-to-cart u-margin-bottom-xx-small">
          <btn
            class="c-engraving-screen__save-button"
            :button-size="['thin', 'large']"
            @click="addToCart"
          >
            {{ buttonText }}
          </btn>
        </div>
        <div class="c-engraving-screen__return-btn">
          <cart-flyout-checkout-button
            v-if="upsell"
            checkout-text="Cancel and go to checkout"
            button-type="link"
            @click="addToCart"
          />
        </div>
      </div>
    </div>
  </template>
  <script>
  import { UrlFilters } from '@/mixins/cdnMixins'
  import Btn from '@/components/button/Btn'
  import CartFlyoutCheckoutButton from '~/components/cart/CartFlyoutCheckoutButton'
  import FontImage from '@/components/engraving/FontImage'
  import get from 'lodash/get'
  import getContentItem from '~/mixins/getContentItem'
  import getEngravingPrice from '@/mixins/getEngravingPrice'
  import intersection from 'lodash/intersection'
  import ProductPrice from '@/components/productPrice/ProductPrice'
  import RadioButton from '@/components/radioButton/RadioButton'
  import isMobile from '@mixins/isMobile';
  import { mapActions, mapState, mapMutations } from 'vuex'
  export default {
    components: {
      RadioButton,
      Btn,
      EngravingFontDropdown: () => import(
        /* webpackPrefetch: true */
        '@/components/engraving/EngravingFontDropdown'
      ),
      EngravingThemeDropdown: () => import(
        /* webpackPrefetch: true */
        '@/components/engraving/EngravingThemeDropdown'
      ),
      EngravingKeyboard: () => import(
        /* webpackPrefetch: true */
        '@/components/engraving/EngravingKeyboard'
      ),
      EngravingTextInput: () => import(
        /* webpackPrefetch: true */
        '@/components/engraving/EngravingTextInput'
      ),
      FontImage,
      ProductPrice,
      CartFlyoutCheckoutButton
    },
    mixins: [
      UrlFilters,
      getEngravingPrice,
      getContentItem('engravingContent', {
        handle:'engraving-content',
        type: 'engravingContent'
      }),
      isMobile
    ],
    props: {
      product: {
        type: Object,
        default: () => ({})
      },
      variant: {
        type: Object,
        default: () => ({})
      },
      isBundle: {
        type: [Boolean, Number],
        default: false
      },
      size: {
        type: [ Number, String ],
        default: 0
      },
      location: {
        type: String,
        default: ''
      },
      text: {
        type: String,
        default: ''
      },
      font: {
        type: String,
        default: ''
      },
      open: {
        type: Boolean,
        default: false,
      },
      addEngravingLabel: {
        type: String,
        default: 'SAVE'
      },
      allowedLocations: {
        type: Array,
        default: () => [
          'exterior',
        ]
      },
      currentRingIndex: {
        type: Number,
        default: 0
      },
      ringsLength: {
        type: Number,
        default: 1
      },
      upsell: { // This variable tells us if the user is coming from an upsell, so they get the discount
        type: Boolean,
        default: false
      },
      selectedItem: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        eLocation: 'exterior',
        requiredFont: false,
        dropdownFont: '',
        dropdownTheme: null,
        showBack: false,
        eFont: '',
        textVal: [""],
        visualTextVal: [""],
        keyVal: 10,
        cursorPosition: {charLocation:0, lineLocation:0},
        placeholderText: '',
        focus: false,
        noPaste: false,
        requiredText: false,
        engravingText: {},
        scrolledToBottom: false,
        charCountText: '',
      }
    },
    computed:{
      ...mapState('cart', [
        'engravingUpsell',
        'engravingUpsellPercentDiscount',
      ]),
      symbols() {
        let results = [
          { symbol:'੮', symbolName:'Airplane' },
          { symbol:'੯', symbolName:'Dumbbell' },
          { symbol:'ੰ', symbolName:'Flame' },
          { symbol:'ੱ', symbolName:'Flower' },
          { symbol:'ੲ', symbolName:'Moon and Starts' },
          { symbol:'ੳ', symbolName:'Arm Flex' },
          { symbol:'ੴ', symbolName:'Palm Tree' },
          { symbol:'ੵ', symbolName:'Puzzle Piece' },
          { symbol:'੶', symbolName:'Dog' },
          { symbol:'੷', symbolName:'Cat Face' },
          { symbol:'⚀', symbolName:'Skull' },
          { symbol:'★', symbolName:'Star' },
          { symbol:'✭', symbolName:'Outlined Star' },
          { symbol:'☰', symbolName:'3 Stars' },
          { symbol:'◆', symbolName:'Diamond' },
          { symbol:'❤', symbolName:'Heart' },
          { symbol:'♩', symbolName:'Eighth Note' },
          { symbol:'♪', symbolName:'Connected Eighth Notes' },
          { symbol:'☯', symbolName:'Yin Yang' },
          { symbol:'☶', symbolName:'Mountains' },
          // { symbol:'❧', symbolName:'Christmas Holly' },
          // { symbol:'❄', symbolName:'Snowflake' },
          // { symbol:'☏', symbolName:'Present' },
          { symbol:'✡', symbolName:'Star of David' },
          { symbol:'♓', symbolName:'Ichtchus Fish' },
          { symbol: '⚁', symbolName:'Cancer Ribbon' },
        ];
        if (this.product && this.product.handle) {
          if (!this.product.handle.includes('dual-tone')) {
            results.push({ symbol:'☨', symbolName:'Cross' },);
          }
        }
        return results;
      },
      mappedEngraving() {
        return this.engravingContent
      },
      productPrice() {
        if (this.product && this.product.priceRange || this.product.price) {
          const product = this.product
          return product && product.priceRange
            ? product.priceRange.min
            : product.price
        } else {
          return this.variant.price;
        }
      },
      fonts() {
        const fonts = get(this.engravingData, 'font')
        return fonts || []
      },
      locations() {
        const engravingData = this.engravingData
        const allowedLocations = this.allowedLocations
        let result = []
        if (engravingData && allowedLocations && allowedLocations.length) {
          result = intersection(
            allowedLocations,
            engravingData.location
          )
        } else if (engravingData) {
          result = engravingData.location
        }
        return result
      },
      engravingMessage() {
        const engravingData = this.engravingData
        let result = null
        if(engravingData && engravingData.modalMessage) {
          result = engravingData.modalMessage
        }
        return result
      },
      engravingData() {
        const product = this.product
        return get(product, 'metafieldsMap.engraving.value', {})
      },
      engravingColor() {
        const color = get(this.engravingData, 'textColor')
        
        return color ? color : '#fff'
      },
      engravingTextShadow() {
        const color = get(this.engravingData, 'textShadow')
        return color
      },
      eSize() {
        let result = this.size
        if (!result) {
          const option = this.variant.selectedOptions ? this.variant.selectedOptions.find((obj)=>{
            return obj.name === 'Size'
          }) : false;
          if (option) {
            result = option.value
          }
        }
        return result
      },
      lengthLimit() {
        const engravingData = this.engravingData
        const eLocation = this.eLocation
        const eSize = this.eSize
        const themeLength = get(this.selectedTheme, 'maxLength')
        if(themeLength) {
          return themeLength
        }
        return engravingData.characterLimit && eLocation && eSize
          ? get(engravingData, `characterLimit[${eLocation}][${eSize}]`, 10)
          : 10
      },
      fontImage() {
        if(this.isBundle) {
          return this.variant.featuredMedia
        } else {
          const media = get(this.product, 'media')
          let result = get(this.product, 'media[0]')
          for (let image of media || []) {
            if (
              image &&
              image.altText &&
              (
                image.altText.includes(this.eFont) ||
                image.altText.includes(this.eFont.split(" ")[0])
              )
            ) {
              if ((this.locations.length > 1) && this.eLocation) {
                if (
                  (this.eLocation.toLowerCase() == "interior") &&
                  image.altText.includes("Inside")
                ) {
                  result = image
                } else if (
                  (this.eLocation.toLowerCase() === "exterior") &&
                  image.altText.includes("Outside")
                ) {
                  result = image
                }
              } else {
                result = image
              }
            }
          }
          if (result)
            return result
          else
            return this.variant.featuredMedia
        }
      },
      engravingImage() {
        let engravingPreviewImage = this.product.metafieldsMap && this.product.metafieldsMap.engraving_preview_image ?  this.product.metafieldsMap.engraving_preview_image.value : null
        if (engravingPreviewImage) {
          return {
            src: engravingPreviewImage,
            altText: 'Engraving Image'
          }
        } else {
          return null
        }
      },
      selectedTheme(){
        const dropdownTheme = this.dropdownTheme
        const themes = this.allowedThemes
        let result = null
        if(dropdownTheme && themes && themes.length > 0) {
          result = themes.filter(e => e.theme == dropdownTheme)[0] || null
        }
        return result
      },
      productImage() {
        return this.engravingImage ? this.engravingImage : this.fontImage
      },
      numRings() {
        let numOfRings = this.ringsLength;
        return numOfRings > 1 ? numOfRings : 1
      },
      ringIndex() {
        let numOfRings = this.ringsLength;
        const cri = this.currentRingIndex;
        return numOfRings > 1 && cri >= 0 && cri < numOfRings ? cri : 0;
      },
      buttonText() {
        if (this.upsell &&
            this.ringsLength > this.ringIndex + 1 &&
            this.engravingUpsell) {
          return "Save & Continue"
        } else if (this.upsell &&
            this.ringsLength <= this.ringIndex + 1 &&
            this.engravingUpsell) {
          return "Save & Checkout"
        } else return this.addEngravingLabel
      },
      finalEngravingPrice() {
        if (this.engravingUpsell &&
            this.upsell) {
          return Math.floor(Number(this.engravingPrice) * (1 - this.engravingUpsellPercentDiscount) * 100) / 100;
        } else {
          return this.engravingPrice
        }
      },
      currentRingSize() {
        return  this.variant.selectedOptions.find((obj)=>obj.name = "Size").value
      },
      numberOfEngravingLines() {
        let engravingInfo = this.product.metafieldsMap && this.product.metafieldsMap.engraving ?  this.product.metafieldsMap.engraving.value : null;
        let numberOfLines = engravingInfo && engravingInfo.numberOfLines ? engravingInfo.numberOfLines : 1
        return numberOfLines
      },
      themeable() {
        const themeable = get(this.engravingData,'themeable')
        const themes = get(this.engravingData,'themes')
        let result = false
        let validThemes = null
        if(themes && themes.length > 0) {
          validThemes = themes.filter(e => e.image && e.theme) || null
        }

        if(themeable && validThemes) {
          result = true
        }
        
        return result
      },
      showThemeImage(){
        const isThemeable = this.themeable
        const isMobile = this.isMobile
        const dropdownTheme = this.dropdownTheme
        const showBack = this.showBack
        let result = false
        if((dropdownTheme && !isMobile) || (showBack && isMobile)) {
          result = true
        }
        return result
      },
      allowedThemes() {
        const result = get(this.engravingData,'themes')
        return result
      }
    },
    watch: {
      open(open) {
        if (open) {
          this.copyState()
        }
      },
      text() {
        this.copyState()
      },
      font() {
        this.copyState()
      },
      location() {
        this.copyState()
      },
      lengthLimit() {
        let result = []
        for (let t of this.textVal) {
          result.push(t.slice(0, this.lengthLimit))
        }
        this.textVal = result
      }
    },
    created() {
      this.copyState()
    },
    async mounted() {
      await this.$nextTick()
      if (this.$refs.wrapper) {
        this.$refs.wrapper.addEventListener('scroll', this.handleScroll)
      }
      this.insertAllEngravingLines()
    },
    beforeDestroy() {
      if (this.$refs.wrapper) {
        this.$refs.wrapper.removeEventListener('scroll', this.handleScroll)
      }
    },
    methods: {
      insertAllEngravingLines() {
        let engravingInfo = this.product.metafieldsMap && this.product.metafieldsMap.engraving ?  this.product.metafieldsMap.engraving.value : null;
        let numberOfLines = engravingInfo && engravingInfo.numberOfLines ? engravingInfo.numberOfLines : 1
        for (let i = 0; i < numberOfLines; i++) {
          if (i > this.textVal.length) {
            this.textVal.push("")
          }
        }
      },
      handleScroll(e) {
        const scrollTop = e.target.scrollTop
        const offsetHeight = e.target.offsetHeight
        const scrollHeight = e.target.scrollHeight
        this.scrolledToBottom = (scrollTop + offsetHeight) === scrollHeight
      },
      copyState() {
        if (this.location) {
          this.eLocation = this.location
        } else {
          this.eLocation = this.allowedLocations[0]
        }
        this.dropdownFont = this.font ? this.font : ''
        if (!this.font && this.engravingData.font) {
          this.eFont =  this.engravingData.font[0]
        } else {
          this.eFont = this.font ? this.font : ''
        }
        this.textVal = this.text.split('\n')
        while (this.metafieldsMap &&
        this.metafieldsMap.engraving &&
        this.textVal.length < this.metafieldsMap.engraving.value.numberOfLines) {
          this.textVal.push('')
        }
      },
      locationChange(event) {
        this.eLocation = event
      },
      closeModal() {
        this.$emit("closeEngraving")
      },
      addKeyText(symbol) {
        const textVal = [...this.textVal]; // Create a copy of the textVal array to avoid mutating it directly
        const cursorLine = this.cursorPosition.lineLocation;
        const chars = this.symbols.map(({ symbol }) => symbol).join('');
        const val = new RegExp(`[${chars}]`, 'gu'); // Add the 'u' flag to make it Unicode-aware
        const engraveLine = textVal[cursorLine] || '';

        let charLength = 1;
        if (symbol.match(val)) {
          charLength++;
        }

        // Calculate the available space for the new symbol
        const availableSpace = this.lengthLimit - engraveLine.length;

        if (availableSpace >= charLength) {
          const beforeCursor = engraveLine.slice(0, this.cursorPosition.charLocation);
          const afterCursor = engraveLine.slice(this.cursorPosition.charLocation);
          textVal[cursorLine] = beforeCursor + symbol + afterCursor;

          // Update cursor position
          this.cursorPosition.charLocation += charLength;

          // Update the textVal array
          this.textVal = textVal;

          // Increment keyVal and set focus
          this.keyVal += 1;
          this.focus = true;
        }
        this.focus = true
      },
      textUpdate(textVal, lineNumber) {
        this.focus = false
        if (textVal.length) {
          this.requiredText = false
        }
        this.textVal[lineNumber] = textVal
        // this.$set(this.textVal, lineNumber, textVal)

        this.visualTextVal[lineNumber] = textVal;
        let value;
        
        if (this.visualTextVal[0].length > 12) {
          const symbols = this.symbols.map((symbol) => symbol.symbol);
          let charToRemove = this.visualTextVal[0].length < 14 ? 2 : this.visualTextVal[0].length - 11;

          const getUpdatedValue = (originalValue, charToRemove) => {
            const startIndex = Math.floor(charToRemove / 2);
            const endIndex = -Math.floor(charToRemove / 2);
            return originalValue.slice(startIndex, endIndex);
          };

          let value = getUpdatedValue(this.visualTextVal[0], charToRemove);

          const numOfSymbols = symbols.filter(e => value.includes(e)).length;
          charToRemove += numOfSymbols;

          value = getUpdatedValue(this.visualTextVal[0], charToRemove);

          this.visualTextVal[0] = value;
        }

        this.focus = true
      },
      updateCursorPosition(event) {
        this.cursorPosition = event
      },
      fontChange(font) {
        this.eFont = font;
        this.dropdownFont = font;
        this.requiredFont = false;
      },
      async addToCart() {
        let discount = 0
        discount = this.engravingUpsellPercentDiscount ? this.engravingUpsellPercentDiscount : discount;
        let engravingUpsellDiscount = discount ? discount : 0;
        let engravingState;
        if (this.isBundle) {
          engravingState = {
            props: [
              { key: 'location', value: this.eLocation },
              { key: 'text', value: this.textVal.join('\n') },
              { key: 'font', value: this.eFont },
            ]
          }
        } else {
          engravingState = {
            item: {
              ...this.product,
              quantity: 1,
              variant: this.variant,
              moreThanOneEngraving: this.ringsLength > 1,
              lastRing: this.ringsLength == this.ringIndex + 1,
            },
            props: [
              { key: 'location', value: this.eLocation },
              { key: 'text', value: this.textVal.join('\n') },
              { key: 'font', value: this.eFont },
            ]
          }
        }
        if (this.dropdownTheme) {
          engravingState.props.push({
            key: 'theme',
            value: this.dropdownTheme
          })
        }
        if (this.upsell && this.engravingUpsell) {
          engravingState.props.push({ key: '_engravingDiscount', value: engravingUpsellDiscount.toString()});
        }
        if (!this.textVal[0].length && this.cursorPosition.lineLocation == 0) {
          this.requiredText = true;
        }
        if (!this.dropdownFont) {
          this.requiredFont = true;
        }
        if (!this.dropdownTheme) {
          this.requiredTheme = true
        }
        if (this.textVal[0].length && this.dropdownFont) {
          if (this.isBundle) {
            this.$root.$refs.productInfoMulti.handleSelection({
              bundleType: this.product.bundleType,
              event: this.currentRingSize,
              product: this.product,
            }, engravingState)
            this.$root.$refs.productInfoDrawer.resetSelectStep();
            this.$emit("closeEngraving");
          } else {
            this.$emit('addEngravingToCart', engravingState);
          }
          this.textVal = []
          this.dropdownFont = ''
          this.dropdownTheme = ''
          this.eFont = 'lato'

          if (this.upsell && this.ringIndex + 1 == this.ringsLength && this.engravingUpsell) {
            setTimeout(this.checkout, 1000) //time out allows the engraving to get to the cart.
          }
        }
        if (this.ringsLength > 1) {
          this.$refs.ringCount.focus();
        }

        this.dropdownFont = ''
      },
      ...mapMutations('cart', ['setCartError']),
      ...mapActions('checkout', ['processCheckout','resetCheckout']),
      async checkout() {
        this.loading = true
        const _instance = this;
        try {
          await this.processCheckout({ //These are empty but it won't work without the functions
            async beforeCreate() {
              // Allows processing of cart before checkout creation.
            },
            async beforeRedirect() {
              // Allows processing after checkout create and before redirecting.
              _instance.loading = false
            }
          })
        } catch (err) {
          console.log(err)
          this.setCartError(err)
          this.loading = false
        }
      },
      updateCharCountText(value) {
        this.charCountText = value
      },
      rotateLetter(eTextVal, letterIndex) {
        let rotateDeg = 0;
        const unit = -3.3;
        const largeLetters = ['w', 'W', 'M', 'l'];
        const smallLetters = [];
        const symbols = this.symbols.map((symbol) => symbol.symbol);

        for (let i = 1; i <= letterIndex; i++) {
          const letter = eTextVal[i];
          const prevLetter = eTextVal[i-1];
          
          if (smallLetters.includes(prevLetter)) {
            rotateDeg += unit * 0.1;
          } else if (largeLetters.includes(prevLetter)) {
            rotateDeg += unit * 0.5;
          } else if (symbols.includes(prevLetter)) {
            rotateDeg += unit * 0.4;
          } else {
            rotateDeg += unit * 0.25;
          }

          if (smallLetters.includes(letter)) {
            rotateDeg += unit * 0.99;
          } else if (largeLetters.includes(letter)) {
            rotateDeg += unit * 1;
          } else if (symbols.includes(letter)) {
            rotateDeg += unit * 1.15;
          } else {
            rotateDeg += unit * 0.75;
          }
        }

        return rotateDeg;
      }
    }
  }
  </script>
  <style lang="scss" scoped>
  .c-engraving-screen {
    height: 100%;
    width: 100%;
    position: relative;
    padding-top: rem(58px);
    &__step {
      padding: rem(10px) 0 0 0;
      &-subtitle {
        text-align: left;
        &-close {
          margin-left: rem(10px);
          cursor: pointer;
        }
        &-span {
          font-weight: bold;
        }
        &-spantext {
          font-weight: 300;
          font-size: medium;
          margin-left: rem(10px);
          text-transform: uppercase;
        }
      }
    }
    &__wrapper {
      width: 100%;
      height: 100%;
      text-align: center;
      padding: 0 150px 0 150px !important;
      overflow-y: scroll;
      overflow-x: hidden;
      padding: rem(20px) rem(10px);
      &--scrollable {
        @include themify($themes) {
          box-shadow: inset 0px -10px 10px -5px rgba(themed('background', 'dark'), 0.8);
        }
      }
      @media screen and (max-width: 767px) {
        padding: 10px !important;
      }
    }
    &__add-to-cart {
      text-align: center;
    }
    &__return-btn {
      text-align: center;
    }
    &__exit-button {
      cursor: pointer;
      background: none;
      border: none;
      line-height: rem(35px);
      font-size: rem(35px);
      width: rem(35px);
      height: rem(35px);
      position: absolute;
      top: rem(10px);
      right: rem(10px);
    }
    &__error {
      color: red;
    }
    &__ring-count {
      position: absolute;
      top: 0;
      display: block;
      background: white;
      text-align: center;
      padding: 10px;
      width: 100%;
      height: rem(58px);
    }
    &__ring-progress {
      padding-top:rem(10px);
    }
    &__save-button {
      width: 50%;
    }
    &__product-image {
      position: relative;
    }
    &__engraving-image-wrapper {
      max-height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__engraving-image {
      max-height: 400px;
      max-width: 400px;
    }
    &__theme-image-wrapper {
      position: relative;
    }
    &__theme-image {
      max-height: 390px;
    }
    &__theme-image-label {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &__product-text-exterior {
      position: absolute;
      top: -81%;
      width: 100%;
      height: 100%;
      transform-origin: top center;
      display: flex;
      justify-content: center;
    }
    &__product-text-exterior-line {
      position: relative;
      height: 153%;
      max-width: 95%;
      transform-origin: top center;
    }
    &__product-letter {
      position: absolute;
      left: 50%;
      background: black;
      -webkit-background-clip: text;
	    -moz-background-clip: text;
		  background-clip: text;
      height: 100%;
      display: flex;
      align-items: flex-end;
      transform-origin: top;
      color: transparent;
      &-child {
        font-size: rem(40px);
      }
    }
  }
  </style>
