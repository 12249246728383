<!--
/****
/* Individual products are loaded with the getProduct mixin.
/* For instructions related to connecting your invetory to
/* Nacelle, please refer to:
/*
/* https://docs.getnacelle.com/getting-started.html#_2-product-settings
/****
-->
<template>
  <div class="p-product-page" :class="pageClass">
    <div class="nosto_page_type" style="display: none;">
      product
    </div>
    <section class="u-margin-bottom-xx-large">
      <product-info
        :product="product"
        :products="pdpCollectionData.products"
        :bundle-products="bundleProducts"
        :bundle-options="bundleOptions"
        :size.sync="selectedRingSize"
        :variant-available="variantAvailable"
        :grid-layout="true"
        :size-guide-modal="true"
        :product-source-id="productSourceId"
        @engraving="startEngraving"
        @update:variant="currentProductVariant = $event"
        @modal:open="openGuideModal"
        @modal:close="closeGuideModal"
      >
        <template v-slot:default="slotProps">
          <!--
            uses scoped slots to pass the props back up to this scope
            @link https://vuejs.org/v2/guide/components-slots.html#Scoped-Slots

            geofence is assumed denied until we get a confirmed positive
            response geoip api

            if a product is NOT in stock, show back in stock notification prompt
          -->
          <template v-if="!variantAvailableLoading">
            <back-in-stock
              v-if="showBackInStock(slotProps, variantAvailable)"
              :product="slotProps.product"
              :variant="slotProps.variant"
            />
            <product-add-to-cart-button
              v-else
              v-bind="{
                ...slotProps,
                disabled: disableAtcBtn(slotProps),
                shoppingGivesDetected,
                allOptionsSelected: allOptionsSelected(slotProps),
                variant: variantProp(slotProps),
              }"
            />
          </template>
          <loader v-else />
          <!--
            don't show this until we have a confirmed negative response from
            geoip api
          -->
          <template v-if="geofenceAllowed === false">
            {{ collectionLabel }} {{ geofenceCodes | formatCodes }}
          </template>
        </template>
        <template
          v-if="product && product.pimSyncSourceProductId"
          v-slot:rating
        >
          <!-- Yotpo Star Ratings -->
          <star-rating
            :product="product"
            class="u-margin-bottom-x-small"
            style="display: inline-block"
          />
        </template>
        <template v-slot:recommendations>
          <div
            v-if="shoppingGivesEnabled"
            id="shopping-gives-widget"
            ref="shoppingGivesWidget"
            class="p-product-page__shopping-gives-widget"
            @click="detectShoppingGivesState"
          />
          <rebuy-related-products
            :product="product"
          />
        </template>
      </product-info>
    </section>
    <a v-utm-adder id="more" />
    <page-content
      :page="productPageContent"
      :products="pdpCollectionData.products"
      :product="product"
    >
      <rebuy-trending-products-carousel
        slot="on-the-other-hand"
        class="l-container u-margin-bottom-x-large"
        title="On the Other Hand"
      />
      <section
        slot="reviews"
        v-if="product && product.pimSyncSourceProductId"
        class="l-container u-margin-bottom-x-large"
      >
        <client-only>
          <reviews-widget
            v-if="product"
            :product="product"
            :pathname="$route.fullPath"
          />
        </client-only>
      </section>
    </page-content>
    </section>
    <modal
      v-bind="mappedModal"
      :show-modal="guideModal"
      @modal="closeGuideModal"
      v-if="mappedModal"
    >
      <template
        v-for="section in mappedModal.sections"
      >
        <component
          :key="section.id"
          :is="section.contentType"
          v-if="section.contentType"
          :id="section.handle"
          v-bind="section.data"
          :get-product-function="() => {}"
          :product="product"
        />
      </template>
    </modal>
  </div>
</template>

<script>
import BackInStock from '~/components/nacelle/BackInStock'
import geofenceProduct from '~/mixins/geofenceProduct'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import nmerge from '~/utils/merge-requests'
import productMetafields from '~/mixins/productMetafields'
import productPageDetails from '~/mixins/productPageDetails'
import ProductRecommendations from '~/components/products/ProductRecommendations'
import productVariantAvailable from '~/mixins/productVariantAvailable'
import Row from '@/components/layout/Row'
import sezzle from '~/mixins/sezzle'
import loadBundleProducts from '~/mixins/loadBundleProducts'
import { getCollectionFromProps } from '~/mixins/getCollectionFromProps'
import { mapState, mapActions } from 'vuex'
import getProductInCard from '~/mixins/getProductInCard'
import productHandle from '~/mixins/productHandle'
import Modal from '@/components/modal/Modal'
import { setupBridge } from '~/modules/storyblok/bridge'
import getContentItem from '~/mixins/getContentItem'
import { RebuyTrendingProductsCarousel, RebuyRelatedProducts } from '~/components/integrations/rebuy'
export default nmerge({
  name: 'product-page-screen-product',
  components: {
    RebuyTrendingProductsCarousel,
    RebuyRelatedProducts,
    BackInStock,
    Row,
    ProductRecommendations,
    Modal
  },
  data() {
    return {
      selectedItem: null
    }
  },
  mixins: [
    productHandle,
    getCollectionFromProps('pdpCollection'),
    sezzle(),
    productMetafields,
    productPageDetails,
    loadBundleProducts,
    // defines geofenceAllowed, geofenceCodes
    geofenceProduct,
    // defines variantAvailable
    productVariantAvailable,
    getProductInCard,
    getContentItem('modal', {
      handle: 'ring-size-modal',
      type: 'globalModal',
      mapping: 'storyblok'
    })
  ],
  data() {
    return {
      mappedModal: null,
      guideModal: false
    }
  },
  fetchKey() {
    return `product-page-screen-product--${this.product && this.product.id}`
  },
  mounted() {
    if (process.client) {
      // @link https://nuxtjs.org/docs/features/data-fetching#the-fetch-hook
      this.$fetch()

      window.dkExternalEndpoints = {
        domain: 'ensorings.myshopify.com',
        getProduct: async () => {
          return new Promise((resolve, reject) => {
            resolve({id: productSourceId});
          });
        },
        getCart: async () => {
          const domain = 'https://ensorings.myshopify.com';
          const response = await fetch(`${domain}/cart.js`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });
          const data = await response.json();
          return data;
        },
        manageDonationProduct: async (variantId, quantity, attributes) => {
          const domain = 'https://ensorings.myshopify.com';
          const cart = await window.dkExternalEndpoints.getCart();
          const line = cart?.items?.find((l) => l.variant_id === variantId);
          const response = await fetch(`${domain}/cart/${quantity === 0 ? 'change' : 'add'}.js`, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              id: line ? line.key : variantId,
              quantity: +quantity,
              properties: attributes,
            }),
          });
          return response;
        },
        updateCartAttributes: async (attributes) => {
          const domain = 'https://ensorings.myshopify.com';
          const response = await fetch(`${domain}/cart/update.js`, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ attributes }),
          });
        },
      };
    }
  },
  watch: {
    async product() {
      if (this.product) {
        await this.$nextTick()
        this.updateEngravingContext()
      }
    },
    modal(value, oldValue) {
      if (!isEqual(value, oldValue)) {
        if (value) {
          this.mappedModal = value
        }
      }
    },
    productPageContent() {
      if (this.guideModalPreview) {
        this.modal = { data: this.productPageContent }
      }
    }
  },
  computed: {
    ...mapState('engraving', {
      engravingProduct: ({ product }) => product
    }),
    pageClass() {
      const productPageContent = this.productPageContent
      let result = []
      if(productPageContent && productPageContent.pageTheme) {
        result.push(`theme-${productPageContent.pageTheme}`)
      }
      return result
    },
    pdpCollectionTitle() {
      return get(this.pdpCollectionData, 'collection.title')
    },
    engravingVariant: {
      get() {
        return this.$store.state.engraving.variant
      },
      set(variant) {
        this.updateEngravingContext()
      }
    },
    openEngraving: {
      get() {
        return this.$store.state.engraving.open
      },
      set(open) {
        if (open) {
          this.updateEngravingContext()
          this.$nextTick(() => this.$store.dispatch('engraving/open'))
        } else {
          this.$store.dispatch('engraving/close')
        }
      }
    },
    showEngravingScreen() {
      const product = this.engravingProduct
      let result = false
      if (product && product.metafields) {
        result = !!product.metafields.find(obj => {
          return obj.key == 'engraving'
        })
      }
      return result
    },
    isGiftCard() {
      return this.product.vendor.toLowerCase().includes('rise.ai')
    },
    rowData() {
      if (this.productPageContent) {
        let sections = this.productPageContent.sections
        for (let section of sections) {
          if (section.contentType == 'row') {
            return section
          }
        }
        return this.productPageContent[1]
      }
    },
    showRecommendations() {
      const frequentlyBoughtTogether = this.frequentlyBoughtTogether
      const drtvEnabled = this.$config.drtv.enabled
      const hideProductRecommendations = !!get(this.productPageContent, 'hideProductRecommendations')
      let result = false
      return (
        frequentlyBoughtTogether &&
        !drtvEnabled &&
        !hideProductRecommendations
      )
    },
    productSourceId() {
      return Buffer.from(this.product.pimSyncSourceProductId, 'base64').toString('utf-8').split('/').pop()
    }
  },
  methods: {
    ...mapActions('engraving', {
      setEngravingContext: 'setContext'
    }),
    updateEngravingContext() {
      const product = this.product
      const selectedItem = this.selectedItem
      this.setEngravingContext({
        mode: 'pdp',
        product: selectedItem || product,
        variant: this.currentProductVariant,
        open: this.openEngraving,
        isBundle: this.isBundle,
      })
    },
    startEngraving(data) {
      this.currentProductVariant = data.variant
      this.selectedRingSize = parseInt(data.size)
      // optional override for bundle context
      this.selectedItem = data.product
      this.openEngraving = true
    },
    closeEngraving() {
      this.openEngraving = false
    },
    openGuideModal() {
      this.guideModal = true
    },
    closeGuideModal() {
      this.guideModal = false
    }
  },
  head() {
    let script = []
    script.push({
      src: `https://assets.dailykarma.io/prod/init-v3.js`,
      body: true,
    })
    return { script }
  }
})
</script>

<style lang="scss" scoped>
.p-product-page {
  padding: $section-padding;
  &__details {
    @include themify($themes) {
      background-color: themed('background', 'xx-light');
    }
  }
  &__shopping-gives-widget {
    height: rem(50px);
    overflow: hidden;
    margin-top: rem(20px);
  }
}
</style>
