<template>
  <div
    v-if="showScreen"
    class="c-mini-pdp-screen l-container section"
  >
    <product-info
      :product="product"
      :products="pdpCollectionData.products"
      :bundle-products="bundleProducts"
      :bundle-options="bundleOptions"
      :mini-bundle="true"
      :disable-filter-bar="disableFilterBar"
    >
      <template v-slot:default="slotProps">
        <!--
          uses scoped slots to pass the props back up to this scope
          @link https://vuejs.org/v2/guide/components-slots.html#Scoped-Slots

          geofence is assumed denied until we get a confirmed positive
          response geoip api

          if a product is NOT in stock, show back in stock notification prompt
        -->
        <template v-if="!variantAvailableLoading">
          <back-in-stock
            v-if="showBackInStock(slotProps, variantAvailable)"
            :product="slotProps.product"
            :variant="slotProps.variant"
          />
          <product-add-to-cart-button
            v-else
            v-bind="{
              ...slotProps,
              disabled: disableAtcBtn(slotProps),
              shoppingGivesDetected: false,
              allOptionsSelected: allOptionsSelected(slotProps),
              variant: variantProp(slotProps),
            }"
          />
        </template>
      </template>
      <template
        v-if="product && product.pimSyncSourceProductId"
        v-slot:rating
      >
        <!-- Yotpo Star Ratings -->
        <star-rating
          :product="product"
          class="u-margin-bottom-x-small"
          style="display: inline-block"
        />
      </template>
    </product-info>
  </div>
  <loader v-else />
</template>

<script>
import BackInStock from '~/components/nacelle/BackInStock'
import GeofenceProduct from '~/mixins/geofenceProduct'
import LoadBundleProducts from '~/mixins/loadBundleProducts'
import Loader from '@/components/loader/Loader'
import nmerge from '~/utils/merge-requests'
import ProductAddToCartButton from '~/components/nacelle/ProductAddToCartButton'
import ProductInfo from '@/components/productInfo'
import ProductVariantAvailable from '~/mixins/productVariantAvailable'
import StarRating from '@/screens/StarRating'
import { getCollectionFromProps } from '~/mixins/getCollectionFromProps'
import { getProductFromProps } from '~/mixins/getProductFromProps'

export default nmerge({
  name: 'mini-bundle-screen',
  components: {
    BackInStock,
    Loader,
    ProductAddToCartButton,
    ProductInfo,
    StarRating,
  },
  mixins: [
    getProductFromProps('productHandle'),
    getCollectionFromProps('pdpCollection', { reloadOnce: false }),
    GeofenceProduct,
    LoadBundleProducts,
    ProductVariantAvailable,
  ],
  props: {
    productHandle: {
      type: String,
      default: ''
    },
    disableFilterBar: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    showScreen() {
      const product = this.product
      const products = this.pdpCollectionData?.products
      const bundleProducts = this.bundleProducts
      return !!(
        product &&
        products && products.length &&
        bundleProducts && bundleProducts.length
      )
    },
    product() {
      return this.productHandleData
    },
    pdpCollection() {
      const product = this.product
      const collectionHandle = product && product.metafieldsMap
        ? product.metafieldsMap['pdp_collection_handle']
        : null
      return collectionHandle ? collectionHandle.value : null
    },
  }
})
</script>

<style lang="scss" scoped>
.c-mini-pdp-screen {
  padding: rem(50px) 0;

  @include respond(phone) {
      padding: rem(18px) 0;
    }
}
</style>
