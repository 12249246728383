<template>
  <div
    v-if="showRecos"
    class="c-rebuy-related-products"
  >
    <h2
      class="t-heading-6 u-margin-bottom-small c-rebuy-related-products__title"
    >
      Frequently Bought Together
    </h2>
    <div class="l-grid l-grid--2-col">
      <div
        v-for="(product, index) in recos"
        class="l-grid__item l-grid__item--align-start"
        :key="`cc-${product.id}${index}`"
      >
        <nuxt-link
          class="c-rebuy-related-products__link"
          :to="`/products/${product.handle}`"
        >
          <content-card
            :title="product.title"
            :description="product.variants[0].price"
            :image="{
              src: product.featuredMedia.thumbnailSrc,
              alt: product.featuredMedia.alt
            }"
            :cta="{ text:'Shop Now', buttonSize: ['small','thin'] }"
          />
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from "@/components/card/ProductCard";
import ContentCard from "@/components/card/ContentCard";
import get from 'lodash/get'

export default {
  name: 'RebuyRelatedProducts',
  components: {
    ContentCard,
    ProductCard
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data() {
    return { recos: null }
  },
  computed:{
    showRecos() {
      const recos = this.recos
      const drtvEnabled = get(this.$config, 'drtv.enabled')
      return !!((recos && recos.length > 0) && !drtvEnabled)
    },
  },
  async fetch() {
    if (this.product) {
      this.recos = await this.$api.getRecosByProduct({
        product: this.product,
        limit: 2
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.c-rebuy-related-products {
  max-width: 456px;
  &__title {
    margin-top: 30px;
    text-transform: uppercase;
  }
  &__link {
    text-decoration: none !important;
    @include themify($themes) {
      //background-color:themed('foreground', 'white-text');
      color: themed('foreground');
    }
  }
  .c-content-card::v-deep {
    h2 {
      height: rem(65px);
    }
  }
}
</style>
