var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{class:_vm.bannerClasses},[_vm._t("background",function(){return [_c('picture',{ref:"hero-img-card",staticClass:"hero-background"},[(_vm.cloudinaryCanAutoFormat)?_c('source',{attrs:{"media":"(max-width: 768px)","srcset":_vm.optimizeSource({
            url: _vm.mobileSrc,
            format: 'auto',
            width: 768,
            crop: _vm.mobileCrop
          })},on:{"error":_vm.fallback}}):_vm._e(),_vm._v(" "),(_vm.reformat)?_c('source',{attrs:{"media":"(max-width: 768px)","srcset":_vm.optimizeSource({
            url: _vm.mobileSrc,
            format: 'webp',
            width: 768,
            crop: _vm.mobileCrop
          }),"type":"image/webp"},on:{"error":_vm.fallback}}):_vm._e(),_vm._v(" "),(_vm.reformat)?_c('source',{attrs:{"media":"(max-width: 768px)","srcset":_vm.optimizeSource({
            url: _vm.mobileSrc,
            format: 'pjpg',
            width: 768,
            crop: _vm.mobileCrop
          }),"type":"image/jpeg"},on:{"error":_vm.fallback}}):_vm._e(),_vm._v(" "),(_vm.cloudinaryCanAutoFormat)?_c('source',{attrs:{"media":"(min-width: 769px) and (max-width: 1023px)","srcset":_vm.optimizeSource({
            url: _vm.imageUrl,
            format: 'auto',
            width: 1023,
            crop: _vm.mobileCrop
          })},on:{"error":_vm.fallback}}):_vm._e(),_vm._v(" "),(_vm.reformat)?_c('source',{attrs:{"media":"(min-width: 769px) and (max-width: 1023px)","srcset":_vm.optimizeSource({
            url: _vm.imageUrl,
            format: 'webp',
            width: 1023,
            crop: _vm.mobileCrop
          }),"type":"image/webp"}}):_vm._e(),_vm._v(" "),(_vm.reformat)?_c('source',{attrs:{"media":"(min-width: 769px) and (max-width: 1023px)","srcset":_vm.optimizeSource({
            url: _vm.imageUrl,
            format: 'pjpg',
            width: 1023,
            crop: _vm.mobileCrop
          }),"type":"image/jpeg"}}):_vm._e(),_vm._v(" "),(_vm.cloudinaryCanAutoFormat)?_c('source',{attrs:{"media":"(min-width: 1023px) and (max-width: 1215px)","srcset":_vm.optimizeSource({
            url: _vm.imageUrl,
            format: 'auto',
            width: 1215
          })},on:{"error":_vm.fallback}}):_vm._e(),_vm._v(" "),(_vm.reformat)?_c('source',{attrs:{"media":"(min-width: 1023px) and (max-width: 1215px)","srcset":_vm.optimizeSource({
            url: _vm.imageUrl,
            format: 'webp',
            width: 1215
          }),"type":"image/webp"}}):_vm._e(),_vm._v(" "),(_vm.reformat)?_c('source',{attrs:{"media":"(min-width: 1023px) and (max-width: 1215px)","srcset":_vm.optimizeSource({
            url: _vm.imageUrl,
            format: 'pjpg',
            width: 1215
          }),"type":"image/jpeg"}}):_vm._e(),_vm._v(" "),(_vm.cloudinaryCanAutoFormat)?_c('source',{attrs:{"media":"(min-width: 1216px) and (max-width: 1407px)","srcset":_vm.optimizeSource({
            url: _vm.imageUrl,
            format: 'auto',
            width: 1407
          })},on:{"error":_vm.fallback}}):_vm._e(),_vm._v(" "),(_vm.reformat)?_c('source',{attrs:{"media":"(min-width: 1216px) and (max-width: 1407px)","srcset":_vm.optimizeSource({
            url: _vm.imageUrl,
            format: 'webp',
            width: 1407
          }),"type":"image/webp"}}):_vm._e(),_vm._v(" "),(_vm.reformat)?_c('source',{attrs:{"media":"(min-width: 1216px) and (max-width: 1407px)","srcset":_vm.optimizeSource({
            url: _vm.imageUrl,
            format: 'pjpg',
            width: 1407
          }),"type":"image/jpeg"}}):_vm._e(),_vm._v(" "),(_vm.cloudinaryCanAutoFormat)?_c('source',{attrs:{"media":"(min-width: 1408px)","srcset":_vm.optimizeSource({
            url: _vm.imageUrl,
            format: 'auto',
            width: 1408
          })},on:{"error":_vm.fallback}}):_vm._e(),_vm._v(" "),(_vm.reformat)?_c('source',{attrs:{"media":"(min-width: 1408px)","srcset":_vm.optimizeSource({
            url: _vm.imageUrl,
            format: 'webp',
            width: 1408
          }),"type":"image/webp"}}):_vm._e(),_vm._v(" "),(_vm.reformat)?_c('source',{attrs:{"media":"(min-width: 1408px)","srcset":_vm.optimizeSource({
            url: _vm.imageUrl,
            format: 'pjpg',
            width: 1408
          }),"type":"image/jpeg"}}):_vm._e(),_vm._v(" "),_c('img',{attrs:{"src":_vm.imageUrl,"alt":_vm.backgroundAltTag},on:{"error":_vm.fallback}})])]},{"mobileBackgroundImgUrl":_vm.mobileBackgroundImgUrl,"imageUrl":_vm.imageUrl,"backgroundAltTag":_vm.backgroundAltTag}),_vm._v(" "),_c('div',{staticClass:"hero-body"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"hero-body-inner"},[_vm._t("body",function(){return [_c('h1',{staticClass:"title",style:(_vm.textColor && _vm.textColor.length > 0 ? `color: ${_vm.textColor}` : '')},[_vm._v("\n            "+_vm._s(_vm.title)+"\n          ")]),_vm._v(" "),_c('h3',{staticClass:"subtitle",style:(_vm.textColor && _vm.textColor.length > 0 ? `color: ${_vm.textColor}` : '')},[_vm._v("\n            "+_vm._s(_vm.subtitle)+"\n          ")])]},{"textColor":_vm.textColor,"title":_vm.title,"subtitle":_vm.subtitle}),_vm._v(" "),_vm._t("cta",function(){return [(_vm.ctaText.length > 0)?_c('p',[_c('cta-button',{attrs:{"to":_vm.ctaUrl},on:{"clicked":_vm.ctaHandler}},[_vm._v(_vm._s(_vm.ctaText))])],1):_vm._e()]},{"ctaUrl":_vm.ctaUrl,"ctaText":_vm.ctaText,"ctaHandler":_vm.ctaHandler})],2)])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }