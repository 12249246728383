<template>
  <div class="c-cart-watch" />
</template>

<script>
import { mapState, mapMutations } from 'vuex'

// CartWatch.vue
//
// Nacelle's Nuxt Starter generates JSON files with your store's data during
// build time. Using this "Static API" increases speed, performance, and
// reliability for your store.
//
// However, depending on when your build times are scheduled, high traffic
// and purchases could clear a variant's inventory before the site is
// rebuilt with the latest data.
//
// This component listens for updates to the cart and checks your Nacelle
// product index to ensure the variant is still available for sale.
//
// Read more about the static files in our docs
// https://docs.getnacelle.com/nuxt/fetching-data.html#data-flow-and-static-files

export default {
  computed: {
    ...mapState('cart', ['lineItems'])
  },
  created() {
    // CartWatch listens for line items added to the Vuex store cart
    this.$store.subscribe((mutation) => {
      const { type, payload } = mutation

      if (type === 'cart/addLineItemMutation') {
        this.checkAvailability(payload)
      }
    })
  },
  methods: {
    ...mapMutations('cart', ['removeLineItemMutation', 'setCartError']),

    // This method gets the Nacelle product ID and variant ID from the
    // line item, and uses the gql api to check if a user is allowed to purchase
    // the item.
    async checkAvailability(lineItem) {
      try {
        const { title = '', productId, variant, handle } = lineItem
        const { DIGITAL_PRODUCTS } = this.$config
        if (productId && variant && !DIGITAL_PRODUCTS.includes(handle)) {
          const variantAvailable = await this.$store.dispatch(
            'cart/checkStockAvailable',
            { product: lineItem, variant }
          )
          if (!variantAvailable) {
            this.removeLineItemMutation(productId)

            // You can customize this error message for what a user sees if
            // the variant is not available.
            this.setCartError(
              `Sorry that variant for ${title} is no longer available.`
            )
          }
          console.log('[cart-watch]', { variantAvailable })
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>
