<template>
  <div class="c-mini-pdp-screen l-container section">
    <product-info
      :product="product"
      :products="pdpCollectionData.products"
      :bundle-products="bundleProducts"
      :bundle-options="bundleOptions"
      :mini-product-detail="true"
    >
      <template
        v-if="product && product.pimSyncSourceProductId"
        v-slot:rating
      >
        <!-- Yotpo Star Ratings -->
        <star-rating
          :product="product"
          class="u-margin-bottom-x-small"
          style="display: inline-block"
        />
      </template>
    </product-info>
  </div>
</template>

<script>
import { getCollectionFromProps } from '~/mixins/getCollectionFromProps'
import { getProductFromProps } from '~/mixins/getProductFromProps'
import loadBundleProducts from '~/mixins/loadBundleProducts'
import ProductInfo from '@/components/productInfo'
import StarRating from '@/screens/StarRating'
import nmerge from '~/utils/merge-requests'

export default nmerge({
  components: {
    ProductInfo,
    StarRating
  },
  mixins: [
    getProductFromProps('productHandle'),
    getCollectionFromProps('pdpCollection', { reloadOnce: false }),
    loadBundleProducts,
  ],
  props: {
    productHandle: {
      type: String,
      default: ''
    }
  },
  computed: {
    product() {
      return this.productHandleData
    },
    pdpCollection() {
      const product = this.product
      const collectionHandle = product && product.metafieldsMap
        ? product.metafieldsMap['pdp_collection_handle']
        : null
      return collectionHandle ? collectionHandle.value : null
    },
  }
})
</script>

<style lang="scss" scoped>
.c-mini-pdp-screen {
  padding: rem(50px) 0;

  @include respond(phone) {
      padding: rem(18px) 0;
    }
}
</style>
