<template>
  <button
    class="c-mobile-search-button t-heading-6"
    :tabindex="tabindexStart"
    @click="showSearch"
  >
    Search
  </button>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: "MobileSearchButton",
  props: {
    tabindexStart:{
      type: Number,
      default: 0
    }
  },
  data(){
    return {

    }
  },
  mounted(){

  },
  methods: mapMutations('search', ['showSearch'])
}
</script>
<style lang="scss" scoped>
  .c-mobile-search-button{
    @include themify($themes) {
      color: themed("primary", "base");
    }
    @include buttonReset;
    display: block;
    margin-bottom: rem(15px);
    text-decoration: none;
    &:hover{
      @include themify($themes) {
        color: themed("accent", "base");
      }
    }

    svg{
      margin-right: rem(20px);
      position: relative;
      top: 3px;
    }
  }
</style>