<template>
  <interface-modal
    class="c-filter-modal-screen"
    :modal-open="modalOpen"
    :align="align"
    @close="$emit('close')"
  >
    <refinement-filters
      class="c-filter-modal-screen__filters"
      :style="styleMods"
      v-bind="$props"
      :input-data="products"
      :property-filters="propertyFilters"
      :price-range-filters="priceRangeFilters"
      :quick-filter="quickFilter"
      :embed-filter="embedFilter"
      @updated="$emit('updated', $event)"
      @close="$emit('close')"
      @clear="$emit('clear')"
      @update:filter-count="$emit('update:filter-count', $event)"
      @update:filters="$emit('update:filters', $event)"
      @active-filters="$emit('active-filters', $event)"
    />
  </interface-modal>
</template>

<script>
import flow from 'lodash/flow'
import identity from 'lodash/identity'
import InterfaceModal from '~/components/nacelle/InterfaceModal'
import RefinementFilters from '~/components/nacelle/RefinementFilters'
import round from 'lodash/fp/round'
import sortBy from 'lodash/fp/sortBy'
import sortedUniq from 'lodash/sortedUniq'
const sortPrices = flow(sortBy(identity), sortedUniq)
const parsePrice = flow(parseFloat, round)
export default {
  components: {
    RefinementFilters,
    InterfaceModal
  },
  props: {
    modalOpen: {
      type: Boolean,
      required: true
    },
    products: {
      type: Array,
      required: true
    },
    propertyFilters: {
      type: Array,
      required: true,
    },
    quickFilter: {
      type: Object,
      required: true,
    },
    align: {
      type: String,
      default: undefined
    },
    embedFilter: {
      type: Boolean,
      default: false
    },
    offsetTop: {
      type: Number,
      default: null
    }
  },
  computed: {
    styleMods() {
      const offsetTop = this.offsetTop
      let result = {}
      if (offsetTop) {
        result['padding-top'] = `${offsetTop}px`
      }
      return result
    },
    // these can be replaced with metafield filters in the future
    priceRangeFilters() {
      const products = this.products
      const defaultRange = [
        { range: [0, 50], label: '< $50' },
        { range: [50, 100], label: '$50 - 100' },
        { range: [100, 200], label: '$100 - 200' },
        { range: [200, 500], label: '$200 - 500' },
        { range: [500, 0], label: '> $500' }
      ]
      let dynamicRange = []
      let prices = []
      for (let { priceRange } of products) {
        const { min, max } = priceRange
        prices.push(parsePrice(min), parsePrice(max))
      }
      prices = sortPrices(prices)
      for (let i = 0; i < prices.length; i++) {
        if (i === 0) {
          const b = prices[i]
          dynamicRange.push({
            range: [0, b],
            label: `< $${b}`
          })
        } else if (i === prices.length) {
          const a = prices[i]
          dynamicRange.push({
            range: [a, 0],
            label: `> $${a}`
          })
        } else {
          const a = prices[i - 1]
          const b = prices[i]
          dynamicRange.push({
            range: [a, b],
            label: `$${a} - $${b}`
          })
        }
      }
      return (dynamicRange.length) ? dynamicRange : defaultRange
    },
  }
}
</script>

<style lang="scss" scoped>
.c-filter-modal-screen {
}
</style>
