<template>
  <div class="c-page-content">
    <section class="c-page-content__details">
      <template v-for="section in topContent">
        <component
          :is="section.contentType"
          v-if="section.contentType"
          :id="section.handle"
          :key="section.id"
          v-bind="section.data"
          :get-product-function="fetchProduct"
          :product="product"
        />
      </template>
    </section>
    <template v-if="showUGC">
      <social-media-gallery
        :key="ugc.id"
        v-bind="ugc.data"
      />
    </template>
    <slot name="on-the-other-hand" />
    <slot name="reviews" />
  </div>
</template>

<script>
import Accordion from '~/components/screens/AccordionScreen'
import BrandCarousel from "@/components/carousel/BrandCarousel"
import Bundle from '~/components/screens/BundleScreen'
import CollectionGrid from '~/components/screens/CollectionGridScreen'
import ContentCard from "@/components/card/ContentCard"
import ContentCarousel from '@/components/carousel/ContentCarousel'
import CtaGrid from '~/components/screens/CTAGridScreen'
import Disclaimer from '@/components/disclaimer/Disclaimer'
import FeaturedCollection from '@/components/carousel/FeaturedCollection'
import FeaturedCollections from '~/components/screens/FeaturedCollectionsScreen'
import FeaturedProducts from '~/components/screens/FeaturedProductsScreen'
import FeaturedRingSize from "@/components/featuredProducts/FeaturedRingSize"
import FeaturedRow from "@/components/layout/FeaturedRow"
import getProductInCard from "../../mixins/getProductInCard";
import HeroBanner from '~/components/screens/HeroScreen'
import ImageCardCta from '@/components/card/ImageCardCta'
import InputForm from '~/components/InputForm'
import LinkCard from '@/components/card/LinkCard'
import MiniProductDetailPage from '~/components/screens/MiniProductDetailPageScreen'
import Modal from '@/components/modal/Modal'
import NavColumn from '@/components/nav/NavColumn'
import NavCombinationLayout from '@/components/nav/NavCombinationLayout'
import NavFourColumnGrid from '@/components/nav/NavFourColumnGrid'
import NavSingleRow from '@/components/nav/NavSingleRow'
import PageContentMixin from '~/mixins/PageContentMixin'
import Review from '~/components/screens/ReviewScreen'
import Row from '@/components/layout/Row'
import StickerCard from '@/components/card/StickerCard'
import SwatchLink from '@/components/swatch/SwatchLink'
import Tabs from '@/components/tabs/Tabs'
import Testimonial from '~/components/nacelle/ContentTestimonial'
import Testimonials from '~/components/nacelle/ContentTestimonials'
import TextContent from '@/components/disclaimer/Disclaimer'
import ThreeColumn from "@/components/layout/ThreeColumn"
import TwoColumn from "@/components/layout/TwoColumn"
import Video from "@/components/video/Vid"
import GuidedQuiz from '~/components/screens/GuidedQuizScreen'
import ProductGrid from '~/components/nacelle/ContentProductGrid'

export default {
  components: {
    Accordion,
    BraceletBuilder: () => import('~/components/BraceletBuilder'),
    BrandCarousel,
    Bundle,
    CollectionGrid,
    ContentCard,
    ContentCarousel,
    CtaGrid,
    Disclaimer,
    FeaturedCollection,
    FeaturedCollections,
    FeaturedProducts,
    FeaturedRingSize,
    FeaturedRow,
    HeroBanner,
    ImageCardCta,
    InputForm,
    LinkCard,
    MiniProductDetailPage,
    Modal,
    NavColumn,
    NavCombinationLayout,
    NavFourColumnGrid,
    NavSingleRow,
    ProductGrid,
    Review,
    Row,
    SocialMediaGallery: () => import('~/components/screens/SocialMediaGallery'),
    StickerCard,
    StoreLocator: () => import('~/components/integrations/stockist/StoreLocator'),
    SwatchLink,
    Tabs,
    Testimonial,
    Testimonials,
    TextContent,
    ThreeColumn,
    TwoColumn,
    Video,
    GuidedQuiz
  },
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [
    PageContentMixin,
    getProductInCard
  ],
  computed: {
    topContent() {
      const mappedSections = this.mappedSections
      const otherContent = this.content
      let content = []
      if (mappedSections && mappedSections.length) {
        content = mappedSections
      } else if (otherContent && otherContent.length) {
        content = otherContent
      }
      return (content && content.length)
        ? content.filter(({ contentType }) => contentType !== 'SocialMediaGallery')
        : []
    },
    ugc() {
      const content = this.topContent
      let result = null
      if (content && content.length) {
        result = content.find(({ contentType }) => contentType === 'SocialMediaGallery')
      }
      return result
    },
    showUGC() {
      const ugc = this.ugc
      return !!(ugc && ugc.data)
    }
  }
}
</script>

<style></style>
