<template>
  <div
    ref="cart"
    class="c-cart"
    :class="['l-flex', 'l-flex--column', 'l-flex__nowrap']"
    :aria-describedby="ariaCartTitleId"
  >
    <button class="c-cart__header u-center-text t-heading-6" @click="hideCart">
      <span aria-hidden="true" class="c-cart__close-icon">
        <img
          class="c-cart__close-icon-image"
          src="~/assets/icons/back-arrow.svg"
          alt="back arrow"
        />
      </span>
      <span :id="ariaCartTitleId" class="c-cart__title">
        <span aria-label="Your Cart."> Your Cart </span>
        <span aria-hidden="true">({{ filteredLineItems | qty }})</span>
      </span>
    </button>
    <cart-flyout-messaging
      class="c-cart__message t-heading-11 t-heading-11--white"
      :site-wide-sale="siteWideSale"
    />
    <shipping-progress v-if="includeFreeShippingProgress" />
    <div
      v-if="filteredLineItems && filteredLineItems.length === 0"
      class="c-cart__wrapper"
      :class="wrapperClassNames"
    >
      <p class="t-paragraph-large u-margin-bottom-small">
        {{ emptyCartMessage || 'Your cart is currently empty.' }}
      </p>
      <btn url="/collections/silicone-rings">
        Shop
      </btn>
    </div>
    <div v-else class="c-cart__wrapper" :class="wrapperClassNames">
      <div class="c-cart__items">
        <component
          :is="cartItemComponent"
          v-for="item in filteredLineItems"
          :key="`${item.id}--${item.variant.id}`"
          class="c-cart__item"
          :item="item"
          :fees="cartFees"
        />
      </div>

      <div
        v-if="cartRecommendation"
        id="cartpage-nosto-1"
      >
        <product-recommendations
          :recommendations="cartRecommendation"
        />
      </div>

      <div
        class="c-cart__footer"
        :class="[
          'l-flex',
          'l-flex--column',
          'l-flex--align-start',
          'l-flex--justify-start'
        ]"
      >
        <cart-offer class="c-cart__cart-offer" @select="handleSelectMiniCart" />
        <div class="c-cart__subtotal-container">
          <cart-flyout-subtotal class="c-cart__subtotal" />
          <!-- Turn off Redo -->
          <!-- <div v-if="false && redoEnabled && !isExchange" class="c-cart__redo-toggle">
            <div></div>
            <div class="l-flex">
              Free Returns + Package Protection for $2.99 via
              <img
                src="~/assets/icons/redo-logo.png"
                alt="Redo logo"
                class="c-cart__redo-icon"
              />
            </div>
            <toggle-button :active="redoApplied" @change-status="toggleRedo"></toggle-button>
          </div> -->
          <btn
            v-if="showEngravingUpsell"
            ref="checkoutButton"
            :button-size="['large']"
            @click="openEngravingUpsell"
          >
            Checkout Now
          </btn>
          <cart-flyout-checkout-button
            v-else
            ref="checkoutButton"
            class="c-cart__checkout-button"
          />
        </div>
      </div>
    </div>
    <!-- <div id="shopping-gives-cart-widget" /> -->
    <template v-if="showMiniCart && miniCartProps">
      <mini-cart
        class="c-cart__mini-cart"
        v-bind="miniCartProps"
        @close="closeMiniCart"
        @update:product="updateProduct"
        @remove:product="removeProduct"
      >
        <template v-slot:default="slotProps">
          <product-add-to-cart-button v-bind="slotProps" />
        </template>
      </mini-cart>
    </template>
    <div v-if="engravingUpsellOpen" class="c-cart__engraving-upsell">
      <EngravingUpsell
        :image="engravingUpsellImage"
        @openEngravingList="openEngravingList"
      />
    </div>
    <div v-if="engravingUpsellListOpen" class="c-cart__engraving-upsell">
      <EngravingUpsellList
        :line-items="filteredLineItems"
        @closeCart="closeCart"
      />
    </div>
  </div>
</template>

<script>
import Btn from '@/components/button/Btn'
import CartFlyoutCheckoutButton from '~/components/cart/CartFlyoutCheckoutButton'
import CartFlyoutMessaging from '~/components/cart/CartFlyoutMessaging'
import CartFlyoutSubtotal from '~/components/cart/CartFlyoutSubtotal'
import CartItem from './CartItem'
import CartOffer from './CartOffer'
import ContentCard from '@/components/card/ContentCard'
import isArray from 'lodash/isArray'
import nmerge from '~/utils/merge-requests'
import ProductAddToCartButton from '~/components/nacelle/ProductAddToCartButton'
import { CartMixin } from '~/mixins/shoppingGives'
import nosto from '~/mixins/nosto'
import { getCollectionFromProps } from '~/mixins/getCollectionFromProps'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import ProductRecommendations from '~/components/products/ProductRecommendations'
import ToggleButton from '@/components/toggleButton/ToggleButton'

export default nmerge({
  components: {
    Btn,
    CartFlyoutCheckoutButton,
    CartFlyoutMessaging,
    CartFlyoutSubtotal,
    CartItem,
    CartOffer,
    ProductRecommendations,
    CartItemNew: () => import('./CartItemNew'),
    MiniCart: () =>
      import(
        /* webpackPrefetch: true */
        '@/components/miniCart/MiniCart'
      ),
    ProductAddToCartButton,
    ContentCard,
    EngravingUpsellList: () =>
      import('~/components/cart/EngravingUpsell/EngravingUpsellList'),
    EngravingUpsell: () =>
      import('~/components/cart/EngravingUpsell/EngravingUpsell'),
    ShippingProgress: () => import('~/components/cart/ShippingProgress'),
    ToggleButton,
  },
  filters: {
    qty(value) {
      let result = 0
      if (isArray(value)) {
        for (let item of value) {
          if (item && item.quantity) {
            result += item.quantity
          }
        }
      }
      return result
    }
  },
  mixins: [
    getCollectionFromProps('giftWithPurchaseCollection', { reloadOnce: false }),
    CartMixin,
    nosto({
      elements: ['cartpage-nosto-1', 'nosto-stl']
    }),
  ],
  data() {
    return {
      showMiniCart: false,
      lineItemProps: [],
      recos: null,
      cartFees: [],
      engravingUpsellOpen: false,
      engravingUpsellListOpen: false,
    }
  },
  async fetch() {
    await this.loadFees()
  },
  fetchOnServer: false,
  computed: {
    ...mapState('cart', [
      'bannerCartMessage',
      'emptyCartMessage',
      'engravingFeeProduct',
      'includeFreeShippingProgress',
      'modBraceletSleeveFeeProduct',
      'siteWideSale',
      'engravingUpsell',
      'redoApplied',
      'redoEnabled',
    ]),
    ...mapState('vwo',['miniCart']),
    ...mapState('redo', {
      isExchange: ({ exchange }) => exchange
    }),
    ...mapGetters('cart', {
      hiddenLineItemHandles: 'hiddenLineItemHandles',
      giftWithPurchaseCollection: 'giftWithPurchaseCollection',
      lineItems: 'mappedLineItems',
      productHasGiftWithPurchase: 'productHasGiftWithPurchase'
    }),
    cartItemComponent() {
      const miniCart = this.miniCart
      let result = 'CartItem'
      if(miniCart && miniCart.variant1) {
        result = 'CartItemNew'
      }
      return result
    },
    showEngravingUpsell() {
      const canEngrave = this.canEngrave
      return !!(this.engravingUpsell && canEngrave)
    },
    engravingUpsellImage() {
      for (let item of this.filteredLineItems) {
        if (item.metafields.find(obj => obj.key == 'engraving')) {
          if (item.image) {
            return { src: item.image.src, alt: item.image.alt }
          } else {
            return item.featuredImage
          }
        }
      }
      return {}
    },
    ariaCartTitleId() {
      return `${this._uid}-cart-title`
    },
    wrapperClassNames() {
      const lineItems = this.lineItems
      let result = ['l-flex', 'l-flex--column']
      if (lineItems && lineItems.length === 0) {
        result.push('c-cart__wrapper--empty', 'u-center-text')
      }
      return result
    },
    miniCartProps() {
      // products should be fetched from the promotional collection
      // OR the fallback collection depending on conditions
      const productHasGiftWithPurchase = this.productHasGiftWithPurchase
      const lineItemProps = this.lineItemProps
      const lineItems = this.lineItems
      const productCollection = this.giftWithPurchaseCollectionData
      let result = null
      if (
        productCollection &&
        productCollection.products &&
        productCollection.products.length
      ) {
        let product = { ...productCollection.products[0] }
        if (lineItems && lineItems.length) {
          for (let item of lineItems) {
            if (productHasGiftWithPurchase(item)) {
              product = item
            }
          }
        }
        // console.log('miniCartProps', product, lineItems)
        result = {
          product,
          productCollection,
          lineItems,
          lineItemProps: [...lineItemProps]
        }
      }
      return result
    },
    filteredLineItems() {
      const CART_DEBUG_MODE = this.$config.CART_DEBUG_MODE
      const hiddenLineItemHandles = this.hiddenLineItemHandles
      const lineItems = this.lineItems
      let result = []
      if (isArray(hiddenLineItemHandles) && !CART_DEBUG_MODE) {
        for (let item of lineItems) {
          if (!hiddenLineItemHandles.includes(item.handle)) {
            result.push(item)
          }
        }
      } else {
        result = this.lineItems
      }
      return result
    },
    canEngrave() {
      for (let lineItem of this.lineItems) {
        if (
          !lineItem.metafieldsMap.engraving ||
          (lineItem.metafieldsMap.engraving &&
            lineItem.customAttributes &&
            lineItem.customAttributes.find(obj => obj.key == 'location'))
        ) {
          continue
        } else {
          return true
        }
      }
      return false
    }
  },
  watch: {
    lineItems(newValue, oldValue) {
      if (newValue && newValue.length == 0) {
        this.hideCart()
      }
      if (
        this.showMiniCart &&
        oldValue &&
        newValue &&
        oldValue.length + 1 === newValue.length
      ) {
        this.showMiniCart = false
        this.lineItemProps = []
      }
    }
  },
  methods: {
    ...mapMutations('cart', ['setFreeShippingThreshold']),
    ...mapActions('cart', [
      'addLineItem',
      'hideCart',
      'removeLineItem',
      'showCart',
      'addRedoToCart',
      'removeRedoFromCart',
    ]),
    handleSelectMiniCart({ lineItemProps }) {
      this.lineItemProps.push(...lineItemProps)
      this.showMiniCart = true
    },
    /**
     * Retrieves any fees specified.
     */
    async loadFees() {
      let handles = []
      if (this.engravingFeeProduct) {
        handles.push(this.engravingFeeProduct)
      }
      if (this.modBraceletSleeveFeeProduct) {
        handles.push(this.modBraceletSleeveFeeProduct)
      }
      try {
        let fees = await this.$api.getProducts({
          handles: handles
        })
        if (fees) {
          this.cartFees = fees
        }
      } catch (error) {
        console.log(error, 'Unable to load fees')
      }
    },
    async updateProduct({ selectedProduct, initialProductId }) {
      await this.removeLineItem(initialProductId)
      await this.addLineItem(selectedProduct)
    },
    async removeProduct({ id }) {
      await this.removeLineItem(id)
    },
    closeMiniCart() {
      this.showMiniCart = false
      try {
        this.$refs.checkoutButton.$el.focus()
      } catch (err) {
        console.warn(err)
      }
    },
    openEngravingUpsell() {
      this.engravingUpsellOpen = true
    },
    openEngravingList() {
      this.engravingUpsellOpen = false
      this.engravingUpsellListOpen = true
    },
    closeCart() {
      this.hideCart()
      this.engravingUpsellListOpen = false
    },
    toggleRedo() {
      if (this.redoApplied) {
        localStorage.setItem('redoOffTime', Date.now());
        this.removeRedoFromCart();
      } else {
        localStorage.removeItem('redoOffTime');
        this.addRedoToCart();
      }
    },
  }
})
</script>

<style lang="scss" scoped>
.c-cart {
  width: 100%;
  height: 100%;
  position: relative;
  &__header {
    width: 100%;
    padding: rem(10px) rem(20px);
    position: relative;
    border: none;
    background: transparent;
    cursor: pointer;
    @include themify($themes) {
      background-color: themed('background', 'base');
    }
  }
  &__close-icon {
    position: absolute;
    left: rem(20px);
  }
  &__close-icon-image {
    transform: rotate(180deg);
  }
  &__wrapper {
    flex: 1;
  }
  &__items {
    flex: 1;
  }
  &__item,
  &__items,
  &__message,
  &__wrapper {
    width: 100%;
    overflow-y: auto;
  }
  &__footer {
    width: 100%;
    overflow: auto;
    @-moz-document url-prefix() {
      @media screen and (max-width: 980px) {
        margin-bottom: 50px;
      }
    }
  }
  &__subtotal-container {
    padding: rem(10px) rem(20px);
    width: 100%;
    overflow: hidden;
    @include respond('phone') {
      margin-bottom: 60px;
    }
  }
  &__total-container {
    padding: $filter-padding;
    width: 100%;
  }
  &__item {
    @include themify($themes) {
      border-top: $border themed('foreground', 'section-border');
    }
  }
  &__redo-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: rem(10px) 0;
    font-size: rem(14px);
  }
  &__redo-icon {
    margin-left: rem(5px);
    width: rem(60px);
    height: rem(30px);
  }

  &__mini-cart {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__engraving-upsell {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: rem(10px);
    padding: rem(20px);
  }
}
</style>
