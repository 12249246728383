<template>
  <component
    :is="$attrs.type"
    v-bind="$attrs"
  />
</template>

<script>
import PageContentMixin from '~/mixins/PageContentMixin'
export default {
  components: {
    LandingPageHero: () => import('@/components/hero/LandingPageHero'),
    CollectionHero: () => import('@/components/hero/CollectionHero'),
    ProductPageHero: () => import('@/components/hero/ProductPageHero'),
    Hero: () => import('@/components/hero/Hero'),
    ImageH1Hero: () => import('@/components/hero/ImageH1Hero'),
    ShortHero: () => import('@/components/hero/ShortHero'),
  },
  mixins: [
    PageContentMixin
  ]
}
</script>
