<template>
  <button
    class="c-interface-close-button"
    aria-label="close"
    @click="close"
  >
    <img
      :src="source"
      class="c-interface-close-button__icon"
    >
  </button>
</template>

<script>
export default {
  props: {
    source: {
      type: String,
      default: require('~/assets/icons/default-close-icon.svg')
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang='scss' scoped>
.c-interface-close-button {
  background: none;
  border: none;
  width: rem(24px);
  height: rem(24px);
  cursor: pointer;
}
</style>
