<!--
/****
/* Individual products are loaded with the getProduct mixin.
/* For instructions related to connecting your invetory to
/* Nacelle, please refer to:
/*
/* https://docs.getnacelle.com/getting-started.html#_2-product-settings
/****
-->
<template>
  <div class="p-product-page">
    <div
      class="nosto_page_type"
      style="display:none"
    >
      product
    </div>
    <section class="u-margin-bottom-xx-large">
      <product-info
        :product="product"
        :products="pdpCollectionData.products"
        :size.sync="selectedRingSize"
        :grid-layout="true"
        @update:variant="currentProductVariant = $event"
      >
        <template v-slot:default="slotProps">
          <!--
          uses scoped slots found in productInfo.vue's slot to pass the props
          back up to this scope
          @link https://vuejs.org/v2/guide/components-slots.html#Scoped-Slots
        -->
          <product-add-to-cart-button
            v-bind="slotProps"
            custom-label="Send to Myself"
          />
          <!-- Second button is injected with the rise.ai app-->
        </template>
      </product-info>
    </section>
    <a v-utm-adder id="more" />
    <page-content
      v-if="showPageContent"
      :page="productPageContent"
      :products="pdpCollectionData.products"
    >
      <rebuy-collection-carousel
        class="p-product-page__featured-products"
        collection-handle="womens-silicone-rings"
      />
      <rebuy-collection-carousel
        class="p-product-page__featured-products"
        collection-handle="mens-silicone-rings"
      />
      <rebuy-similar-products-carousel
        class="p-product-page__featured-products"
        title="On the Other Hand"
        :product="product"
      />
    </page-content>
  </div>
</template>

<script>
import FeaturedProductsScreen from '~/components/screens/FeaturedProductsScreen'
import get from 'lodash/get'
import getProductInCard from '~/mixins/getProductInCard'
import gtmProductEvents from '~/mixins/gtmProductEvents'
import nmerge from '~/utils/merge-requests'
import nosto from '~/mixins/nosto'
import productHandle from '~/mixins/productHandle'
import productMetafields from '~/mixins/productMetafields'
import productPageDetails from '~/mixins/productPageDetails'
import sezzle from '~/mixins/sezzle'
import { getCollectionFromProps } from '~/mixins/getCollectionFromProps'
import { getLineItem } from '@/utils/shopifyHelper'
import { parseId } from '@/utils/parseIds'
import { v4 as uuid } from 'uuid'
import {
  RebuyCollectionCarousel,
  RebuySimilarProductsCarousel
} from '~/components/integrations/rebuy'

export default nmerge({
  mixins: [
    getProductInCard,
    getCollectionFromProps('pdpCollection'),
    sezzle(),
    productMetafields,
    productPageDetails,
    productHandle,
    gtmProductEvents,
  ],
  components: {
    RebuyCollectionCarousel,
    RebuySimilarProductsCarousel,
    FeaturedProductsScreen
  },
  data() {
    return {
      riseInit: false,
    }
  },
  /**
   * @link <https://help.rise.ai/en/articles/4858938-using-rise-with-a-headless-store>
   * NOTE: the markup & init object has to be available on the page before the
   * rise script loads.
   */
  mounted() {
    if (
      process.client &&
      this.product &&
      this.product.handle &&
      this.product.handle === 'giftcard'
    ) {
      window.Rise = {
        onGiftAdded: (lineItemProps) => {
          if (lineItemProps && lineItemProps.gift) {
            let customAttributes = []
            for (let [ key, value ] of Object.entries(lineItemProps.gift)) {
              if (key && value) {
                customAttributes.push({ key, value })
              }
            }
            return this.$store.dispatch('cart/addLineItem', getLineItem(
              this.product,
              this.currentProductVariant,
              { customAttributes }
            ))
          }
        },
        cart: { token: uuid() },
        product: { id: parseId(this.product.pimSyncSourceProductId) },
        full_product: { available: true },
        is_product_page: true,
        using_add_to_cart_flow: false,
        is_floating_cart_theme: true,
      }
      const i = setInterval(() => {
        if (
          window.Rise &&
          window.Rise.settings &&
          window.Rise.cart &&
          window.Rise.cart.token
        ) {
          clearInterval(i)
          window.Rise.settings.headless_without_shopify_cart_flow = true
          this.addMetafields({
            key: 'cart_token',
            value: window.Rise.cart.token
          })
        }
      }, 500)
      setTimeout(() => {
        if (
          window.Rise &&
          (!window.Rise.settings || !window.Rise.cart)
        ) {
          clearInterval(i)
          console.warn('unable to read Rise.settings!')
        }
      }, 10000)
      this.$nextTick(() => {
        this.riseInit = true
      })
    }
  },
  head() {
    const riseInit = this.riseInit
    const subdomain = get(this.$config, 'MYSHOPIFY_SUBDOMAIN')
    let script = []
    if (subdomain) {
      // wait until the init code & markup are loaded in the page
      if (riseInit) {
        script.push({
          hid: `j-str-rise-ai--${subdomain}`,
          src: `https://str.rise-ai.com/?shop=${subdomain}.myshopify.com`,
          defer: true,
        })
      }
    } else if (process.env.NODE_ENV !== 'production') {
      console.error('[rise.ai] missing subdomain', subdomain)
    }
    return { script }
  },
})
</script>

<style lang="scss" scoped>
.p-product-page {
  padding: $section-padding;
  &__details {
    @include themify($themes) {
      background-color: themed('background', 'xx-light');
    }
  }
}
</style>
