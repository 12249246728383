<!--
/****
/* Customize your Nacelle content by taking advantage
/* of named slots. For more details, refer to:
/*
/* https://docs.getnacelle.com/nuxt/pages.html#customizing-homepage-content-output
/****
-->
<template>
  <div
    v-if="article"
    class="p-article"
  >
    <div
      class="nosto_page_type"
      style="display:none"
    >
      other
    </div>
    <article>
      <hero
        :title="article.title"
        :desktop-background-image="backgroundImage"
        :mobile-background-image="backgroundImageMobile || backgroundImage"
        text-color="white"
      />
      <div class="p-article__content">
        <div class="l-container">
          <page-content
            v-if="topContent"
            :content="topContent"
            :get-product-function="fetchProduct"
          />
          <blog-article-header
            :title="article.title"
            :author="article.author"
            :tags="article.tags"
            :publish-date="article.publishDate"
          >
            <!-- Extra HTML markup can also be added below the default header content -->
          </blog-article-header>
          <blog-article-content :article="article">
            <!-- Extra HTML added after content -->
            <nuxt-link
              :to="`/blogs/${$route.params.blogHandle}/`"
              class="t-link"
            >
              Back to Blog
            </nuxt-link>
          </blog-article-content>
          <page-content
            v-if="bottomContent"
            :content="bottomContent"
            :get-product-function="fetchProduct"
          />
        </div>
      </div>
    </article>
  </div>
</template>

<script>
// import getCollection from '~/mixins/getCollection'
import BlogArticleContent from '~/components/nacelle/BlogArticleContent'
import BlogArticleHeader from '~/components/nacelle/BlogArticleHeader'
import get from 'lodash/get'
import getBlogArticle from '~/mixins/getBlogArticle'
import getProductInCard from '../../../mixins/getProductInCard'
import Hero from '@/components/hero/Hero'
import nmerge from '~/utils/merge-requests'
import PageContent from '~/components/nacelle/PageContent'
import viewEvent from '~/mixins/viewEvent'
import { mapGetters } from 'vuex'

export default nmerge({
  components: {
    Hero,
    BlogArticleHeader,
    BlogArticleContent,
    PageContent
  },
  mixins: [
    getProductInCard,
    // getCollection({ redirect: false }),
    getBlogArticle(),
    viewEvent('article'),
    getProductInCard,
  ],
  computed: {
    ...mapGetters('space', ['getMetatag']),
    topContent() {
      return get(this.article, 'topContent')
    },
    bottomContent() {
      return get(this.article, 'bottomContent')
    },
    backgroundImage() {
      return get(this.article, 'featuredMedia')
    },
    backgroundImageMobile() {
      return undefined
    }
  },
  head() {
    if (this.article) {
      const properties = {}
      const meta = []
      const title = this.getMetatag('title')

      if (this.article.title) {
        let fullTitle = this.article.title

        if (title) {
          fullTitle = `${fullTitle} | ${title.value}`
        }

        properties.title = fullTitle
        meta.push({
          hid: 'og:title',
          property: 'og:title',
          content: fullTitle
        })
      }

      if (this.article.description) {
        meta.push({
          hid: 'description',
          name: 'description',
          content: this.article.description
        })
        meta.push({
          hid: 'og:description',
          property: 'og:description',
          content: this.article.description
        })
      }

      if (this.article.featuredMedia) {
        meta.push({
          hid: 'og:image',
          property: 'og:image',
          content: this.article.featuredMedia.src
        })
      }

      meta.push({
        hid: 'og:type',
        property: 'og:type',
        content: 'article'
      })

      return {
        ...properties,
        meta
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.p-article {
  &__content {
    padding: $section-padding;
  }
}
</style>
