<template>
  <div>
    <interface-modal :modal-open="showModal" @closeModal="showModal = false">
      <code>{{ errorMessage }}</code>
    </interface-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import InterfaceModal from '~/components/nacelle/InterfaceModal'
export default {
  components: {
    InterfaceModal
  },
  data() {
    return {
      showModal: false
    }
  },
  computed: {
    ...mapState('cart', ['error']),
    errorMessage() {
      // nacelle shopify checkout does something silly:
      // ```
      // throw new Error(String(err));
      // ```
      // so we can't access the real error message
      const error = this.error
      return (error && error.message || '').replace('Error: Error: ', '')
    }
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      const { type, payload } = mutation

      if (type === 'cart/setCartError') {
        this.showModal = true
      }
    })
  }
}
</script>

<style></style>
