<template>
  <div
    :aria-hidden="!modalOpen"
    :aria-labelledby="ariaModalTargetId"
    role="dialog"
    class="c-interface-modal"
    :class="variantClassNames"
    @close="closeModal"
    @click="closeModal"
  >
    <div
      v-show="modalOpen"
      class="c-interface-modal__wrapper"
      :class="{'c-interface-modal__wrapper--full':fullScreen}"
      @click.stop
    >
      <slot
        :tabindex="modalOpen ? 0 : undefined"
        @close="closeModal"
      />
    </div>
  </div>
</template>
  
<script>
  export default {
    props: {
      modalOpen: {
        type: Boolean,
        default: false
      },
      align: {
        type: String,
        default: 'left'
      },
      fullScreen: {
        type: Boolean,
        default: false
      },
    },
    computed: {
      ariaModalTargetId() {
        return `${this._uid}-interface-modal`
      },
      variantClassNames() {
        const modalOpen = this.modalOpen
        const align = this.align
        let result = []
        if (modalOpen) {
          result.push('c-interface-modal--open')
        }
        switch (align) {
        case 'left':
        case 'right':
          result.push(`c-interface-modal--align-${align}`)
          break
        }
        return result
      }
    },
    watch: {
      async modalOpen() {
        if (this.modalOpen == true) {
          document.body.classList.add("u-overflow-hidden")
          try {
            if (this.$refs.modalTarget && this.$refs.modalTarget.focus) {
              await this.$nextTick()
              this.$refs.modalTarget.focus()
            }
          } catch (err) {
            console.warn(err)
          }
        } else {
          document.body.classList.remove("u-overflow-hidden")
        }
      }
    },
    methods: {
      closeModal() {
        this.$emit('close')
      },
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .c-interface-modal {
    position: relative;
    overflow: hidden;
  
    &__wrapper {
      position: fixed;
      top: 30px;
      @include respond('phone') {
        top: 0;
      }
      bottom: 30px;
      left: calc((100vw - 800px) / 2);
      display: flex;
      flex-wrap: wrap;
      width: 800px;
      border-radius: rem(2px);
      height: 100vh;
      height: -webkit-fill-available;
      max-height: -webkit-fill-available;
      overflow: hidden;
      z-index: $zindex-modal;
      cursor: default;
      @include themify($themes) {
        background-color: themed('background', 'base');
      }
    }
    &--open {
      background-color: rgba(0, 0, 0, 0.5);
      z-index: $zindex-modal-overlay;
      width: 100vw;
      height: 100vh;
      height: -webkit-fill-available;
      max-height: -webkit-fill-available;
      cursor: pointer;
      position: fixed;
      top: 0;
      overflow: hidden;
    }
    &--open &__wrapper {
      &--full {
        width: 100vw;
      }
      @include respond('phone') {
        width: 100vw;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
      @include respond('tab-port') {
        width: 100vw;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }
  }
  </style>
  
