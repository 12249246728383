<template>
  <div class="c-back-in-stock">
    <p class="t-paragraph">
      Subscribe to back in stock notifications
    </p>
    <form
      v-if="formVisible"
      class="c-back-in-stock__form"
      :class="{
        'c-back-in-stock__form--pending': requestPending
      }"
      @submit.prevent="sendBackInStockNotificationRequest"
    >
      <text-input
        v-model="emailAddress"
        class="c-back-in-stock__form__input"
        type="email"
        icon="envelope"
        placeholder="Enter your email..."
        :disabled="requestPending"
      />
      <btn
        class="c-back-in-stock__notify-button"
        :button-size="['thin', 'small']"
        :is-disabled="requestPending"
      >
        Notify Me
      </btn>
    </form>
    <div
      v-else
      class="c-back-in-stock__success"
      :class="['u-center-text']"
    >
      <p class="t-paragraph">
        You will recieve an email message when this product is back in stock!
      </p>
    </div>
  </div>
</template>

<script>
import TextInput from '@/components/inputs/TextInput'
import Btn from '@/components/button/Btn'
export default {
  components: {
    Btn,
    TextInput
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    variant: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      emailAddress: null,
      requestStatus: 'initial',
      acceptsMarketing: false,
      response: null
    }
  },
  computed: {
    shopUrl() {
      const subdomain = process.env.MYSHOPIFY_SUBDOMAIN
      return `${subdomain}.myshopify.com`
    },
    productID() {
      return atob(this.product.pimSyncSourceProductId).split('/').pop()
    },
    variantID() {
      return atob(this.variant.id).split('/').pop()
    },
    utcOffsetInSecs() {
      if (process.browser) {
        const date = new Date()
        const offsetInMins = date.getTimezoneOffset()
        const offsetInSecs = offsetInMins * 60
        return offsetInSecs
      }
      return null
    },
    requestPending() {
      return this.requestStatus === 'pending'
    },
    formVisible() {
      return ['initial', 'pending'].includes(this.requestStatus)
    }
  },
  mounted() {
    let height = document.getElementsByClassName("c-back-in-stock__form__input")[0].offsetHeight
    document.getElementsByClassName("c-back-in-stock__notify-button")[0].setAttribute("style", `height: ${height}px`)
  },
  methods: {
    async sendBackInStockNotificationRequest() {
      const vm = this
      vm.requestStatus = 'pending'
      return this.$axios(`https://app.backinstock.org/stock_notification/create.json?shop=${vm.shopUrl}&notification[email]=${vm.emailAddress}&notification[product_no]=${vm.productID}&notification[quantity_required]=1&notification[accepts_marketing]=${vm.acceptsMarketing}&notification[customer_utc_offset]=${vm.utcOffsetInSecs}&variant[variant_no]=${vm.variantID}`)
        .then(res => {
          vm.response = res.data
          if (res.data.errors) {
            vm.requestStatus = 'fail'
          } else {
            vm.requestStatus = 'success'
          }
        }).catch(res => {
          vm.response = res.error
          vm.requestStatus = 'fail'
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.c-back-in-stock {
  &__form {
    display: flex;
    flex-direction: row;
    &--pending {
      cursor: wait;
    }
    &__input {
      padding: 0px;
    }
  }
  &__notify-button {
    // always match the height of the form element
    height: rem(37px);
    text-overflow: clip;
    overflow: hidden;
  }
}
</style>
