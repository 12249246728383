<!--
/****
/* Individual products are loaded with the getProduct mixin.
/* For instructions related to connecting your invetory to
/* Nacelle, please refer to:
/*
/* https://docs.getnacelle.com/getting-started.html#_2-product-settings
/****
-->
<template>
  <div class="p-product-bracelet-page">
    <div
      class="nosto_page_type"
      style="display:none"
    >
      product
    </div>
    <section class="u-margin-bottom-xx-large">
      <product-info
        v-if="product"
        :product="product"
        :products="pdpCollectionData.products"
        :size.sync="selectedRingSize"
        @modal:open="openGuideModal"
        @modal:close="closeGuideModal"
        :grid-layout="true"
        @engraving="startEngraving"
        @update:variant="currentProductVariant = $event"
      >
        <template v-slot:default="slotProps">
          <!--
            uses scoped slots to pass the props back up to this scope
            @link https://vuejs.org/v2/guide/components-slots.html#Scoped-Slots

            geofence is assumed denied until we get a confirmed positive
            response geoip api

            if a product is NOT in stock, show back in stock notification prompt
          -->
          <template v-if="!variantAvailableLoading">
            <back-in-stock
              v-if="showBackInStock(slotProps, variantAvailable)"
              :product="slotProps.product"
              :variant="slotProps.variant"
            />
            <product-add-to-cart-button
              v-else
              v-bind="{
                ...slotProps,
                shoppingGivesDetected
              }"
            />
          </template>
          <loader v-else />
        </template>
        <template
          v-if="product"
          v-slot:rating
        >
          <!-- Yotpo Star Ratings -->
          <star-rating
            :product="product"
            class="u-margin-bottom-x-small"
            style="display: inline-block"
          />
        </template>
        <template v-slot:recommendations>
          <div
            v-if="shoppingGivesEnabled"
            id="shopping-gives-widget"
            ref="shoppingGivesWidget"
            class="p-product-bracelet-page__shopping-gives-widget"
            @click="detectShoppingGivesState"
          />
          <rebuy-related-products
            :product="product"
          />
        </template>
      </product-info>
    </section>
    <a v-utm-adder id="more" />
    <page-content
      :page="productPageContent"
      :products="pdpCollectionData.products"
    >
      <rebuy-trending-products-carousel
        slot="on-the-other-hand"
        class="l-container u-margin-bottom-x-large"
        title="On the Other Hand"
        :product="product"
      />
      <section
        slot="reviews"
        class="l-container u-margin-bottom-x-large"
      >
        <client-only>
          <!-- Yotpo Reviews -->
          <reviews-widget
            v-if="product"
            :product="product"
            :pathname="$route.fullPath"
          />
        </client-only>
      </section>
    </page-content>
    <modal
      v-bind="mappedModal"
      :show-modal="guideModal"
      @modal="closeGuideModal"
      v-if="mappedModal"
    >
      <template
        v-for="section in mappedModal.sections"
      >
        <component
          :key="section.id"
          :is="section.contentType"
          v-if="section.contentType"
          :id="section.handle"
          v-bind="section.data"
          padding-top="0"
          padding-bottom="0"
          :get-product-function="() => {}"
        />
      </template>
    </modal>
  </div>
</template>

<script>
import BackInStock from '~/components/nacelle/BackInStock'
import getProductInCard from "~/mixins/getProductInCard";
import gtmProductEvents from '~/mixins/gtmProductEvents'
import nmerge from '~/utils/merge-requests'
import nosto from '~/mixins/nosto'
import isEqual from 'lodash/isEqual'
import productHandle from '~/mixins/productHandle'
import productMetafields from '~/mixins/productMetafields'
import productPageDetails from '~/mixins/productPageDetails'
import productVariantAvailable from '~/mixins/productVariantAvailable'
import sezzle from '~/mixins/sezzle'
import { getCollectionFromProps } from '~/mixins/getCollectionFromProps'
import { mapState, mapActions } from 'vuex'
import Modal from '@/components/modal/Modal'
import { setupBridge } from '~/modules/storyblok/bridge'
import getContentItem from '~/mixins/getContentItem'
import { RebuyTrendingProductsCarousel, RebuyRelatedProducts } from '~/components/integrations/rebuy'
export default nmerge({
  components: {
    RebuyRelatedProducts,
    RebuyTrendingProductsCarousel,
    BackInStock,
    Modal
  },
  mixins: [
    getCollectionFromProps('pdpCollection'),
    sezzle(),
    getProductInCard,
    productMetafields,
    productPageDetails,
    productHandle,
    productVariantAvailable,
    gtmProductEvents,
    getContentItem('modal', {
      handle: 'bracelet-size-modal',
      type: 'globalModal',
      mapping: 'storyblok'
    })
  ],
  data() {
    return {
      mappedModal: null,
      guideModal: false
    }
  },
  watch: {
    async product() {
      if (this.product) {
        await this.$nextTick()
        this.setEngravingContext({
          mode: 'pdp',
          product: this.product,
          variant: this.currentProductVariant
        })
      }
    }
  },
  computed: {
    ...mapState('engraving', {
      engravingProduct: ({ product }) => product
    }),
    engravingVariant: {
      get() {
        return this.$store.state.engraving.variant
      },
      set(variant) {
        this.setEngravingContext({
          mode: 'pdp',
          product: this.product,
          variant
        })
      }
    },
    openEngraving: {
      get() {
        return this.$store.state.engraving.open
      },
      set(open) {
        if (open) {
          this.setEngravingContext({
            mode: 'pdp',
            product: this.product,
            variant: this.currentProductVariant
          })
          this.$nextTick(() => this.$store.dispatch('engraving/open'))
        } else {
          this.$store.dispatch('engraving/close')
        }
      }
    },
    showEngravingScreen() {
      const product = this.engravingProduct
      let result = false
      if (product && product.metafields) {
        result = !!product.metafields.find(obj => {
          return obj.key == 'engraving'
        })
      }
      return result
    },
    isGiftCard() {
      return this.product.vendor.toLowerCase().includes('rise.ai')
    },
    rowData() {
      if (this.productPageContent) {
        let sections = this.productPageContent.sections
        for (let section of sections) {
          if (section.contentType == 'row') {
            return section
          }
        }
        return this.productPageContent[1]
      }
    },
  },
  watch: {
    modal(value, oldValue) {
      if (!isEqual(value, oldValue)) {
        if (value) {
          this.mappedModal = value.data
        }
      }
    }
  },
  methods: {
    ...mapActions('engraving', {
      setEngravingContext: 'setContext'
    }),
    startEngraving(data) {
      this.currentProductVariant = data.variant
      this.selectedRingSize = parseInt(data.size)
      this.openEngraving = true
    },
    closeEngraving() {
      this.openEngraving = false
    },
    openGuideModal() {
      this.guideModal = true
    },
    closeGuideModal() {
      this.guideModal = false
    }
  }
})
</script>

<style lang="scss" scoped>
  .p-product-bracelet-page {
    padding: $section-padding;
    &__details {
      @include themify($themes) {
        background-color: themed('background', 'xx-light');
      }
    }
    &__shopping-gives-widget {
      height: rem(50px);
      overflow: hidden;
      margin-top: rem(20px);
    }
  }
</style>
