<template>
  <div class="c-collection-grid-screen section l-container">
    <filter-bar
      v-if="enableFilterBar"
      :products="products"
      :modal-open="modalOpen"
      :embed-filter="true"
      :modal-offset-top="navHeight"
      @clear="filtered = false"
      @click="toggleFilterModal"
      @close="toggleFilterModal"
      @updated="filteredData = $event"
      @sortProducts="sortProducts"
    />
    <div
      v-if="showNoResults"
      class="c-collection-grid-screen__no-result"
    >
      <p class="t-paragraph">
        Nothing matches your search. Try removing some filters.
      </p>
    </div>
    <product-grid
      v-else
      :key="gridKey"
      :products="activeProducts"
      :disable-product-shop-button="true"
      :filtered="filtered"
      :sorted="sorted"
      :loading="loading"
      :truncate="truncate"
      :max-products="maxProducts"
    />
  </div>
</template>

<script>
import { getCollectionFromProps } from '~/mixins/getCollectionFromProps'
import ProductGrid from '@/components/productGrid/ProductGrid'
import { getKey } from '~/utils/graphql-proxy'
import FilterManager from '~/mixins/FilterManager'
import FilterBar from '~/components/FilterBar'

export default {
  components: {
    FilterBar,
    ProductGrid
  },
  mixins: [
    getCollectionFromProps('collection', { reloadOnce: false }),
    FilterManager,
  ],
  props: {
    enableFilterBar: {
      type: Boolean,
      default: false
    },
    collection: {
      type: String,
      default: ''
    },
    truncate: {
      type: Boolean,
      default: undefined
    },
    maxProducts: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      navHeight: null,
    }
  },
  computed: {
    gridKey() {
      return getKey(this.$props)
    },
    products() {
      const collectionData = this.collectionData
      return collectionData?.products || []
    },
    activeProducts() {
      const filtered = this.filtered
      const filteredProducts = this.filteredData
      const products = this.products
      return (filtered) ? filteredProducts : products
    },
    showNoResults() {
      const activeProducts = this.activeProducts
      const products = this.products
      return (
        products && products.length &&
        activeProducts && (activeProducts.length === 0)
      )
    },
  },
  watch: {
    modalOpen() {
      this.getNavHeight()
    },
    filteredData() {
      this.updateFilteredWatch()
    },
    products() {
      this.updateFilteredWatch()
    },
  },
  methods: {
    updateFilteredWatch() {
      const value = this.filteredData
      const products = this.products
      this.getNavHeight()
      this.filtered = !!(
        products && products.length &&
        value
      )
    },
    getNavHeight() {
      if (process.client && !this.navHeight) {
        try {
          const el = document.getElementsByClassName('j-global-header-height')[0]
          this.navHeight = el.clientHeight
        } catch (err) {
          // this is fine
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.c-collection-grid-screen {
  min-height: rem(500px);
  &__no-result {
    margin: rem(20px);
    height: rem(200px);
  }
}
</style>
