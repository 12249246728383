<template>
  <div
    class="u-app-wrapper theme-gold-2023"
    :class="{
      'u-overflow-hidden': scrollLockEnabled,
      'u-scroll-lock': scrollLockEnabled,
    }"
  >
    <!-- IMPORTANT: c-app has to be nested in the div with the class theme-gold-2023 -->
    <div class="c-app">
      <!-- IMPORTANT: c-app has to be nested in the div with the class theme-gold-2023 -->
      <global-header-screen ref="header" />
      <nuxt
        class="c-app__nuxt"
        :class="{
          'u-overflow-hidden': scrollLockEnabled,
          'u-scroll-lock': scrollLockEnabled,
        }"
      />
      <client-only>
        <event-dispatcher />
      </client-only>
      <error-modal />
      <cart-watch />
    </div>
  </div>
</template>

<script>
import CartWatch from '~/components/cart/CartWatch'
import ErrorModal from '~/components/nacelle/ErrorModal'
import EventDispatcher from '~/components/nacelle/EventDispatcher'
import GlobalHeaderScreen from '~/components/screens/GlobalHeaderScreen'
import Layout from '~/mixins/Layout.vue'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  mixins: [
    Layout
  ],
  components: {
    GlobalHeaderScreen,
    EventDispatcher,
    ErrorModal,
    CartWatch
  }
}
</script>
