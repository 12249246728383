var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"p-product-page",class:_vm.pageClass},[_c('div',{staticClass:"nosto_page_type",staticStyle:{"display":"none"}},[_vm._v("\n    product\n  ")]),_vm._v(" "),_c('section',{staticClass:"u-margin-bottom-xx-large"},[_c('product-info',{attrs:{"product":_vm.product,"products":_vm.pdpCollectionData.products,"bundle-products":_vm.bundleProducts,"bundle-options":_vm.bundleOptions,"size":_vm.selectedRingSize,"variant-available":_vm.variantAvailable,"grid-layout":true,"size-guide-modal":true,"product-source-id":_vm.productSourceId},on:{"update:size":function($event){_vm.selectedRingSize=$event},"engraving":_vm.startEngraving,"update:variant":function($event){_vm.currentProductVariant = $event},"modal:open":_vm.openGuideModal,"modal:close":_vm.closeGuideModal},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [(!_vm.variantAvailableLoading)?[(_vm.showBackInStock(slotProps, _vm.variantAvailable))?_c('back-in-stock',{attrs:{"product":slotProps.product,"variant":slotProps.variant}}):_c('product-add-to-cart-button',_vm._b({},'product-add-to-cart-button',{
              ...slotProps,
              disabled: _vm.disableAtcBtn(slotProps),
              shoppingGivesDetected: _vm.shoppingGivesDetected,
              allOptionsSelected: _vm.allOptionsSelected(slotProps),
              variant: _vm.variantProp(slotProps),
            },false))]:_c('loader'),_vm._v(" "),(_vm.geofenceAllowed === false)?[_vm._v("\n          "+_vm._s(_vm.collectionLabel)+" "+_vm._s(_vm._f("formatCodes")(_vm.geofenceCodes))+"\n        ")]:_vm._e()]}},(_vm.product && _vm.product.pimSyncSourceProductId)?{key:"rating",fn:function(){return [_c('star-rating',{staticClass:"u-margin-bottom-x-small",staticStyle:{"display":"inline-block"},attrs:{"product":_vm.product}})]},proxy:true}:null,{key:"recommendations",fn:function(){return [(_vm.shoppingGivesEnabled)?_c('div',{ref:"shoppingGivesWidget",staticClass:"p-product-page__shopping-gives-widget",attrs:{"id":"shopping-gives-widget"},on:{"click":_vm.detectShoppingGivesState}}):_vm._e(),_vm._v(" "),_c('rebuy-related-products',{attrs:{"product":_vm.product}})]},proxy:true}],null,true)})],1),_vm._v(" "),_c('a',{directives:[{name:"utm-adder",rawName:"v-utm-adder"}],attrs:{"id":"more"}}),_vm._v(" "),_c('page-content',{attrs:{"page":_vm.productPageContent,"products":_vm.pdpCollectionData.products,"product":_vm.product}},[_c('rebuy-trending-products-carousel',{staticClass:"l-container u-margin-bottom-x-large",attrs:{"slot":"on-the-other-hand","title":"On the Other Hand"},slot:"on-the-other-hand"}),_vm._v(" "),(_vm.product && _vm.product.pimSyncSourceProductId)?_c('section',{staticClass:"l-container u-margin-bottom-x-large",attrs:{"slot":"reviews"},slot:"reviews"},[_c('client-only',[(_vm.product)?_c('reviews-widget',{attrs:{"product":_vm.product,"pathname":_vm.$route.fullPath}}):_vm._e()],1)],1):_vm._e()],1),_vm._v(" "),(_vm.mappedModal)?_c('modal',_vm._b({attrs:{"show-modal":_vm.guideModal},on:{"modal":_vm.closeGuideModal}},'modal',_vm.mappedModal,false),[_vm._l((_vm.mappedModal.sections),function(section){return [(section.contentType)?_c(section.contentType,_vm._b({key:section.id,tag:"component",attrs:{"id":section.handle,"get-product-function":() => {},"product":_vm.product}},'component',section.data,false)):_vm._e()]})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }