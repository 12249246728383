<template>
  <div class="c-shipping-progress l-flex l-flex--column l-flex--align-center l-flex--justify-center">
    <div
      class="c-shipping-progress__message u-center-text"
      :class="deriveBackgroundClass(backgroundColor)"
    >
      <span
        :class="messageClasses"
      >
        {{ parsedMessage }}
      </span>
    </div>
    <div class="c-shipping-progress__bar">
      <div
        class="c-shipping-progress__bar-fill"
        :class="deriveBackgroundClass(progressColor)"
        :style="{ transform: `scaleX(${progress})` }"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'ShippingProgress',
  computed: {
    ...mapState('cart', {
      backgroundColor: ({ freeShippingBackgroundColor }) => freeShippingBackgroundColor,
      color: ({ freeShippingTextColor }) => freeShippingTextColor,
      message: ({ freeShippingMessage }) => freeShippingMessage,
      progressColor: ({ freeShippingProgressColor }) => freeShippingProgressColor,
      threshold: ({ freeShippingThreshold }) => freeShippingThreshold,
      thresholdReachedMessage:({ freeShippingReachedMessage }) => freeShippingReachedMessage
    }),
    ...mapGetters('cart', ['freeShippingThresholdPassed', 'amountUntilFreeShipping']),
    parsedMessage() {
      const { currency } = this.$options.filters;
      let replaceKeyword;
      if (this.freeShippingThresholdPassed) {
        replaceKeyword = currency(this.threshold);
        return this.thresholdReachedMessage.replace('[threshold]', replaceKeyword);
      }
      replaceKeyword = currency(this.amountUntilFreeShipping);
      return this.message.replace('[difference]', replaceKeyword);
    },
    progress() {
      if (this.freeShippingThresholdPassed) {
        return 1;
      }
      return 1 - (this.amountUntilFreeShipping / this.threshold);
    },
    messageClasses() {
      if (this.color.length) {
        return [
          't-heading-9',
          `t-heading-9--${this.color}`
        ]
      }

      return 't-heading-9';
    }
  },
  methods: {
    deriveBackgroundClass(value) {
      if (value.length) {
        return `u-background-color-${value}`
      }
      return '';
    }
  },
}
</script>

<style lang="scss" scoped>
.c-shipping-progress {
  width: 100%;

  &__message {
    width: 100%;
    padding: rem(11px) 0;
  }

  &__bar {
    position: relative;
    width: 100%;
    padding: rem(3px) 0;

    @include themify($themes) {
      background: themed('background', 'xx-light');
    }
  }

  &__bar-fill {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: left;
    transition: transform 0.2s ease;
  }
}
</style>