<script>
/**
 * component-mapping screen
 * add additional product-page overrides in
 * ~/components/screens/ProductPageScreen
 */
import ProductPageScreen from '~/components/screens/ProductPageScreen'
import nmerge from '~/utils/merge-requests'
export default nmerge({ mixins: [ ProductPageScreen ] })
</script>
