<template>
  <interface-modal
    :modal-open="cartVisible"
    align="right"
    @close="hideCart()"
  >
    <cart @close="hideCart()" />
  </interface-modal>
</template>

<script>
import Cart from '~/components/cart/Cart'
import InterfaceModal from '~/components/nacelle/InterfaceModal'
import { mapState, mapActions } from 'vuex'
export default {
  components: {
    Cart,
    InterfaceModal
  },
  computed: mapState('cart', ['cartVisible']),
  methods: mapActions('cart', ['hideCart']),
}
</script>
