var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"u-app-wrapper theme-gold-2023",class:{
    'u-overflow-hidden': _vm.scrollLockEnabled,
    'u-scroll-lock': _vm.scrollLockEnabled,
  }},[_c('div',{staticClass:"c-app"},[_c('global-header-screen',{ref:"header"}),_vm._v(" "),_c('nuxt',{staticClass:"c-app__nuxt",class:{
        'u-overflow-hidden': _vm.scrollLockEnabled,
        'u-scroll-lock': _vm.scrollLockEnabled,
      }}),_vm._v(" "),_c('client-only',[_c('event-dispatcher')],1),_vm._v(" "),_c('error-modal'),_vm._v(" "),_c('cart-watch')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }