<template>
  <engraving-modal
    class="c-engraving-screen-wrapper"
    :modal-open="engravingVisible"
    @close="closeEngraving"
  >
    <engraving-modal-screen
      :key="screenKey"
      v-bind="engravingContext[ringIndex]"
      :rings-length="ringLength"
      :current-ring-index="ringIndex"
      @addEngravingToCart="addEngravingToCart"
      @closeEngraving="closeEngraving"
    />
  </engraving-modal>
</template>
<script>
import EngravingModalScreen from '~/components/screens/EngravingModalScreen'
import InterfaceModal from '~/components/nacelle/InterfaceModal'
import EngravingModal from '~/components/nacelle/EngravingModal'
import { mapGetters } from 'vuex'
import get from 'lodash/get'

export default {
  components: {
    InterfaceModal,
    EngravingModal,
    EngravingModalScreen,
  },
  props: {
    engravingContext: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      ringLength: this.engravingContext.length,
      ringIndex: 0
    }
  },
  computed: {
    ...mapGetters('engraving', ['engravingVisible']),
    screenKey() {
      const evgravingContext = this.engravingContext
      const ringIndex = this.ringIndex
      const currentRing = evgravingContext[ringIndex]
      const handle = get(currentRing, 'product.handle')
      const variantId = get(currentRing, 'variant.id')

      return `${ringIndex}-${handle}-${variantId}`
    },
  },
  watch: {
    engravingContext() {
      this.ringLength = this.engravingContext.length;
    },
  },
  methods:{
    addEngravingToCart(engravingState) {
      if (this.ringIndex + 1 < this.ringLength) {
        this.ringIndex++
      }
      this.$emit('addEngravingToCart', engravingState)
    },
    closeEngraving() {
      this.$emit('closeEngraving')
      this.ringIndex = 0
      this.ringLength = 0
    }
  }
}

</script>
<style lang="scss" scoped>
.c-engraving-screen-wrapper {}
</style>

