<template>
  <div v-if="mappedRingGuidedContent">
    <GuidedQuiz
      v-bind="$attrs"
      :results="mappedRingGuidedContent.results"
    />
  </div>
</template>

<script>
import clone from 'lodash/clone'
import GuidedQuiz from '@/components/guidedQuiz/GuidedQuiz'
import FeaturedProducts from "./FeaturedProductsScreen"

export default {
  name: "QuidedQuizScreen",
  components:{
    GuidedQuiz,
    FeaturedProducts,
  },
  filters: {
    clone(products) {
      return clone(products)
    }
  },
  data() {
    return {
      products: null
    }
  },
  props:{
    mappedSections: {
      type: Array,
      default: () => []
    },
    
  },
  computed: {
    mappedRingGuidedContent() {
      const productData = this.products;
      const section = this.$attrs
      let result = section ? {
        title: section.entryTitle,
        results: clone(productData),
      } : null;
      return result
    }
  },
  async mounted () {
      try {
        let products = await this.$api.getCollectionProducts({
          handle: 'guided-quiz'
        })
        if(products) {
          this.products = products
        }
      } catch (error) {
        console.log(error, 'Unable to load products')
      }
  },
}
</script>
<style scoped></style>
