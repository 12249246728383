<template>
  <div>
    <h2 class="t-heading-3">
      {{ title }}
    </h2>
    <p class="t-paragraph">
      {{ subtitle }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Oops...no results!'
    },
    subtitle: {
      type: String,
      default: 'Try adjusting the filters or try another search...'
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
