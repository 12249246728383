<template>
  <div
    class="p-reviews"
    :class="['l-container']"
  >
    <div
      class="nosto_page_type"
      style="display:none"
    >
      other
    </div>
    <hero-banner
      type="ShortHero"
      title="Reviews"
    />
    <reviews-widget />
  </div>
</template>
<script>
import HeroBanner from '~/components/screens/HeroScreen'
import ReviewsWidget from '@/screens/ReviewsWidget'
export default {
  components: {
    HeroBanner,
    ReviewsWidget
  }
}
</script>
