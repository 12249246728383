<template>
  <div class="c-cta-grid l-container">
    <div class="l-grid l-grid--3-col">
      <div v-for="card in cards" :key="card.handle">
        <component
          :is="card.contentType"
          v-if="card.contentType"
          :id="card.handle"
          v-bind="card"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BackgroundContentCard from '@/components/card/BackgroundContentCard';
import PageContentMixin from '~/mixins/PageContentMixin';
import isMobile from '@mixins/isMobile';

export default {
  components: {
    BackgroundContentCard,
  },
  mixins: [
    PageContentMixin,
    //this.isMobile is true screen width < 900px
    isMobile
  ],
  props: {
    cards: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.c-cta-grid {
}
</style>
