<template>
  <div
    v-if="siteWideSale || bannerCartMessage"
    class="c-cart-messaging"
  >
    <span class="u-sr-only"> Banner message: </span>
    <span :aria-label="`${message}.`"> {{ message }} </span>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    siteWideSale: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('cart', [
      'bannerCartMessage',
      'siteWideSaleMessage'
    ]),
    message() {
      const siteWideSale = this.siteWideSale
      const siteWideSaleMessage = this.siteWideSaleMessage
      const bannerCartMessage = this.bannerCartMessage
      return (siteWideSale)
        ? siteWideSaleMessage
        : bannerCartMessage
    }
  }
}
</script>

<style lang="scss" scoped>
.c-cart-messaging {
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 4px;
  @include themify($themes) {
    color: themed('foreground', 'white');
    background-color: themed('accent', 'base');
  }
}
</style>
