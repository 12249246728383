<template>
  <div class="c-add-to-cart-btn">
    <p 
      v-if="selectionError && !isGiftCard && !variant && atcBtn.variant1"
      class="t-heading-8 c-add-to-cart-btn__error u-margin-bottom-xx-small"
    >
      PLEASE SELECT A SIZE
    </p>
    <btn
      v-if="isProductVariantSelectChild"
      :disabled="disableAtcButton"
      :is-disabled="disableAtcButton"
      class="add-to-cart-btn"
      :class="{ 'Rise-add-to-cart-button': isGiftCard }"
      type="primary"
      :button-size="['large']"
      @click="addToCart"
    >
      <span v-if="showSelectOptionsVariant">Select Options</span>
      <span v-if="showOutOfStockVariant">Out of Stock</span>
      <span v-if="showAddToCartVariant">
        <span>{{ addToCartLabel }}</span>
        <template v-if="!hidePrice">
          &nbsp;-&nbsp;
          <product-price
            :price="price"
            :compare-at-price="compareAtPrice"
          />
        </template>
      </span>
      <span v-if="variantInLineItems">
        <template v-if="cooldown"> Added! </template>
        <template v-else> Add another? </template>
      </span>
    </btn>
    <btn
      v-else
      type="primary"
      class="Rise-add-to-cart-button"
      :class="{ 'u-block': isGiftCard }"
      :button-size="['large']"
      :disabled="disableAtcButton && (!atcBtn.variant1 || isGiftCard)"
      :is-disabled="disableAtcButton && (!atcBtn.variant1 || isGiftCard)"
      @click="addToCart"
    >
      <slot>
        <span v-if="!onlyOneOption && product.availableForSale">
          Select Options
        </span>
        <span v-if="showAddToCart">
          <span v-if="productInfoType.default || productInfoType.variant1 || productInfoType.variant2">{{ addToCartLabel }}</span>
          <span v-else-if="productInfoType.variant3 || productInfoType.variant4">{{ addToCartLabel }} - ${{ price }}</span>
          <template v-if="!hidePrice">
            &nbsp;-&nbsp;
            <product-price
              :price="price"
              :compare-at-price="compareAtPrice"
            />
          </template>

        </span>
        <span v-if="!variant">
          <span v-if="productInfoType.default || productInfoType.variant1 || productInfoType.variant2">{{ addToCartLabel }}</span>
          <span v-else-if="productInfoType.variant3 || productInfoType.variant4">{{ addToCartLabel }} - ${{ price }}</span>
        </span>
        <!-- jgunther If you want to edit the value of the Rise.ai Button. Go to
             app -> Gift card assets -> Pop-up -> Language -> Gift Card Button
        -->
        <span v-if="showOutOfStock && variant">Out of Stock</span>
        <span v-if="onlyOneOption && variantInLineItems">
          <template v-if="cooldown"> Added! </template>
          <template v-else> Add another? </template>
        </span>
      </slot>
    </btn>
  </div>
</template>

<script>
import get from 'lodash/get'
import Btn from '@/components/button/Btn'
import ProductPrice from "@/components/productPrice/ProductPrice";
import { mapState, mapActions } from 'vuex'
import { getLineItem } from '@/utils/shopifyHelper'
export default {
  components: {
    Btn,
    ProductPrice
  },
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
    variant: {
      type: Object,
      default: undefined,
    },
    metafields: {
      type: Array,
      default: () => [],
    },
    quantity: {
      type: Number,
      default: 1,
    },
    allOptionsSelected: {
      type: Boolean,
      default: false,
    },
    confirmedSelection: {
      type: Boolean,
      default: false,
    },
    onlyOneOption: {
      type: Boolean,
      default: false,
    },
    lineItemProperties: {
      type: Array,
      default: () => [],
    },
    hide: {
      type: Boolean,
      default: false,
    },
    customLabel: {
      type: String,
      default: '',
    },
    showPrice: {
      type: Boolean,
      default: true,
    },
    hidePrice: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    shoppingGivesDetected: {
      type: [String, Boolean],
      default: false
    },
    addonLineItems: {
      // Array<{ product, variant?, customAttributes?, quantity? }>
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      cooldown: false,
      selectionError: false
    }
  },
  computed: {
    ...mapState('cart', ['lineItems']),
    ...mapState('vwo',['atcBtn', 'productInfoType']),
    addToCartLabel() {
      const customLabel = this.customLabel
      return customLabel || `Add To Cart`
    },
    price() {
      const product = this.product
      const variant = this.variant
      const isGiftCard = this.isGiftCard
      return (!isGiftCard)
        ? get(product, 'priceRange.min') || 0
        : variant.price
    },
    compareAtPrice() {
      const variant = this.variant
      let result = null
      if (variant) {
        result = get(variant, 'compareAtPrice') || 0
      }
      return result
    },
    variantInLineItems() {
      const vm = this
      if (vm.variant != null) {
        const lineItem = vm.lineItems.findIndex(lineItem => {
          return lineItem.variant.id === vm.variant.id
        })
        if (lineItem !== -1) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    isProductVariantSelectChild() {
      // FIXME use a prop instead of reading parent
      return this.$parent.$options._componentTag === 'product-variant-select'
    },
    disableAtcButton() {
      // selection-based logic moved to product-info
      // send the correct props instead of updating the logic here.
      const disabled = this.disabled
      const variant = this.variant ? this.variant : this.product.variants[0]
      const allOptionsSelected = this.allOptionsSelected
      const variantInLineItems = this.variantInLineItems
      if (disabled) {
        return true
      } else {
        // disable atc if any of these are true
        const a = !allOptionsSelected
        const b = (allOptionsSelected && !variant)
        const c = (
          !variantInLineItems &&
          allOptionsSelected &&
          variant &&
          variant.availableForSale !== true
        )
        // console.log('disable atc', { a, b, c })
        return (a || b || c)
      }
    },
    showSelectOptionsVariant() {
      const variantInLineItems = this.variantInLineItems
      const allOptionsSelected = this.allOptionsSelected
      const product = this.product
      return (
        !variantInLineItems && !allOptionsSelected && product.availableForSale
      )
    },
    showOutOfStockVariant() {
      const variantInLineItems = this.variantInLineItems
      const allOptionsSelected = this.allOptionsSelected
      const variant = this.variant
      const product = this.product
      return (
        (!variantInLineItems && allOptionsSelected && variant == undefined) ||
        (!variantInLineItems &&
          allOptionsSelected &&
          variant.availableForSale === false) ||
        !product.availableForSale
      )
    },
    showOutOfStock() {
      const variantInLineItems = this.variantInLineItems
      const allOptionsSelected = this.allOptionsSelected
      const variant = this.variant
      const product = this.product
      return (
        (!variantInLineItems && allOptionsSelected && variant == undefined) ||
        (!variantInLineItems &&
          allOptionsSelected &&
          variant.availableForSale === false) ||
        !product.availableForSale
      )
    },
    showAddToCartVariant() {
      const variantInLineItems = this.variantInLineItems
      const allOptionsSelected = this.allOptionsSelected
      const variant = this.variant
      return (
        !variantInLineItems &&
        allOptionsSelected &&
        variant &&
        variant.availableForSale == true
      )
    },
    showAddToCart() {
      const onlyOneOption = this.onlyOneOption
      const variantInLineItems = this.variantInLineItems
      const variant = this.variant
      return (
        onlyOneOption &&
        !variantInLineItems &&
        variant &&
        variant.availableForSale == true
      )
    },
    isGiftCard() {
      return (
        this.product &&
        this.product.vendor &&
        this.product.vendor.toLowerCase() === 'rise.ai'
      )
    },
    customAttributes() {
      const shoppingGivesDetected = this.shoppingGivesDetected
      const lineItemProperties = this.lineItemProperties
      let extraAttrs = []
      if (shoppingGivesDetected) {
        extraAttrs.push({
          key: '_shopping_gives',
          value: `${shoppingGivesDetected}`,
        })
      }
      return [
        ...(lineItemProperties || []),
        ...extraAttrs,
      ]
    },
  },
  watch: {
    confirmedSelection() {
      this.addToCart()
    },
    variantInLineItems(added) {
      if (added) {
        this.cooldown = true
        setTimeout(() => {
          this.cooldown = false
        }, 1 * 1000)
      }
    },
    // jgunther The add to cart button supplied by Rise.Ai does not exist as a
    // vue component therefore needs to be manually disabled until a value is
    // selected
    async disableAtcButton() {
      await this.$nextTick()
      const qs = document.getElementsByClassName('GiftWizard-gift-button')
      if (qs && qs[0] && qs[0].classList) {
        if (this.disableAtcButton) {
          qs[0].classList.add('c-btn--disabled')
        } else {
          qs[0].classList.remove('c-btn--disabled')
        }
      }
    }
  },
  methods: {
    ...mapActions('cart', [
      'addLineItem',
      'removeLineItem',
      'incrementLineItem',
      'decrementLineItem',
      'showCart'
    ]),
    async addToCart() {
      if (
        this.allOptionsSelected &&
        this.product &&
        this.product.availableForSale
      ) {
        let addedItems = []
        const lineItem = getLineItem(
          this.product,
          this.variant || this.product.variants[0],
          {
            metafields: this.metafields,
            customAttributes: this.customAttributes,
            quantity: this.quantity,
          }
        )
        addedItems.push(this.addLineItem(lineItem))
        if (this.addonLineItems && this.addonLineItems.length) {
          for (let {
            product,
            variant,
            quantity,
            customAttributes,
          } of this.addonLineItems) {
            const addonLineItem = getLineItem(
              product,
              variant || product.variants[0],
              { quantity, customAttributes }
            )
            addedItems.push(this.addLineItem(addonLineItem))
          }
        }
        await Promise.all(addedItems)
        this.showCart()
      } else {
        this.selectionError = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.c-add-to-cart-btn {
  &__error {
    color: red;
  }
}
.hide {
  visibility: hidden;
  width: 0;
  padding: 0;
  margin: 0;
}

/**
 * Rise.ai script for gift cards sets inline `display: none` on the ATC button.
 * Ensures the inline styling can't hide the default button.
 */
.u-block {
  display: block !important;
}

button.gwbutton {
  //
}
</style>
