<template>
  <div
    class="c-review-screen l-container section"
  >
    <review-carousel
      :title="title"
      :reviews="mappedReviews"
      :center-carousel="centerCarousel"
    />
  </div>
</template>

<script>
import get from 'lodash/get'
import nmerge from '~/utils/merge-requests'
import ReviewCarousel from '@/components/carousel/ReviewCarousel'
import uniqueId from 'lodash/uniqueId'
import { fromBase64 } from '~/utils/base64'

export default nmerge({
  components: {
    ReviewCarousel,
  },
  props: {
    title: {
      type: Object,
      default: () => ({})
    },
    numberOfReviews: {
      type: Number,
      default: 10
    },
    layout: {
      type: String,
      default: ''
    },
    productHandle: {
      type: String,
      default: ''
    },
    staticReviews: {
      type: Array,
      default: () => []
    }
  },
  async fetch() {
    await this.fetchData()
  },
  data() {
    return {
      product: {},
      reviews: []
    }
  },
  computed: {
    centerCarousel() {
      return (this.layout == 'center')
    },
    mappedReviews() {
      const reviews = this.reviews
      const staticReviews = this.staticReviews
      let result = []
      if (staticReviews.length) {
        for (let review of staticReviews) {
          result.push(this.formatReview(review))
        }
      } else {
        result = reviews
      }
      return result
    },
  },
  watch: {
    numberOfReviews() {
      this.fetchData()
    },
    productHandle() {
      this.fetchData()
    }
  },
  methods: {
    getProductId(product) {
      if (product && product.id) {
        const idString = fromBase64(product.id)
        return idString.split('/PRODUCT/')[1].split('/')[0]
      } else {
        return uniqueId('product-')
      }
    },
    getVariantId(variant) {
      if (variant && variant.id) {
        const idString = fromBase64(variant.id)
        console.log(idString)
        return uniqueId('variant-')
      } else {
        return uniqueId('variant-')
      }
      const idString = fromBase64(variant.id)
    },
    formatReview(review) {
      const storeId = process.env.OKENDO_SUBSCRIBER_ID
      const productName = get(this.product, 'title')
      const productImageUrl = get(this.product, 'featuredImage.src')
      const productHandle = get(this.product, 'handle')
      const productId = this.getProductId(this.product)
      const variant = get(this.product, 'variants[0]')
      const productVariantName = get(variant, 'title')
      const variantId = this.getVariantId(variant)
      return {
        "subscriberId": storeId,
        "reviewId": review._uid,
        "subscriberId_productId":`${storeId}:shopify-${productId}`,
        "productId":`shopify-${productId}`,
        "attributesWithRating":[
          /*{
            "minLabel":"Poor",
            "title":"Quality",
            "type":"range",
            "value":5,
            "maxLabel":"Excellent"
          },{
            "minLabel":"Small",
            "midLabel":"Just Right",
            "title":"Size/Fit",
            "type":"centered-range",
            "value":0,
            "maxLabel":"Big"
          }*/
        ],
        "body": review.body,
        "dateCreated": review.dateCreated,
        "dateUpdated": review.dateCreated,
        "helpfulCount":0,
        "isRecommended":true,
        productHandle,
        productImageUrl,
        productName,
        "productUrl":`//ensorings.com/products/${productHandle}`,
        productVariantName,
        "rating": review.rating,
        "reviewer":{
          "displayName": review.displayName,
          "isVerified":true
        },
        "isIncentivized":true,
        "status":"approved",
        "title": review.title,
        "unhelpfulCount":0,
        variantId
      }
    },
    async fetchData() {
      const storeId = process.env.OKENDO_SUBSCRIBER_ID
      let productId = ''

      if (this.productHandle) {
        const product = this.product = await this.$api.getProduct({ handle: this.productHandle })
        productId = this.getProductId(product)
      }

      const url = productId ?
        `https://api.okendo.io/v1/stores/${storeId}/products/shopify-${productId}/reviews?limit=${this.numberOfReviews}&orderBy=date%20desc` :
        `https://api.okendo.io/v1/stores/${storeId}/reviews?limit=${this.numberOfReviews}&orderBy=date%20desc`
      const res = await this.$axios.get(url)
      this.reviews = get(res, 'data.reviews', [])
    }
  }
})
</script>

<style lang="scss" scoped>
.c-review-screen {
  padding: 100px 50px 50px;
}
</style>
