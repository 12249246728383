<!--
/****
/* Product collections are loaded with the getCollection mixin.
/* For information about creating collections, please refer to:
/*
/* https://docs.getnacelle.com/nuxt/collections.html#adding-content-to-collections-pages
/****
-->
<template>
  <div
    class="p-collection"
    :class="pageClassNames"
  >
    <div
      v-if="collection"
      class="nosto_page_type"
      style="display:none"
    >
      category
    </div>
    <component
      v-if="showHeader"
      :is="mappedPageSections[0].contentType"
      :id="mappedPageSections[0].handle"
      v-bind="mappedPageSections[0].data"
      :get-product-function="fetchProduct"
    />
    <filter-bar
      v-if="showFilter"
      ref="filterBar"
      :visible="!isQuiz"
      :products="products"
      :modal-open="modalOpen"
      @clear="filtered = false"
      @click="toggleFilterModal"
      @close="toggleFilterModal"
      @updated="filteredData = $event"
      @sortProducts="sortProducts($event)"
    />
    <template v-if="showCollection">
      <div
        v-if="filteredProducts.length === 0"
        class="c-no-result"
      >
        <p class="t-paragraph">
          Nothing matches your search. Try removing some filters.
        </p>
      </div>
      <component
        :is="mappedPageSections[1].contentType"
        v-if="visibilityMap.modContentA"
        :id="mappedPageSections[1].handle"
        v-bind="mappedPageSections[1].data"
        :get-product-function="fetchProduct"
      />
      <collections-container
        v-if="visibilityMap.collectionsContainerA"
        :key="`${collectionHandle}-A--${filteredProducts.length}`"
        :handle="collectionHandle"
        :title="collection.title"
        :products="filteredProducts"
        :sections="page.sections"
        :filtered="filtered || singleGrid"
        :sorted="sorted"
        :first-instance="true"
        :collection-order="collectionOrder"
        @update:pagination="updatePagination"
      />
      <component
        :is="mappedPageSections[1].contentType"
        v-if="visibilityMap.contentA"
        :id="mappedPageSections[1].handle"
        v-bind="mappedPageSections[1].data"
        :get-product-function="fetchProduct"
      />
      <collections-container
        v-if="visibilityMap.collectionsContainerB"
        :key="`${collectionHandle}-B--${filteredProducts.length}`"
        :handle="collectionHandle"
        :title="collection.title"
        :products="filteredProducts"
        :sections="page.sections"
        :filtered="filtered"
        :first-instance="false"
        :collection-order="collectionOrder"
        @update:pagination="updatePagination"
      />
      <template v-for="(section, index) in lastPageSections">
        <component
          :is="section.contentType"
          :id="section.handle"
          :key="`last-${index}-${section.handle}`"
          v-bind="section.data"
          :get-product-function="fetchProduct"
        />
      </template>
    </template>
  </div>
</template>

<script>
import Btn from '@/components/button/Btn'
import CollectionsContainer from '@/components/collection/CollectionsContainer'
import CollectionSortAndFilter from '~/mixins/CollectionSortAndFilter'
import FilterBar from '~/components/FilterBar'
import FilterManager from '~/mixins/FilterManager'
import get from 'lodash/get'
import getCollection from '~/mixins/getCollection'
import getPage from '~/mixins/getPage'
import getProductInCard from '~/mixins/getProductInCard'
import HeroBanner from '~/components/screens/HeroScreen'
import InputForm from '~/components/InputForm'
import nmerge from '~/utils/merge-requests'
import ObserveEmitter from '~/components/nacelle/ObserveEmitter'
import ProductGrid from '~/components/nacelle/ProductGrid'
import Row from '@/components/layout/Row'
import viewEvent from '~/mixins/viewEvent'
import { mapGetters } from 'vuex'

export default nmerge({
  components: {
    SuperCollectionPage: () => import('~/components/SuperCollectionPage'),
    FilterBar,
    HeroBanner,
    Row,
    InputForm,
    Btn,
    ProductGrid,
    ObserveEmitter,
    CollectionsContainer,
  },
  data() {
    return {
      recos: null
    }
  },
  mixins: [
    getPage({ error: false }),
    getCollection({ orderProp: 'collectionOrder' }),
    viewEvent('collection'),
    // defines this.getFilteredProducts
    CollectionSortAndFilter,
    // fetchProduct
    getProductInCard,
    FilterManager,
  ],
  computed: {
    ...mapGetters('space', ['getMetatag']),
    singleGrid() {
      return get(this.page, 'fields.singleGrid')
    },
    showFilter() {
      const page = this.page
      return !get(page, 'fields.hideFilterBar')
    },
    showHeader() {
      const collection = this.collection
      const mappedPageSections = this.mappedPageSections
      const isQuiz = this.isQuiz
      return (
        collection &&
        mappedPageSections[0] && mappedPageSections[0].contentType &&
        !isQuiz
      )
    },
    showCollection() {
      const products = this.products
      const mappedPageSections = this.mappedPageSections
      return !!(
        products && products.length &&
        mappedPageSections
      )
    },
    visibilityMap() {
      const mappedPageSections = this.mappedPageSections
      const collection = this.collection
      const isQuiz = this.isQuiz
      const page = this.page
      const products = this.filteredProducts
      const singleGrid = this.singleGrid
      const isFiltered = this.filtered
      const filtered = isFiltered || singleGrid
      return {
        modContentA: (
          mappedPageSections &&
          mappedPageSections[1] &&
          mappedPageSections[1].contentType &&
          (collection.handle === 'mod') &&
          !isQuiz
        ),
        contentA: (
          mappedPageSections[1] &&
          mappedPageSections[1].contentType &&
          !filtered &&
          (collection.handle != 'mod') &&
          !isQuiz
        ),
        collectionsContainerA: (
          page &&
          page.sections &&
          products &&
          (products.length > 0) &&
          !isQuiz
        ),
        collectionsContainerB: (
          page &&
          page.sections &&
          products &&
          (products.length > 0) &&
          !filtered &&
          !isQuiz
        ),
      }
    },
    pageClassNames() {
      const page = this.page
      let result = []
      if (page) {
        const pageHandle = get(page, 'handle')
        const pageTheme = get(page, 'fields.pageTheme')
        result.push(`p-collection--${pageHandle}`)
        if (pageTheme) {
          result.push(`theme-${pageTheme}`)
        }
      }
      return result
    },
    mappedPageSections() {
      let result = []
      const vm = this
      const page = this.page
      if (page && page.sections) {
        result = get(page, 'sections', [])
      }
      return result
    },
    lastPageSections() {
      const sections = this.mappedPageSections
      let validSections = []
      for (let section of sections.slice(2)) {
        if (section && section.contentType !== 'NacelleReference') {
          validSections.push(section)
        }
      }
      return validSections
    },
    filteredProducts() {
      let products = this.getFilteredProducts({
        collectionHandle: this.collectionHandle,
        filtered: this.filtered,
        filteredData: this.filteredData,
        productData: this.products,
        orderProductsBy: this.sortBy
      })
      console.log('filtered-products', this.products.length, 'in', products.length, 'out')
      return products
    },
    featuredImage() {
      let result = null
      if (
        this.collection &&
        this.collection.featuredMedia &&
        this.collection.featuredMedia.src
      ) {
        result = this.collection.featuredMedia.src
      }
      return result
    },
    isQuiz() {
      return this.collection && this.collection.handle === "guided-quiz";
    },
    collectionOrder() {
      const page = this.page
      return get(page, 'superCollection')
        ? get(page, 'collectionsOrder')
        : null
    }
  },
  mounted() {
    if (this.collection && !process.server && process.env.NOSTO_ENABLED) {
      if (window.nostoClient) {
        window.nostoClient
          .viewCollection({
            collection: this.collection,
            elements: ['categorypage-nosto-2']
          })
          .then(data => (this.recos = data))
      } else {
        console.warn('nostoClient not found')
      }
    }
  },
  head() {
    if (this.page) {
      const properties = {}
      const meta = []
      const title = this.getMetatag('title')

      if (this.page.title) {
        let fullTitle = this.page.title

        if (title) {
          fullTitle = `${fullTitle} | ${title.value}`
        }

        properties.title = fullTitle
        meta.push({
          hid: 'og:title',
          property: 'og:title',
          content: fullTitle
        })
      }

      if (this.page.description) {
        meta.push({
          hid: 'description',
          name: 'description',
          content: this.page.description
        })
        meta.push({
          hid: 'og:description',
          property: 'og:description',
          content: this.page.description
        })
      }

      if (this.featuredImage) {
        meta.push({
          hid: 'og:image',
          property: 'og:image',
          content: this.featuredImage
        })
      }

      return {
        ...properties,
        meta
      }
    }
  },
  watch: {
    filteredData(value) {
      const products = this.products
      const route = this.$route
      // read valid properties from filter-bar ref
      const filterProperties = get(this.$refs, 'filterBar.filters', [])
        .map((filter) => get(filter, 'property.field'))
      let queryMatchesPropertyField = false
      for (let field of [...filterProperties, 'priceRange']) {
        if (route.query[field]) {
          queryMatchesPropertyField = true
          break
        }
      }
      if (
        products &&
        products.length &&
        queryMatchesPropertyField
      ) {
        // console.log('setting filtered', { products, route, filteredData: value })
        this.filtered = true
      } else {
        this.filtered = false
      }
    },
  },
  methods: {
    updatePagination(i) {
      // console.log('collection-page', i)
      this.fetchMore()
    }
  }
})
</script>

<style lang="scss" scoped>
.p-collection {
  .c-no-result {
    margin: rem(20px);
  }
}
</style>
