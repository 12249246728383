<template>
  <div
    v-if="cartSubtotal"
    class="c-cart-subtotal"
    :class="[
      'l-flex',
      'l-flex--justify-start'
    ]"
  >
    <h4 class="c-cart-subtotal__title t-paragraph-large u-margin-bottom-x-small">
      Subtotal
    </h4>
    <product-price
      class="c-cart-subtotal__price"
      :price="cartSubtotal"
    />
  </div>
</template>

<script>
import ProductPrice from '~/components/nacelle/ProductPrice'
import { mapGetters } from 'vuex'

export default {
  components: {
    ProductPrice
  },
  computed: {
    ...mapGetters('cart', ['cartSubtotal'])
  }
}
</script>

<style lang='scss' scoped>
.c-cart-subtotal {
  width: 100%;
  &__title {
    margin-right: auto;
  }
}
</style>
