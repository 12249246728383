<template>
  <div id="event-dispatcher" />
</template>
<script>
import { mapState, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapState('events', ['log']),
    ...mapState(['facebookCatalogID']),
    ...mapGetters('cart', ['quantityTotal']),
    ...mapState('cart', ['lineItems']),

    productIDs() {
      return this.lineItems.map(item =>
        this.decodeBase64Id(item.pimSyncSourceProductId)
      )
    },
    logEntry() {
      return [...this.log].pop()
    },
    fbq() {
      return process.client ? window.fbq : undefined
    },
    ga() {
      return process.client ? window.ga : undefined
    },
    nosto() {
      return process.client ? window.nostoClient : undefined
    }
  },
  watch: {
    log() {
      const vm = this
      switch (vm.logEntry.eventType) {
        case 'PAGE_VIEW':
          vm.facebookPageView()
          vm.googleAnalyticsPageView()
          break
        case 'PRODUCT_VIEW':
          vm.facebookProductView()
          vm.googleAnalyticsProductView()
          break
        case 'ADD_TO_CART':
          vm.facebookAddToCart()
          vm.googleAnalyticsAddToCart()
          vm.nostoAddToCart()
          break
        case 'REMOVE_FROM_CART':
          vm.googleAnalyticsRemoveFromCart()
          break
        case 'CHECKOUT_INIT':
          vm.facebookCheckoutInitiate()
          break
        case 'SEARCH_PRODUCTS':
          vm.googleAnalyticsSearchProducts()
          break
      }
    }
  },
  methods: {
    decodeBase64Id(encodedId) {
      const clean = id => id.split('::').shift()
      const decode = process.client
        ? window.atob
        : id => Buffer.from(id, 'base64').toString()

      return decode(clean(encodedId))
        .split('/')
        .pop()
    },
    //// PAGE VIEW METHODS /////////////////////////////////
    facebookPageView() {
      if (typeof this.fbq !== 'undefined') {
        this.fbq('track', 'PageView')
      }
    },
    googleAnalyticsPageView() {
      if (typeof this.ga !== 'undefined') {
        this.ga('send', 'pageview', this.logEntry.payload.path)
      }
    },

    //// PRODUCT VIEW METHODS //////////////////////////////
    facebookProductView() {
      if (typeof this.fbq !== 'undefined') {
        const vm = this
        this.fbq('track', 'ViewContent', {
          content_ids: vm.decodeBase64Id(
            vm.logEntry.payload.product.pimSyncSourceProductId
          ),
          content_name: vm.logEntry.payload.product.title,
          content_type: 'product',
          product_catalog_id: vm.facebookCatalogID
        })
      }
    },
    googleAnalyticsProductView() {
      if (typeof this.ga !== 'undefined') {
        const vm = this
        this.ga('ec:addProduct', {
          id: vm.decodeBase64Id(
            vm.logEntry.payload.product.pimSyncSourceProductId
          ),
          name: vm.logEntry.payload.product.title
        })
        this.ga('ec:setAction', 'detail')
        this.ga('send', 'pageview')
      }
    },

    //// ADD TO CART METHODS ///////////////////////////////
    facebookAddToCart() {
      if (this.fbq !== undefined) {
        const vm = this
        this.fbq('track', 'AddToCart', {
          content_ids: vm.decodeBase64Id(
            vm.logEntry.payload.product.pimSyncSourceProductId
          ),
          content_name: vm.logEntry.payload.product.title,
          content_type: 'product',
          value: vm.logEntry.payload.product.variant.price,
          currency: 'USD',
          product_catalog_id: vm.facebookCatalogID
        })
      }
    },
    googleAnalyticsAddToCart() {
      if (this.ga !== undefined) {
        const vm = this
        this.ga('ec:addProduct', {
          id: vm.decodeBase64Id(
            vm.logEntry.payload.product.pimSyncSourceProductId
          ),
          name: vm.logEntry.payload.product.title
        })
        this.ga('ec:setAction', 'add')
        this.ga('send', 'event', 'UX', 'click', 'add to cart')
      }
    },
    nostoAddToCart() {
      if (this.nosto !== undefined) {
        this.nosto.updateCart(this.logEntry.payload.cart)
      }
    },

    //// REMOVE FROM CART METHODS ///////////////////////////////
    googleAnalyticsRemoveFromCart() {
      if (typeof this.ga !== 'undefined') {
        const vm = this
        this.ga('ec:addProduct', {
          id: vm.decodeBase64Id(
            vm.logEntry.payload.product.pimSyncSourceProductId
          ),
          name: vm.logEntry.payload.product.title
        })
        this.ga('ec:setAction', 'remove')
        this.ga('send', 'event', 'UX', 'click', 'remove from cart')
      }
    },

    //// CHECKOUT INITIATION METHODS ///////////////////////////////
    facebookCheckoutInitiate() {
      if (typeof this.fbq !== 'undefined') {
        const vm = this
        this.fbq('track', 'InitiateCheckout', {
          content_ids: vm.productIDs,
          content_type: 'product',
          num_items: vm.quantityTotal,
          product_catalog_id: vm.facebookCatalogID
        })
      }
    },

    //// SEARCH PRODUCTS ///////////////////////////////
    googleAnalyticsSearchProducts() {
      if (typeof this.ga !== 'undefined') {
        const vm = this
        this.ga('send', 'pageview', `${vm.$route.path}?q=${vm.logEntry.payload.query}`);
      }
    }
  }
}
</script>

<style>
#event-dispatcher {
  display: none;
}
</style>
