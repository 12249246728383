var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-variant-title t-paragraph-medium",class:[
    'l-flex',
    'l-flex--column',
    'l-flex--align-start',
  ]},[_vm._l((_vm.variantProps),function(variantProp){return _c('div',{key:variantProp.label,staticClass:"c-variant-title__prop u-margin-bottom-xx-small"},[(variantProp.label && variantProp.value)?[_c('div',{staticClass:"l-flex"},[_c('ul',[_c('li',[_c('p',{staticClass:"c-variant-title__prop-label t-paragraph-medium--base"},[_vm._v("\n              "+_vm._s(_vm._f("capitalize")(variantProp.label))+":\n            ")])])]),_vm._v(" "),_c('ul',[_c('li',[_c('p',{staticClass:"c-variant-title__prop-value",style:(variantProp.style)},[_vm._v("\n              "+_vm._s(variantProp.value)+"\n            ")])])])])]:_c('span',{staticClass:"c-variant-title__prop-label"},[_vm._v("\n      "+_vm._s(variantProp.label)+"\n    ")])],2)}),_vm._v(" "),(_vm.canEditEngraving)?_c('div',{staticClass:"c-variant-title__edit-engraving l-flex"},[_c('button',{staticClass:"c-variant-title__button c-variant-title__button-edit",class:[
        'l-flex',
        'l-flex--align-center',
        'l-flex__nowrap'
      ],attrs:{"title":"Edit engraving."},on:{"click":_vm.editEngraving}},[_c('img',{staticClass:"c-variant-title__crud-icon",attrs:{"src":require("assets/icons/pencil.svg"),"alt":"edit"}}),_vm._v(" "),_c('span',{staticClass:"t-paragraph-medium"},[_vm._v("Edit Engraving")])]),_vm._v(" "),_c('button',{staticClass:"c-variant-title__button c-variant-title__button-remove",class:[
        'l-flex',
        'l-flex--align-center',
        'l-flex__nowrap'
      ],attrs:{"title":"Remove engraving."},on:{"click":function($event){return _vm.deleteEngraving(_vm.item)}}},[_c('img',{staticClass:"c-variant-title__crud-icon",attrs:{"src":require("assets/icons/default-close-icon-red.svg"),"alt":"remove"}}),_vm._v(" "),_c('span',{staticClass:"t-paragraph-medium c-variant-title__button-remove"},[_vm._v("Remove")])])]):(_vm.wantsEngraving)?_c('div',{staticClass:"c-variant-title__engraving-cta"},[_c('button',{staticClass:"c-variant-title__engraving-cta-button t-heading-7",attrs:{"aria-label":"Add or edit engraving."},on:{"click":_vm.editEngraving}},[_c('span',[_vm._v("\n        + Add Engraving\n      ")])])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }