<template>
  <transition name="fade-up">
    <div
      class="c-search-autocomplete"
      :class="{
        'l-grid': showSuggestions,
        'l-grid--align-start': showSuggestions,
        'l-grid--2-col-second-long': showSuggestions,
      }"
    >
      <div
        v-if="showSuggestions"
        class="c-search-autocomplete__suggestion-list"
      >
        <h2
          :class="[
            't-paragraph-large',
            'u-margin-bottom-x-small',
          ]"
        >
          Suggestions
        </h2>
        <!-- eslint-disable vue/no-v-html -->
        <ul
          :class="[
            't-list',
          ]"
          @click="handleSuggestion"
          @keyup="handleSuggestion"
          v-html="autocompleteLinks"
        />
        <!-- eslint-enable vue/no-v-html -->
      </div>
      <search-results
        v-show="shouldShowAutocomplete"
        v-if="productData"
        class="c-search-autocomplete__search-results"
        :search-data="productData"
        :search-query="query"
        slot-mode="multiple"
        @results="setAutocompleteVisible"
        @no-query="setAutocompleteNotVisible"
        @update:autocomplete="autocompleteData = $event"
      >
        <template v-slot:result="{ result }">
          <h2
            class="c-search-autocomplete__search-results-title"
            :class="[
              't-paragraph-large',
              'u-margin-bottom-x-small',
              'u-text-align-left',
              'l-flex',
            ]"
          >
            <span> Products </span>
            <nuxt-link
              v-if="query && query.value"
              class="c-search-autocomplete__search-results-view-all"
              :class="['t-link']"
              :to="{
                name: 'search',
                query: { q: query.value }
              }"
            >
              View All
            </nuxt-link>
          </h2>
          <product-grid
            class="c-search-autocomplete__product-grid"
            :products="result.slice(0, 4)"
            :columns="columns"
            :enable-stars="true"
            :compact="true"
          />
        </template>
        <template v-slot:no-results>
          <loader v-if="productData && productData.length === 0" />
          <search-no-results v-else />
        </template>
      </search-results>
      <loader v-else />
    </div>
  </transition>
</template>

<script>
import Loader from '@/components/loader/Loader'
import ProductGrid from '@/components/productGrid/ProductGrid'
import SearchNoResults from '~/components/nacelle/SearchNoResults'
import SearchResults from '~/components/nacelle/SearchResults'
import { mapMutations } from 'vuex'
export default {
  components: {
    ProductGrid,
    SearchResults,
    SearchNoResults,
    Loader,
  },
  props: {
    productData: {
      type: Array,
      default: null
    },
    query: {
      type: Object,
      default: () => ({}),
    },
    autocompleteVisible: {
      type: Boolean,
      required: true,
    },
    queryOrigin: {
      type: String,
      default: null,
    },
    columns: {
      type: Number,
      default: 4,
    },
  },
  data() {
    return {
      autocompleteData: null
    }
  },
  computed: {
    shouldShowAutocomplete() {
      return (
        this.autocompleteVisible &&
        this.queryOrigin &&
        this.queryOrigin === 'global'
      )
    },
    showSuggestions() {
      const autocompleteLinks = this.autocompleteLinks
      return autocompleteLinks && autocompleteLinks.length
    },
    autocompleteLinks() {
      const value = this.autocompleteData
      const openBoldRegex = /<b>/g
      const closeBoldRegex = /<\/b>/g
      let result = []
      for (let item of value || []) {
        const q = item.replace(openBoldRegex, '').replace(closeBoldRegex, '')
        const searchRoute = this.$router.resolve({
          name: 'search',
          query: { q }
        })
        result.push(
          `<li class="c-search-autocomplete__suggestion-list-item">
            <button
              data-href="${searchRoute.href}"
              class="c-search-autocomplete__suggestion-button t-link"
            >${this.$sanitize(item)}</button>
          </li>`
        )
      }
      return result.join('\n')
    }
  },
  methods: {
    ...mapMutations('search', [
      'setAutocompleteVisible',
      'setAutocompleteNotVisible',
      'hideSearch',
    ]),
    handleSuggestion(event) {
      const shouldPushRouter = (
        (event.type === 'click') ||
        (event.type === 'keyup' && event.code === 'Enter')
      )
      let buttonEl = null
      if (event && event.target) {
        switch (event.target.tagName) {
        case 'BUTTON':
          buttonEl = event.target
          break
        case 'A':
          buttonEl = event.target
          break
        case 'B':
          buttonEl = event.target.parentNode
          break
        }
      }
      if (buttonEl && shouldPushRouter) {
        const url = buttonEl.getAttribute('data-href')
        if (url) {
          setTimeout(() => this.hideSearch(), 100)
          this.$router.push(url)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.c-search-autocomplete {
  position: relative;
  max-width: 100rem;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  &.l-grid--2-col-second-long {
    grid-template-columns: 1fr 4fr;
  }

  &__suggestion-list {
    margin-top: rem(20px);
    ul { list-style: none; }
  }
  &__search-results {
    margin-top: rem(20px);
    &-title {
      padding-left: rem(10px);
      padding-right: rem(20px);
    }
    &-view-all {
      margin-left: auto;
    }
    .c-product-grid::v-deep {
      .l-grid--4-col-min-2 {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
  &__product-grid {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.fade-up-enter-active,
.fade-up-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}

/* .fade-leave-active below version 2.1.8 */
.fade-up-enter,
.fade-up-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
</style>

<style lang="scss">
.c-search-autocomplete {
  &__suggestion-list-item {
    list-style: none;
    padding-top: rem(10px);
    padding-bottom: rem(10px);
  }
  &__suggestion-button {
    border: none;
    background: none;
    cursor: pointer;
  }
}
</style>
