<template>
  <div class="l-flex l-flex__nowrap">
    <text-input
      :ref="`${position}-search-input`"
      v-model="localQuery"
      autocomplete="off"
      type="text"
      :placeholder="placeholder"
      class="c-search-input"
      :class="[
        'u-text-align-left'
      ]"
      icon="search"
      @keyup="trackSearch"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
    >
      <button
        v-if="localQuery && (localQuery.length > 0)"
        :class="clearButtonClass"
        title="Clear Search"
        @click.stop="clearQuery"
      >
        &#215;
      </button>
    </text-input>
    <Btn
      v-bind="searchButton"
      class="c-search-box__button"
      @click="trackSearch"
    />
  </div>
</template>

<script>
import TextInput from '@/components/inputs/TextInput'
import Btn from '@/components/button/Btn'
import { mapMutations, mapActions } from 'vuex'
import { RecentSearchesMixin } from '~/mixins/SearchMixin'
export default {
  components: {
    TextInput,
    Btn
  },
  mixins: [
    RecentSearchesMixin
  ],
  props: {
    placeholder: {
      type: String,
      default: 'Search products..'
    },
    position: {
      type: String,
      default: 'global'
    },
    query: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      localQuery: '',
      searchButton: {
        text: 'Search',
        buttonSize: 'small'
      }
    }
  },
  computed: {
    clearButtonClass() {
      let result = ['c-search-input__clear-button'];
      if (this.position == 'global')
        result.push('c-search-input__clear-button--global');
      return result
    }
  },
  watch: {
    query(query) {
      if (query === null) {
        this.localQuery = ''
      } else {
        this.localQuery = query.value
      }
    },
    localQuery(value) {
      // if we are in global search, submit search queries on a delay for
      // autocomplete
      if (this.position === 'global' && value) {
        this.setQuery({
          origin: this.position,
          value
        })
      } else if (value === '') {
        this.setQuery(null)
      }
    }
  },
  mounted() {
    if (this.query) {
      this.localQuery = (this.query) ? this.query.value : null
    } else if (location.search && !location.search.includes('utm')) {
      this.localQuery = this.$route.query.q
      this.setQuery({
        origin: this.position,
        value: this.localQuery
      })
    }
    this.$refs[`${this.position}-search-input`].focus()
  },
  methods: {
    ...mapMutations('search', ['setQuery', 'hideSearch']),
    ...mapActions('events', ['searchProducts']),
    async trackSearch(e) {
      if (e.key === 'Enter' || e.type === 'click') {
        await this.saveSearch(this.localQuery)
        await this.updateRecentSearches()
        setTimeout(() => this.hideSearch(), 200)
        this.$router.push({ name: 'search', query: { q: this.localQuery } })
      }
      if (e.key !== 'Enter') {
        if (process.client) {
          window.nostoClient.search({ query: this.localQuery })
        }
      }
      // Check that the key press is a letter or number and that
      // local query has a value before tracking an event
      if (/^[a-z0-9]$/i.test(e.key) && this.localQuery) {
        const trackSearchEvent = this.debounce(this.searchProducts, 500)
        trackSearchEvent({ query: this.localQuery })
      }
    },
    clearQuery() {
      this.setQuery(null)
    },
    debounce(fn, debounceTime) {
      return (...args) => {
        if (this.timeout !== null) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => fn(...args), debounceTime)
      }
    },
    focus() {
      const position = this.position
      this.$refs[`${position}-search-input`].focus()
    }
  }
}
</script>
<style lang="scss" scoped>
  .c-search-input{
    width: 100%;
    &__clear-button {
      position: absolute;
      right: rem(195px);
      top: rem(30px);
      background: none;
      border: none;
      line-height: rem(35px);
      font-size: rem(35px);
      width: rem(35px);
      height: rem(35px);
      cursor: pointer;
      z-index: 9;
      &--global {
        z-index: $zindex-global-header;
        top: rem(10px);
      }
    }
  }
  .c-search-box__button {
      height: 43px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

</style>
