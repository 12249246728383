<template>
  <span>
    <button
      class="c-search-button"
      type="button"
      @click="toggleSearch"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="21px"
        height="21px"
        viewBox="0 0 21 21"
        version="1.1"
      >
        <title>Search</title>
        <desc />
        <g
          id="Navigation"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            id="D-Nav-Shop"
            transform="translate(-1234.000000, -71.000000)"
            fill="#000000"
            fill-rule="nonzero"
          >
            <g
              id="$ICON/Search"
              transform="translate(1234.000000, 71.000000)"
            >
              <path
                id="Shape"
                d="M13.9690083,12.5167959 C16.4644136,9.26272627 16.005911,4.63204885 12.9207485,1.92982475 C9.83558603,-0.772399361 5.18181933,-0.619442998 2.28098911,2.27952404 C-0.61984112,5.17849107 -0.77289579,9.82926866 1.93106506,12.9124496 C4.63502591,15.9956305 9.26867951,16.4538385 12.5248405,13.9600361 L19.2732882,20.7041492 C19.6685009,21.0986169 20.3087463,21.0986169 20.7039591,20.7041492 C21.0986803,20.3091903 21.0986803,19.6693562 20.7039591,19.2743972 L13.9690083,12.5167959 Z M7.81442411,13.5419011 C4.64641911,13.5419011 2.07824364,10.9753751 2.07824364,7.80940493 C2.07824364,4.64343474 4.64641911,2.07690879 7.81442411,2.07690879 C10.9824291,2.07690879 13.5506046,4.64343474 13.5506046,7.80940493 C13.5431839,10.9722982 10.9793502,13.5344851 7.81442411,13.5419011 Z"
              />
            </g>
          </g>
        </g>
      </svg>
    </button>
    <search-overlay
      v-show="searchVisible"
    />
  </span>
</template>

<script>
import SearchOverlay from '~/components/screens/SearchOverlay'
import { mapMutations, mapState } from 'vuex'
export default {
  components: {
    SearchOverlay,
  },
  computed: {
    ...mapState('search', ['searchVisible']),
    ...mapState('nav', ['navVisible'])
  },
  watch: {
    searchVisible(visible) {
      if (visible) {
        document.body.style['overflow-y'] = 'hidden'
      } else {
        document.body.style['overflow-y'] = null
      }
    }
  },
  methods: {
    ...mapMutations('search', ['showSearch', 'hideSearch']),
    ...mapMutations('nav', ['closeAllDrawingMenu','changeMenuActive','changeShowingMenu']),
    toggleSearch() {
      if (this.searchVisible) {
        this.hideSearch()
      } else {
        this.showSearch()
      }
      if (this.navVisible) {
        this.$root.$emit('closeIndicatorElement');
        this.closeAllDrawingMenu()
        this.changeShowingMenu(false)
        this.changeMenuActive(false)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.c-search-button {
  position: relative;
  background: none;
  border: none;
  cursor: pointer;
  path {
    @include themify($themes) {
      fill: themed('primary');
    }
  }
}
</style>
