var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"fade-up"}},[_c('div',{staticClass:"c-search-autocomplete",class:{
      'l-grid': _vm.showSuggestions,
      'l-grid--align-start': _vm.showSuggestions,
      'l-grid--2-col-second-long': _vm.showSuggestions,
    }},[(_vm.showSuggestions)?_c('div',{staticClass:"c-search-autocomplete__suggestion-list"},[_c('h2',{class:[
          't-paragraph-large',
          'u-margin-bottom-x-small',
        ]},[_vm._v("\n        Suggestions\n      ")]),_vm._v(" "),_c('ul',{class:[
          't-list',
        ],domProps:{"innerHTML":_vm._s(_vm.autocompleteLinks)},on:{"click":_vm.handleSuggestion,"keyup":_vm.handleSuggestion}})]):_vm._e(),_vm._v(" "),(_vm.productData)?_c('search-results',{directives:[{name:"show",rawName:"v-show",value:(_vm.shouldShowAutocomplete),expression:"shouldShowAutocomplete"}],staticClass:"c-search-autocomplete__search-results",attrs:{"search-data":_vm.productData,"search-query":_vm.query,"slot-mode":"multiple"},on:{"results":_vm.setAutocompleteVisible,"no-query":_vm.setAutocompleteNotVisible,"update:autocomplete":function($event){_vm.autocompleteData = $event}},scopedSlots:_vm._u([{key:"result",fn:function({ result }){return [_c('h2',{staticClass:"c-search-autocomplete__search-results-title",class:[
            't-paragraph-large',
            'u-margin-bottom-x-small',
            'u-text-align-left',
            'l-flex',
          ]},[_c('span',[_vm._v(" Products ")]),_vm._v(" "),(_vm.query && _vm.query.value)?_c('nuxt-link',{staticClass:"c-search-autocomplete__search-results-view-all",class:['t-link'],attrs:{"to":{
              name: 'search',
              query: { q: _vm.query.value }
            }}},[_vm._v("\n            View All\n          ")]):_vm._e()],1),_vm._v(" "),_c('product-grid',{staticClass:"c-search-autocomplete__product-grid",attrs:{"products":result.slice(0, 4),"columns":_vm.columns,"enable-stars":true,"compact":true}})]}},{key:"no-results",fn:function(){return [(_vm.productData && _vm.productData.length === 0)?_c('loader'):_c('search-no-results')]},proxy:true}],null,false,2964479751)}):_c('loader')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }