<template>
  <div
    v-if="activeOfferComponent"
    class="c-offer"
    :class="[
      'l-flex',
      'l-flex--justify-start',
      'l-flex--column'
    ]"
  >
    <component
      :is="activeOfferComponent"
      class="c-offer__active"
      v-bind="activeOfferProps"
      @select="$emit('select', $event)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
    CartOfferAuto1015: () => import('./CartOfferAuto1015'),
    CartOfferBogo: () => import('./CartOfferBogo'),
    CartOfferGiftWithPurchase: () => import('./CartOfferGiftWithPurchase'),
  },
  computed: mapGetters('cart', [
    'activeOfferProps',
    'activeOfferComponent'
  ])
}
</script>

<style lang="scss" scoped>
.c-offer {
  width: 100%;
  &__active {
    max-height: rem(120px);
  }
}
</style>
