<template>
  <div v-if="filterComponent && usefulFilter">
    <accordion
      :title="filterLabel"
    >
      <component
        :is="filterComponent"
        v-bind="{ property, values, activeFilter }"
        @input="$emit('input', $event)"
      />
    </accordion>
  </div>
</template>

<script>
import Accordion from '@/components/accordion/Accordion'
import RefinementFilterScreenMixin from '@/mixins/refinementFilterScreen'
import {
  ColorFilter,
  WidthFilter,
  GenderFilter,
  EngravingFilter,
  SizeFilter,
  ProductTypeFilter,
} from '@/components/filter'

export default {
  components: {
    EngravingFilter,
    Accordion,
    ColorFilter,
    WidthFilter,
    GenderFilter,
    SizeFilter,
    ProductTypeFilter,
  },
  mixins: [
    RefinementFilterScreenMixin
  ],
  props: {
    property: {
      type: Object,
      required: true
    },
    values: {
      type: Array,
      required: true
    },
    activeFilters: {
      type: Array,
      default: () => [String]
    }
  },
  computed: {
    usefulFilter() {
      const values = this.values
      return !!(values && (values.length > 1))
    }
  }
}
</script>

<style lang="scss" scoped>
.c-filter-screen {
  width: 100%;
}
</style>
