<template comments>
  <div class="c-site-footer">
    <global-footer
      v-if="globalFooterVisible"
      v-bind="footer"
    >
      <p
        id="a11y-notice"
        class="t-paragraph-small"
      >
        <!--googleoff: index-->
        <!-- eslint-disable vue/no-v-html -->
        <client-only>
          <span v-html="mappedA11yNotice" />
        </client-only>
        <!-- eslint-enable vue/no-v-html -->
        <!--googleon: index-->
      </p>
    </global-footer>
    <div
      v-if="showEpilogue"
      class="c-site-footer__extra l-container t-paragraph-small"
    >
      <!-- eslint-disable vue/no-v-html -->
      <div
        v-if="mappedDisclaimer"
        v-html="mappedDisclaimer"
      />
      <div
        v-if="mappedSocialBody"
        v-html="mappedSocialBody"
      />
      <!-- eslint-enable vue/no-v-html -->
    </div>
    <!-- <script async src="https://assets.dailykarma.io/prod/init-v3.js"></script> -->
  </div>
</template>

<script>
import get from 'lodash/get'
import getContentItem from '~/mixins/getContentItem'
import GlobalFooter from '@/components/footer/GlobalFooter'
import nmerge from '~/utils/merge-requests'
import { mapState } from 'vuex'
import { setupBridge } from '~/modules/storyblok/bridge'
export default nmerge({
  components: {
    GlobalFooter
  },
  mixins: [
    getContentItem('footer', {
      handle: 'globalFooter',
      type: 'footer'
    }),
    getContentItem('a11yNotice', {
      handle: 'a11y-notice',
      type: 'content'
    })
  ],
  async created() {
    if (process.env.STORYBLOK_PREVIEW_MODE && false) {
      this.unsubscribe = await setupBridge(this, (story) => {
        console.log('[storyblok-bridge] footer', story)
        switch (story.handle) {
          case 'footer':
            // this.footer = story
            break
          case 'a11y-notice':
            // this.a11yNotice = story
            break
        }
      }, {
        handles: [
          'footer',
          'a11y-notice',
        ]
      })
    }
  },
  beforeDestory() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  },
  computed: {
    ...mapState('menu', ['globalFooterVisible']),
    mappedA11yNotice() {
      return get(this.a11yNotice, 'body')
    },
    mappedSocialBody() {
      const footer = this.footer
      let result = null
      if (footer && footer.socialMedia) {
        result = footer.socialMedia.body
      }
      return result
    },
    mappedDisclaimer() {
      const footer = this.footer
      return footer && footer.disclaimer
    },
    showEpilogue() {
      const socialBody = this.mappedSocialBody
      const disclaimer = this.mappedDisclaimer
      return !!(socialBody || disclaimer)
    }
  },
})
</script>

<style lang="scss" scoped>
.c-site-footer {
  width: 100%;
  &__extra {
    column-count: 3;
    column-gap: rem(30px);
    text-align: left;
    padding-top: rem(10px);
    padding-bottom: rem(10px);
    @include respond('tab-land') {
      column-count: 2;
    }
    @include respond('phone') {
      column-count: 1;
    }
    div ~ div {
      padding-top: rem(10px);
    }
  }
}
</style>

<style lang="scss">
.c-site-footer .c-site-footer__extra.t-paragraph-small .t-paragraph {
  font-size: 12px !important;
}
</style>
