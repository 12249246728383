<template>
  <div
    class="c-page"
    :class="pageClassNames"
  >
    <div
      class="nosto_page_type"
      style="display:none"
    >
      other
    </div>
    <!--
    /****
    /* The <page-content> component maps data from your CMS to Nacelle components.
    /* For information about creating pages, please refer to:
    /*
    /* https://docs.getnacelle.com/nuxt/pages.html#general-pages
    /****
    -->
    <page-content
      :page="page"
      :products="products"
    />
    <div id="ensolandingpage" />
  </div>
</template>

<script>
import get from 'lodash/get'
import getPage from '~/mixins/getPage'
import PageContent from '~/components/nacelle/PageContent'
import { mapGetters } from 'vuex'
export default {
  components: {
    PageContent
  },
  mixins: [
    getPage({ error: true })
  ],
  data() {
    return {
      products: [],
      test: "test"
    }
  },
  computed: {
    ...mapGetters('space', ['getMetatag']),
    pageTheme() {
      const page = this.page
      const themeName = get(page, 'fields.pageTheme')
      return (themeName) ? `theme-${themeName}` : undefined
    },
    pageClassNames() {
      let classNames = []
      const page = this.page
      const pageTheme = this.pageTheme
      if (page) {
        classNames.push(`p-${page.handle}`)
        if (pageTheme) {
          classNames.push(pageTheme)
        }
      }
      return classNames
    },
  },
  head() {
    if (this.page) {
      // console.log("pageHandle", this.page.title)
      const properties = {}
      const meta = []
      const title = this.getMetatag('title')

      if (this.page.title) {
        let fullTitle = this.page.title

        if (title) {
          fullTitle = `${fullTitle} | ${title.value}`
        }

        properties.title = fullTitle
        meta.push({
          hid: 'og:title',
          property: 'og:title',
          content: properties.title
        })
      }

      if (this.page.description) {
        meta.push({
          hid: 'description',
          name: 'description',
          content: this.page.description
        })
        meta.push({
          hid: 'og:description',
          property: 'og:description',
          content: this.page.description
        })
      }

      if (this.featuredImage) {
        meta.push({
          hid: 'og:image',
          property: 'og:image',
          content: this.featuredImage
        })
      }

      meta.push({
        hid: 'og:image',
        property: 'og:image',
        content: this.featuredImage
      })

      return {
        ...properties,
        meta
      }
    }
  },
}
</script>
<style>
.c-page {
  min-height: 50vh;
}
</style>
