<template>
  <shoppable-instagram
    v-bind="$props"
  />
</template>

<script>
import ShoppableInstagram from '@/components/instagram/ShoppableInstagram'
export default {
  components: {
    ShoppableInstagram,
  },
  props: ['title', 'cta', 'links']
}
</script>
