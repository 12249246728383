<!--
/****
/* Blog data is loaded from your CMS with the getBlog mixin.
/* For information about connecting your blog, please refer to:
/*
/* https://docs.getnacelle.com/nuxt/blogs.html#blogs
/****
-->
<template>
  <div class="c-blogs">
    <div
      class="nosto_page_type"
      style="display:none"
    >
      other
    </div>
    <page-content
      class="c-blogs__top-content u-margin-bottom-large"
      v-if="topContent"
      :content="topContent"
      :get-product-function="fetchProduct"
    />
    <div class="l-container">
      <div class="l-grid l-grid--3-col">
        <div
          v-for="(article, index) in filteredArticles"
          :key="`${article.id}-${index}`"
          class="u-margin-bottom-x-small c-blogs__card-wrapper l-grid__item l-grid__item--align-start u-100-height"
        >
          <nuxt-link v-utm-adder :to="`/blogs/${$route.params.blogHandle}/${article.handle}`" class="c-blogs__card">
            <BlogCard
              :title="articleTitle(article.title)"
              :description="blogDescription(article.excerpt)"
              :image="article.featuredMedia"
              :titleLogo="logoImage(article.logoImage)"
              textAlignment="left"
              class="l-flex l-flex--align-start u-100-height"
            />
          </nuxt-link>
        </div>
      </div>
      <observe-emitter @observe="fetchMore" />
    </div>
    <page-content
      v-if="bottomContent"
      :content="bottomContent"
      :get-product-function="fetchProduct"
    />
  </div>
</template>
<script>
import BlogCard from '@/components/card/BlogCard'
import get from 'lodash/get'
import getBlog from '~/mixins/getBlog'
import getProductInCard from '~/mixins/getProductInCard'
import isArray from 'lodash/isArray'
import ObserveEmitter from '~/components/nacelle/ObserveEmitter'
import PageContent from '~/components/nacelle/PageContent'
import viewEvent from '~/mixins/viewEvent'
import { mapGetters } from 'vuex'
export default {
  components: {
    ObserveEmitter,
    BlogCard,
    PageContent,
  },
  mixins: [
    getBlog(),
    viewEvent('blog'),
    getProductInCard,
  ],
  computed: {
    ...mapGetters('space', ['getMetatag']),
    topContent() {
      return get(this.blog, 'topContent')
    },
    bottomContent() {
      return get(this.blog, 'bottomContent')
    },
    /**
     * @link https://vuejs.org/v2/style-guide/#Simple-expressions-in-templates-strongly-recommended
     */
    showHero() {
      // v-if="blog.fields.entryTitle &&
      // blog.fields.pageContent &&\
      // blog.fields.pageContent.fields.desktopBackgroundImage"
      const blog = this.blog
      return !!(
        get(blog, 'fields.entryTitle') &&
        get(blog, 'fields.pageContent.fields.desktopBackgroundImage')
      )
    },
    heroProps() {
      // :title="blog.fields.entryTitle"
      // :desktop-background-image="blog.fields.pageContent.fields.desktopBackgroundImage.fields.file.url"
      const blog = this.blog
      return {
        title: get(blog, 'fields.entryTitle'),
        desktopBackgroundImage: get(
          blog,
          'fields.pageContent.fields.desktopBackgroundImage.fields.file.url'
        )
      }
    },
    blogProducts() {
      if (this.blog && this.blog.products && this.blog.products.length > 0) {
        return this.blog.products
      }

      return null
    },
    featuredArticle() {
      if (this.articles && this.articles.length > 0) {
        return this.articles[0]
      }

      return null
    },
    filteredArticles() {
      const articles = this.articles
      return isArray(articles) ? articles.slice(0, articles.length - 1) : []
    },
  },
  methods: {
    articleTitle(title) {
      if (title && title.length > 70) {
        return title.replace(" | Enso Rings", "").slice(0,70) + "..."
      } else if (title) {
        return title.replace(" | Enso Rings", "")
      }
    },
    blogDescription(description) {
      if (description && description.length > 120) {
        return description.slice(0,110) + "..."
      } else {
        return description
      }

    },
    logoImage(image) {
      if (image && image.fields && image.fields.file) {
        // return {src:image.fields.file.url, alt: image.fields.title}
        return {}
      } else return {}
    }
  },
  head() {
    const properties = {}
    const meta = []
    const title = this.getMetatag('title')

    let fullTitle = 'Blog'

    if (title) {
      fullTitle = `${fullTitle} | ${title.value}`
    }

    properties.title = fullTitle
    meta.push({
      hid: 'og:title',
      property: 'og:title',
      content: fullTitle
    })

    return {
      ...properties,
      meta
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.columns .article-preview) {
  padding: 1rem;
  border: 1px solid #f5f5f5;
}
.c-blogs {
  &__card {
    text-decoration: inherit;
    color: inherit;
  }

}
</style>
