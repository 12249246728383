<template>
  <div
    v-if="showRecos"
    class="c-product-recommendations"
  >
    <h2 class="t-heading-6 u-margin-bottom-small c-product-recommendations__title">
      FREQUENTLY BOUGHT TOGETHER
    </h2>
    <div class="l-grid l-grid--2-col">
      <div
        v-for="(recommendation, index) in recommendations"
        :key="index"
        class="l-grid__item l-grid__item--align-end"
      >
        <nosto-link
          class="c-product-recommendations__link"
          :to="`/products/${recommendation.handle}`"
          nosto-id="productpage-nosto-2-copy"
        >
          <ContentCard
            class="c-product-recommendations__content-card"
            :sub-title="recommendation.title"
            :description="recommendation.variants[0].price"
            :image="{src:recommendation.featuredMedia.thumbnailSrc, alt:recommendation.title }"
            :cta="{text:'Shop Now', buttonSize:['small','thin']}"
          />
        </nosto-link>
      </div>
    </div>
  </div>
</template>

<script>
import NostoLink from '~/components/integrations/nosto/NostoLink'
import ContentCard from "@/components/card/ContentCard";
import get from 'lodash/get'

export default {
  name: 'ProductRecommendations',
  components: {
    NostoLink,
    ContentCard,
  },
  props: {
    recommendations: {
      type: Array,
      default: null
    }
  },
  computed:{
    showRecos() {
      const recos = this.recommendations
      const drtvEnabled = get(this.$config, 'drtv.enabled')
      return !!((recos && recos.length > 0) && !drtvEnabled)
    },
    nostoId() {
      return process.env.NOSTO_ID;
    }
  },
  methods: {
    onClick(event) {
      this.$emit('click', event)
    }
  }
}
</script>

<style lang="scss" scoped>
.c-product-recommendations {
  &__title{
    margin-top: 30px;
  }
  &__link {
    text-decoration: none !important;
    @include themify($themes) {
      //background-color:themed('foreground', 'white-text');
      color: themed('foreground');
    }
  }
}
</style>
