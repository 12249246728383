var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-super-collection"},[(_vm.mappedPageSections[0] && _vm.mappedPageSections[0].contentType)?_c(_vm.mappedPageSections[0].contentType,_vm._b({tag:"component",attrs:{"id":_vm.mappedPageSections[0].handle}},'component',_vm.mappedPageSections[0].data,false)):_vm._e(),_vm._v(" "),_c('filter-bar',{on:{"click":_vm.toggleFilterModal}}),_vm._v(" "),(_vm.superCollections.length)?_c('div',[(_vm.productDisplayComponent == 'product-card-carousel')?_c('div',{staticClass:"p-super-collection__carouselSections"},[_vm._l((_vm.superCollections),function(collection,index){return [(index < 3)?_c('div',{key:`${index}-${collection}-lt-3`,staticClass:"p-super-collection__carousel-section"},[_c('collection-loader',{staticClass:"l-container",attrs:{"collection":collection},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [_c('carousel-screen',_vm._b({attrs:{"carousel-component":_vm.productDisplayComponent,"title":slotProps.collection.title,"description":slotProps.collection.description,"cta":{
                url: `/collections/${collection}`,
                text: 'View All',
                type: 'primary',
                buttonSize: 'small'
              }}},'carousel-screen',slotProps,false))]}}],null,true)})],1):_vm._e()]})],2):_vm._e()]):_c('div',[_c('loader')],1),_vm._v(" "),(_vm.loaderIndex <= 3)?_c('observe-emitter',{on:{"observe":_vm.loadMore}}):_vm._e(),_vm._v(" "),(_vm.mappedPageSections[1] && _vm.mappedPageSections[1].contentType)?_c(_vm.mappedPageSections[1].contentType,_vm._b({tag:"component",attrs:{"id":_vm.mappedPageSections[1].handle}},'component',_vm.mappedPageSections[1].data,false)):_vm._e(),_vm._v(" "),(_vm.productDisplayComponent == 'product-card-carousel')?_c('div',{staticClass:"p-super-collection__carouselSections"},[_vm._l((_vm.superCollections),function(collection,index){return [(index >= 3)?_c('div',{key:`${index}-${collection}-gte-3`,staticClass:"p-super-collection__carousel-section"},[_c('collection-loader',{staticClass:"l-container",attrs:{"collection":collection},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [_c('carousel-screen',_vm._b({attrs:{"carousel-component":_vm.productDisplayComponent,"title":slotProps.collection.title,"description":slotProps.collection.description,"cta":{
              url: `/collections/${collection}`,
              text: 'View All',
              type: 'primary',
              buttonSize: 'small'
            }}},'carousel-screen',slotProps,false))]}}],null,true)})],1):_vm._e()]})],2):_vm._e(),_vm._v(" "),_c('observe-emitter',{on:{"observe":_vm.loadMore}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }