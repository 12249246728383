<template>
  <div class="c-collection-loader">
    <slot
      v-if="dataLoaded"
      v-bind="collectionData"
    />
    <loader v-else />
  </div>
</template>
<script>
import { getCollectionFromProps } from '~/mixins/getCollectionFromProps'
import Loader from '@/components/loader/Loader'
export default {
  components: {
    Loader
  },
  mixins: [
    getCollectionFromProps('collection')
  ],
  props: {
    collection: {
      type: String,
      required: true
    }
  },
  computed: {
    dataLoaded() {
      const collectionData = this.collectionData
      return !!(
        collectionData &&
        collectionData.collection &&
        collectionData.products
      )
    }
  }
}
</script>
