<template>
  <p
    class="c-product-title t-heading-9"
  >
    {{ title }}
  </p>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Product Title'
    }
  }
}
</script>

<style lang="scss" scoped>
.c-product-title {
  max-width: rem(260px);

  @include respond('phone') {
    max-width: 43vw;
  }
}
</style>
