var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.autoAddIncomplete)?_c('div',{staticClass:"c-cart-offer-gwp"},[(_vm.thresholdReached)?_c('div',{staticClass:"c-cart-offer-gwp__cta",class:[
      'l-flex',
      'l-flex--justify-start'
    ]},[(_vm.productImage)?_c('img',{staticClass:"c-cart-offer-gwp__product-image",attrs:{"src":_vm.productImage.thumbnailSrc,"alt":_vm.productImage.alt}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"c-cart-offer-gwp__cta-details",class:[
        'l-flex',
        'l-flex--align-start',
        'l-flex--column',
        'l-flex__nowrap'
      ]},[(_vm.ctaForMinicartType)?[_c('div',{staticClass:"c-cart-offer-gwp__cta-banner t-heading-13 t-heading-13--accent"},[_vm._v("\n          "+_vm._s(_vm.cta)+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"c-cart-offer-gwp__product-name t-heading-9 u-margin-bottom-xx-small"},[_c('span',[_vm._v(_vm._s(_vm.productName))])]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('button',{class:[
            'c-cart-offer-gwp__add-to-cart-button t-link u-text-underline'
          ],on:{"click":_vm.showMiniCart}},[(_vm.giftAdded)?[_vm._v("\n            Change Color & Size\n          ")]:[_vm._v("\n            Select Color & Size\n          ")]],2)]:[_c('div',{staticClass:"c-cart-offer-gwp__cta-banner t-heading-13 t-heading-13--accent"},[_vm._v("\n          "+_vm._s(_vm.cta)+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"c-cart-offer-gwp__product-name t-heading-9 u-margin-bottom-xx-small"},[_c('span',[_vm._v(_vm._s(_vm.productName))])]),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('button',{class:[
            'c-cart-offer-gwp__add-to-cart-button t-link u-text-underline'
          ],on:{"click":_vm.quickAddToCart}},[_vm._v("\n          Add to Cart\n        ")])]],2)]):_c('div',{staticClass:"c-cart-offer-gwp__offer",class:[
      'l-flex',
      'l-flex--justify-start',
      'l-flex__nowrap'
    ]},[_c('cart-offer-cta',{staticClass:"c-cart-offer-gwp__copy",attrs:{"variant":"gwp","message-template":_vm.activeMessage,"remainder":_vm._f("currency")(_vm.remainder),"collection-name":_vm.productName}}),_vm._v(" "),(_vm.productImage)?_c('img',{staticClass:"c-cart-offer-gwp__product-image",attrs:{"src":_vm.productImage.thumbnailSrc,"alt":_vm.productImage.alt}}):_vm._e()],1)]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"t-heading-13 t-heading-13--white u-margin-bottom-xx-small"},[_c('span',{staticClass:"c-cart-offer-gwp__free-gift"},[_vm._v("\n            Free Gift\n          ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"t-heading-13 t-heading-13--white u-margin-bottom-xx-small"},[_c('span',{staticClass:"c-cart-offer-gwp__free-gift"},[_vm._v("\n            Free Gift\n          ")])])
}]

export { render, staticRenderFns }