<template>
  <div class="c-engraving-upsell-list">
    <div class="u-text-align-center u-margin-bottom-large">
      <h2 class="t-heading-4 u-margin-bottom-small">
        Select one or more engravable ring.
      </h2>
      <p>Select each of the rings you'd like to engrave. Discounts are applied to each ring.</p>
    </div>

    <div class="c-engraving-upsell-list__select-product u-margin-bottom-large">
      <SelectProduct
        :line-items="lineItems"
        @checkboxChanged="changeEngraving"
      />
    </div>

    <div
      v-if="noEngravingsError"
      class="c-engraving-upsell-list__error u-margin-bottom-medium"
    >
      * You must select a ring to engrave.
    </div>
    <Btn
      :button-size="['thin','large']"
      class="u-margin-bottom-medium"
      @click="openEngraving"
    >
      Save & Continue
    </Btn>
    <div class="c-engraving-upsell-list__checkout-button">
      <CartFlyoutCheckoutButton
        checkout-text="Cancel and go to checkout"
        button-type="link"
      />
    </div>
  </div>
</template>
<script>
import SelectProduct from '@/components/selectProduct/SelectProduct'
import CartFlyoutCheckoutButton from '~/components/cart/CartFlyoutCheckoutButton'
import Btn from '@/components/button/Btn'
import isEqual from 'lodash/isEqual'
import { mapActions } from 'vuex'
export default {
  name: 'EngravingUpsellList',
  components: {
    SelectProduct,
    CartFlyoutCheckoutButton,
    Btn
  },
  props: {
    lineItems: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      engravingArray: [],
      noEngravingsError: false,
    }
  },
  methods: {
    changeEngraving(item) {
      let notInArray = true;
      for (let i in this.engravingArray) {
        if (isEqual(this.engravingArray[i], item)) {
          notInArray = false;
          let tempArray = this.engravingArray
          this.engravingArray = tempArray.slice(0,i);
        }
      }
      if (notInArray) {
        this.engravingArray.push(item);
      }
    },
    async openEngraving() {
      //check and make sure there is at least one engraving
      if (this.engravingArray && this.engravingArray[0]) {
        this.noEngravingsError = false;
        await this.$nextTick()
        await this.setRingsEngraving(this.engravingArray)
        this.$emit("closeCart", this.engravingArray)
      } else {
        this.noEngravingsError = true;
      }

    },
    ...mapActions('engraving', {
      setEngravingContext: 'setContext',
      setRingsEngraving: 'setRings',
    }),
  }
}
</script>
<style lang="scss" scoped>
.c-engraving-upsell-list {
  &__select-product {
    padding:0 rem(20px);
    @include respond(phone) {
      padding: 0;
    }
  }
  &__checkout-button {
    text-align:center;

  }
  &__error {
    @include themify($themes) {
      color: themed('foreground', 'error');
    }
  }
}
</style>
