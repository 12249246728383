<template>
  <p
    v-if="variant === 'gwp'"
    class="c-cart-offer-copy"
    :class="[
      't-paragraph-small',
      'u-center-text'
    ]"
  >
    {{ gwpMessage }}
  </p>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: ''
    },
    messageTemplate: {
      type: String,
      default: ''
    },
    remainder: {
      type: String,
      default: ''
    },
    collectionName: {
      type: String,
      default: ''
    }
  },
  computed: {
    gwpMessage() {
      const template = this.messageTemplate
      const remainder = this.remainder
      const collectionName = this.collectionName
      return template
        .replace('COLLECTION_NAME', collectionName)
        .replace('REMAINDER', remainder)
    }
  }
}
</script>

<style lang="scss" scoped>
.c-cart-offer-copy {
  padding: rem(20px);
  margin-bottom: 0;

  @include themify($themes) {
    background: themed('background', 'xx-light');
  }
}
</style>
