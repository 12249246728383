<template>
  <div
    v-if="showRecos"
    class="c-rebuy-similar-products-carousel u-margin-bottom-medium"
  >
    <h3 class="t-heading-3 u-text-align-center u-margin-bottom-small">{{ title }}</h3>
    <product-carousel
      :products="products"
    />
  </div>
</template>

<script>
import get from 'lodash/get'
import ProductCarousel from '@/components/carousel/ProductCarousel'
import uniqBy from 'lodash/uniqBy'
export default {
  name: 'RebuySimilarProductsCarousel',
  props: {
    title: {
      type: String,
      default: 'Similar Products'
    },
    product: {
      type: Object,
      required: true
    }
  },
  components: {
    ProductCarousel
  },
  data() {
    return {
      recos: null
    }
  },
  computed: {
    showRecos() {
      return !!(this.recos && this.product && this.recos.length)
    },
    products() {
      const recos = this.recos
      return uniqBy(recos, 'handle')
    }
  },
  async fetch() {
    if (this.product) {
      this.recos = await this.$api.getRecosByProduct({
        product: this.product
      })
    }
  },
  watch: {
    product(value, oldValue) {
      if (value !== oldValue) {
        this.$fetch()
      }
    }
  }
}
</script>
