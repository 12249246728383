<template>
  <div class="c-search-results">
    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="searchResults && searchResults.length === 0"
        class="no-results"
      >
        <slot name="no-results" />
      </div>
      <div
        v-else
        class="search-results"
      >
        <slot
          name="result"
          :result="searchResults"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { SearchResultsMixin } from '~/mixins/SearchMixin'
export default {
  mixins: [
    SearchResultsMixin
  ]
}
</script>

<style lang="scss" scoped>
.c-search-results{
  &__results{
    min-height: 400px;
    height: 80%;
  }
  &__no-results{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
