<template>
  <div
    class="c-search-box"
    :class="`${position}-searchbox`"
  >
    <div :class="headerClassNames">
      <search-input
        ref="j-search-input"
        class="c-search-box__input"
        :query="query"
        :placeholder="placeholder"
        :position="position"
      />
    </div>
    <search-box-autocomplete
      v-if="showAutoComplete"
      :query="query"
      :product-data="productData"
      :autocomplete-visible="autocompleteVisible"
      :query-origin="queryOrigin"
      :columns="columns"
      @clear-query="clearQuery"
    />
  </div>
</template>

<script>
import SearchBoxAutocomplete from '~/components/nacelle/SearchBoxAutocomplete'
import SearchInput from '~/components/nacelle/SearchInput'
import sleep from '~/utils/sleep'
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
export default {
  components: {
    SearchBoxAutocomplete,
    SearchInput,
  },
  props: {
    position: {
      type: String,
      default: 'global'
    },
    placeholder: {
      type: String,
      default: 'Search by Name, Color, Keyword...'
    },
    enableAutocomplete: {
      type: Boolean,
      default: false
    },
    columns: {
      type: Number,
      default: 4,
    }
  },
  data() {
    return {
      loadingData: false,
    }
  },
  computed: {
    ...mapState('search', [
      'query',
      'autocompleteVisible',
      'searchVisible',
    ]),
    ...mapGetters('search', [
      'productData',
      'queryOrigin',
    ]),
    showAutoComplete() {
      const enabled = this.enableAutocomplete
      const query = this.query
      //const position = this.position
      //const routeName = this.$route.name
      const hasProductData = this.productData && this.productData.length
      const loadingData = this.loadingData
      return (
        enabled &&
        query &&
        query.value &&
        // position === 'global' &&
        // routeName !== 'search' &&
        hasProductData &&
        !loadingData
      )
    },
    showCloseButton() {
      const position = this.position
      return position === 'global'
    },
    headerClassNames() {
      const position = this.position
      const classNamePrefix = 'c-search-box'
      let result = [ `${classNamePrefix}__input-wrapper` ]
      if (position === 'in-page') {
        result.push(`${classNamePrefix}__header`, 'l-container')
      }
      return result
    },
  },
  watch: {
    query(query) {
      if (query && query.value) {
        this.deferGetProductData()
      }
    },
    searchVisible(visible) {
      if (visible) {
        this.focus()
      }
    }
  },
  methods: {
    ...mapActions('search', ['getProductData']),
    ...mapMutations('search', ['setQuery', 'hideSearch']),
    clearQuery() {
      this.setQuery(null)
    },
    focus() {
      this.$refs['j-search-input'].focus()
    },
    /**
     * data loading is a PERFORMANCE SENSITIVE operation. be VERY CAREFUL when
     * modifying the load order. at the time of this writing the search.json
     * weighs roughly 6M and can easily hang the browser if it is requested at
     * the wrong time.
     */
    async deferGetProductData() {
      if (this.loadingData) {
        return
      }
      try {
        this.loadingData = true
        await this.$nextTick()
        await sleep(200)
        await this.getProductData()
        await this.$nextTick()
        await sleep(200)
        this.loadingData = false
      } catch (err) {
        console.error(
          '[search-box] unable to dispatch search/getProductData',
          err
        )
        this.loadingData = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-search-box {
  position: relative;

  &__header {
    padding-top: rem(20px);
    padding-bottom: rem(20px);
  }

  &__input-wrapper {
    position: relative;
    max-width: rem(1000px);
    margin: 0 auto;
    @include respond(phone) {
      width: calc(100% - 2rem);
    }
  }

  &__input {
  }

  &__body {
    max-width: rem(1000px);
    margin: 0 auto;
    grid-template-columns: 1fr 3fr;

    @include respond(tab-land) {
      grid-template-columns: 1fr;
    }
    @include respond(phone) {
      grid-template-columns: 1fr;
    }
  }

  &__link {
    display: block;
    @include themify($themes){
      color: themed("foreground");
    }

    &:hover {
      text-decoration: none;
    }
  }
  &__loader-wrapper {
    &--visible {
      padding: rem(20px);
      @include themify($themes){
        border: $input-border themed("foreground", "input-border");
        border-top: none;
      }
    }
  }
}
</style>
