<template>
  <div class="c-stockist-store-locator">
    <client-only>
      <div :data-stockist-widget-tag="accountId">
        <span>Loading store locator from</span>
        <a v-utm-adder href="https://stockist.co">Stockist store locator</a>
        <span>...</span>
      </div>
    </client-only>
  </div>
</template>

<script>
export default {
  data() {
    return {
      accountId: null
    }
  },
  mounted() {
    this.accountId = process.env.STOCKIST_ACCOUNT_ID
  },
  head() {
    const accountId = this.accountId
    const embedData = {
      hid: 'stockist-widget',
      src: '//stockist.co/embed/v1/widget.min.js',
      async: true,
      defer: true
    }
    let meta = {
      title: 'Store Locator',
      script: []
    }
    if (accountId) {
      meta.script.push(embedData)
    }
    return meta
  }
}
</script>
