<template>
  <accordion
    v-if="showMoreInfo"
    :title="label"
    :show-title="showTitle"
    title-typography="t-heading-9"
    class="c-cart-item-info"
  >
    <template v-slot:secondary-label>
      <div class="c-cart-item-info__price" v-if="isEngraved">
        <product-price
          v-if="engravingDiscount"
          :price="(engravingFeeLabel.price - engravingDiscount) | currency"
          :compare-at-price="engravingFeeLabel.compareAtPrice | currency"
        />
        <product-price
          v-if="engravingFeeLabel"
          :price="engravingFeeLabel.price | currency"
          :compare-at-price="engravingFeeLabel.compareAtPrice | currency"
        />
      </div>
    </template>

    <div v-if="isEngraved" class="c-cart-item-info__engraving">
      <product-variant-title
        v-if="!miniCartVariant"
        :item="item"
        class="c-cart-item__variant-title"
      />
      <product-variant-title-new
        v-else
        :item="item"
        class="c-cart-item__variant-title"
      />
    </div>

    <div v-else-if="isBundle" class="c-cart-item-info__bundle">
      <div
        v-for="(item, index) of bundleItems"
        class="c-cart-item-info__bundle__item"
        :class="['l-flex', 'l-flex--justify-start']"
        :key="`${index}-${item.id}`"
      >
        <product-image
          class="c-cart-item-info__bundle__item__img"
          :src="item | productImgUrl"
          :alt="item | productImgAlt"
          size="60x60"
        />
        <div>
          <h4>{{ item | productTitle }}</h4>
          <p>{{ item | productSize }}</p>
        </div>
      </div>
    </div>
  </accordion>
  <div v-else-if="isEngravable" class="c-cart-item-info__engraving">
    <product-variant-title
      v-if="!miniCartVariant"
      :item="item"
      class="c-cart-item__variant-title"
    />
    <product-variant-title-new
      v-else
      :item="item"
      class="c-cart-item__variant-title"
    />
  </div>
</template>

<script>
import Accordion from '@/components/accordion/Accordion'
import ProductImage from '@/components/productImage/ProductImage'
import ProductVariantTitle from '~/components/nacelle/ProductVariantTitle'
import ProductVariantTitleNew from '~/components/nacelle/ProductVariantTitleNew'
import ProductPrice from '@/components/productPrice/ProductPrice'
import get from 'lodash/get'
import { mapState } from 'vuex'
export default {
  filters: {
    productTitle(product) {
      return get(product, 'title')
    },
    productImgUrl(product) {
      return get(product, 'featuredImage.src')
    },
    productImgAlt(product) {
      return get(product, 'featuredImage.alt')
    },
    productSize(product) {
      const selectedOptions = get(product, 'variant.selectedOptions', [])
      let result = ''
      for (let opt of selectedOptions) {
        if (opt.name === 'Size') {
          result = `${opt.name} ${opt.value}`
          break
        }
      }
      return result
    },
    variantTitle() {
      return get(this.item, 'variant.title')
    }
  },
  components: {
    Accordion,
    ProductImage,
    ProductVariantTitle,
    ProductVariantTitleNew,
    ProductPrice
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    fees: {
      type: Array,
      default: () => []
    },
    engravingFee: {
      type: Object,
      default: () => ({})
    },
    showTitle: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    ...mapState('vwo',['miniCart']),
    label() {
      const isEngraved = this.isEngraved
      const isBundle = this.isBundle
      let result = ''
      if (isEngraved) {
        result = 'Engraving'
      } else if (isBundle) {
        result = 'Bundle Details'
      }
      return result
    },
    showMoreInfo() {
      const isEngraved = this.isEngraved
      const isBundle = this.isBundle
      const bundleItems = this.bundleItems
      return isEngraved || isBundle && bundleItems.length > 0
    },
    isEngraved() {
      const item = this.item
      const facets = item.facets || []
      const hasEngraving = item.customAttributes.filter(e => e.key == 'text').length > 0 || false
      const isEngraved = hasEngraving
      return !!isEngraved
    },
    isEngravable() {
      const item = this.item
      const isEngraved = this.isEngraved
      const engravable = get(item, 'metafieldsMap.engraving.value')
      return !!engravable && !isEngraved
    },
    engravingDiscount() {
      return Math.ceil(
        this.engravingFee.price *
          Number(
            this.item.customAttributes.find(
              obj => obj.key == '_engravingDiscount'
            ).value
          )
      )
    },
    isBundle() {
      const item = this.item
      const isBundle = item.productType === 'Bundle'
      return isBundle
    },
    bundleItems() {
      const isBundle = this.isBundle
      const item = this.item
      let result = []
      if (isBundle && item.customAttributes) {
        for (let attr of item.customAttributes) {
          if (attr.key === '_local_bundle_selections') {
            result = attr.value
            break
          }
        }
      }
      return result
    },
    engravingFeeLabel() {
      let result = this.fees.filter(fee => {
        return fee.handle === 'engraving-fee'
      })
      if (result && result[0]) {
        return result[0].variants[0]
      }
      return null
    },
    engravingDiscount() {
      const item = this.item || ''
      const customAttributes = item.customAttributes
      let result = false
      if(customAttributes &&
        customAttributes.find(obj => obj.key == '_engravingDiscount')
      ) {
        result = true
      }
      return result
    },
    miniCartVariant() {
      const miniCart = this.miniCart
      let result = false
      if(miniCart && miniCart.variant1) {
        result = true
      }
      return result
    }
  }
}
</script>

<style lang="scss" scoped>
.c-cart-item-info {
  width: 100%;
  padding: 0;
  &__bundle {
    &__item {
      width: 100%;
    }
    &__item__img {
    }
  }
  &__price {
    margin-right: rem(10px);
  }
}
</style>
