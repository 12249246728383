<!--
/****
/* The "Starship Furniture" store will load as default
/* content in the absence of page data supplied by a CMS
/****
-->
<template>
  <div class="page"
       :class="pageClass"
  >
    <div
      class="nosto_page_type"
      style="display: none"
    >
      front
    </div>
    <page-content :page="page" />
  </div>
</template>

<script>
import nmerge from '~/utils/merge-requests'
import getPage from '~/mixins/getPage'
import ContentHeroBanner from '~/components/nacelle/ContentHeroBanner'
import ContentSideBySide from '~/components/nacelle/ContentSideBySide'
import PageContent from '~/components/nacelle/PageContent'
import DrtvMode from '~/mixins/DrtvMode'
import { mapGetters } from 'vuex'

export default nmerge({
  components: {
    ContentHeroBanner,
    ContentSideBySide,
    PageContent
  },
  computed: {
    pageClass() {
      let results = [];
      if (this.page) {
        if(this.page.handle) {
          results.push(`p-${this.page.handle}`)
        }
        if (this.page.fields) {
          if (this.page.fields.pageTheme) {
            results.push(`theme-${this.page.fields.pageTheme}`)
          }
        }
      }
      return results
    },
    name() {
      return this.$store.state.space.name
    },
    ...mapGetters('space', ['getMetatag'])
  },
  mixins: [
    DrtvMode,
    getPage({ pageHandle: 'homepage', mapping: 'storyblok' }),
  ],
  head() {
    if (this.page) {
      //console.log('pageHandle', this.page.title)
      const properties = {}
      const meta = []
      const title = this.getMetatag('title')

      if (this.page.title) {
        let fullTitle = this.page.title

        if (title) {
          fullTitle = `${fullTitle} | ${title.value}`
        }

        properties.title = fullTitle
        meta.push({
          hid: 'og:title',
          property: 'og:title',
          content: properties.title
        })
      }

      if (this.page.description) {
        meta.push({
          hid: 'description',
          name: 'description',
          content: this.page.description
        })
        meta.push({
          hid: 'og:description',
          property: 'og:description',
          content: this.page.description
        })
      }

      if (this.featuredImage) {
        meta.push({
          hid: 'og:image',
          property: 'og:image',
          content: this.featuredImage
        })
      }

      meta.push({
        hid: 'og:image',
        property: 'og:image',
        content: this.featuredImage
      })
      return {
        ...properties,
        meta
      }
    }
  }
})
</script>
