<template>
  <div class="p-super-collection">
    <!--    first section is typically the hero-->
    <component
      :is="mappedPageSections[0].contentType"
      v-if="mappedPageSections[0] && mappedPageSections[0].contentType"
      :id="mappedPageSections[0].handle"
      v-bind="mappedPageSections[0].data"
    />
    <filter-bar
      @click="toggleFilterModal"
    />
    <div v-if="superCollections.length">
      <div
        v-if="productDisplayComponent == 'product-card-carousel'"
        class="p-super-collection__carouselSections"
      >
        <template
          v-for="(collection, index) in superCollections"
        >
          <div
            v-if="index < 3"
            :key="`${index}-${collection}-lt-3`"
            class="p-super-collection__carousel-section"
          >
            <collection-loader
              v-slot:default="slotProps"
              class="l-container"
              :collection="collection"
            >
              <carousel-screen
                :carousel-component="productDisplayComponent"
                :title="slotProps.collection.title"
                :description="slotProps.collection.description"
                v-bind="slotProps"
                :cta="{
                  url: `/collections/${collection}`,
                  text: 'View All',
                  type: 'primary',
                  buttonSize: 'small'
                }"
              />
            </collection-loader>
          </div>
        </template>
      </div>
    </div>
    <div v-else>
      <loader />
    </div>
    <observe-emitter
      v-if="loaderIndex <= 3"
      @observe="loadMore"
    />
    <component
      :is="mappedPageSections[1].contentType"
      v-if="mappedPageSections[1] && mappedPageSections[1].contentType"
      :id="mappedPageSections[1].handle"
      v-bind="mappedPageSections[1].data"
    />
    <div
      v-if="productDisplayComponent == 'product-card-carousel'"
      class="p-super-collection__carouselSections"
    >
      <template
        v-for="(collection, index) in superCollections"
      >
        <div
          v-if="index >= 3"
          :key="`${index}-${collection}-gte-3`"
          class="p-super-collection__carousel-section"
        >
          <collection-loader
            v-slot:default="slotProps"
            class="l-container"
            :collection="collection"
          >
            <carousel-screen
              :carousel-component="productDisplayComponent"
              :title="slotProps.collection.title"
              :description="slotProps.collection.description"
              v-bind="slotProps"
              :cta="{
                url: `/collections/${collection}`,
                text: 'View All',
                type: 'primary',
                buttonSize: 'small'
              }"
            />
          </collection-loader>
        </div>
      </template>
    </div>
    <observe-emitter @observe="loadMore" />
  </div>
</template>

<script>
//Components
import InputForm from '~/components/InputForm'
import FilterBar from '~/components/FilterBar'
import FilterModalScreen from '~/components/screens/FilterModalScreen'
import HeroBanner from '~/components/screens/HeroScreen'
import Row from '@/components/layout/Row'
import CollectionsContainer from '@/components/collection/CollectionsContainer'
import CarouselScreen from '~/components/screens/CarouselScreen'
import CollectionLoader from '~/components/screens/CollectionLoader'
//Nacelle Components
import ObserveEmitter from '~/components/nacelle/ObserveEmitter'
import Btn from "@/components/button/Btn";
import Loader from "@/components/loader/Loader";

export default {
  components: {
    FilterBar,
    HeroBanner,
    Row,
    CollectionLoader,
    FilterModalScreen,
    ObserveEmitter,
    CollectionsContainer,
    InputForm,
    CarouselScreen,
    Btn,
    Loader
  },
  props: {
    page: {
      type: Object,
      default: () => {}
    },
    organizeProductsBy: {
      type: String,
      default: 'product'
    },
    productDisplayComponent: {
      type: String,
      default: 'product-grid'
    },
    mappedPageSections: {
      type: Array,
      default: () => []
    },
    products: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      modalOpen: false,
      filtered: false,
      loaderIndex: 0
    }
  },
  computed: {
    /**
     * based on the scroll position (tracked by observe-emitter), return a
     * slice of the complete super collection list. data loading is deferred
     * to the child components.
     * @return {string[]}
     */
    superCollections() {
      const handles = this.page && this.page.fields
        ? this.page.fields.superCollectionSubCollections
        : []
      return handles.slice(0, this.loaderIndex + 1)
    },
  },
  methods: {
    loadMore() {
      this.loaderIndex += 1
    },
    toggleFilterModal() {
      this.$emit("toggleFilterModal")
    },
  }
}
</script>

<style lang="scss" scoped>
.p-super-collection {
}
</style>
