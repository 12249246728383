<template>
  <div
    ref="img-card"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true
    }"
    class="c-product-image"
  >
    <picture
      class="c-product-image__picture"
    >
      <source
        v-if="visibility && cloudinaryCanAutoFormat && validImage"
        class="c-product-image__source"
        :srcset="optimizeSource({ url: source, format: 'auto' })"
        @loadend="onLoaded"
      >
      <source
        v-if="visibility && reformat && validImage"
        class="c-product-image__source"
        :srcset="optimizeSource({ url: source, format: 'webp' })"
        type="image/webp"
      >
      <source
        v-if="visibility && reformat && validImage"
        class="c-product-image__source"
        :srcset="optimizeSource({ url: source, format: 'jpg' })"
        type="image/jpeg"
      >
      <img
        v-if="visibility"
        ref="product-image"
        class="c-product-image__img"
        :class="{ 'c-product-image__img--loaded': loaded, 'c-product-image__img--border':!noBorder}"
        :src="source"
        :alt="alt"
        :width="width"
        :height="height"
        :style="cssVars"
        @loadend="onLoaded"
        @error="fallback"
      >
      <img
        v-else-if="!visibility"
        class="c-product-image__img"
        :src="blankImage"
      >
    </picture>
  </div>
</template>

<script>
import imageOptimize from '~/mixins/imageOptimize'
import imageVisibility from '~/mixins/imageVisibility'

export default {
  mixins: [
    imageOptimize,
    imageVisibility
  ],
  props: {
    source: {
      type: String,
      default:
        'https://nacelle-assets.s3-us-west-2.amazonaws.com/default-product-image.svg'
    },
    alt: {
      type: String,
      default: 'Featured Product Image'
    },
    fadeIn: {
      type: Number,
      default: 0.3
    },
    containerRef: {
      type: String,
      default: 'img-card'
    },
    noBorder: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    cssVars() {
      return {
        '--fade-in-time': `${this.fadeIn}s`
      }
    },
    fallbackImage() {
      return this.source
    }
  }
}
</script>

<style lang="scss" scoped>
.c-product-image,
.c-product-image__img {
  width: 100%;
  &--loaded {
    animation: fadein var(--fade-in-time);
  }
}

.c-product-image {
  &__img {
    padding: rem(5px);

    &--border{
      @include themify($themes) {
        border: $border themed('foreground', 'section-border')
      }
    }
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
