<template>
  <div>
    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="searchResults && searchResults.length == 0"
        key="no-results"
        class="no-results"
      >
        <loader v-if="searchLoading" />
        <slot
          v-else
          name="no-results"
        />
      </div>
      <div
        v-else
        key="results"
        class="search-results"
      >
        <slot
          name="result"
          :result="searchResultsSlice"
        />
        <div ref="load-more" />
      </div>
    </transition>
  </div>
</template>

<script>
import Loader from '@/components/loader/Loader'
import { mapState, mapMutations } from 'vuex'
import { SearchResultsMixin } from '~/mixins/SearchMixin'
export default {
  components: { Loader },
  mixins: [
    SearchResultsMixin
  ],
  data() {
    return {
      pageHeight: null
    }
  },
  computed: {
    ...mapState('search', ['resultsToDisplay', 'filteredData']),
    itemSinglularPlural() {
      return (this.searchResults && this.searchResults.length === 1)
        ? 'item'
        : 'items'
    },
    searchResultsSlice() {
      const searchResults = this.searchResults
      const limit = this.resultsToDisplay
      let result = []
      if (searchResults && searchResults.length) {
        result = searchResults.slice(0, limit || searchResults.length)
      }
      return result
    }
  },
  watch: {
    filteredData(newData, oldData) {
      if (JSON.stringify(newData) !== JSON.stringify(oldData)) {
        this.resetResults()
      }
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.$refs['load-more']) {
        const options = {
          root: null,
          rootMargin: '250px',
          threshold: 1
        }
        const observer = new IntersectionObserver(this.showMoreResults, options)
        const observee = this.$refs['load-more']
        observer.observe(observee)
        this.isObserverInitialized = true
      }
    }, 5000)
  },
  methods: {
    ...mapMutations('search', ['showMoreResults', 'resetResults'])
  }
}
</script>

<style lang="scss" scoped>
.search-results,
.no-results {
  min-height: 400px;
}

.no-results {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
