<template>
  <div
    class="p-subscribe-to-sms"
    :class="[
      'l-flex',
      'l-flex--column'
    ]"
  >
    <klaviyo-embed-form
      class="c-capture-form"
      :embed-code="embedCode"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      embedCode: `<div class="klaviyo-form-TbyJBY"></div>`
    }
  }
}
</script>
<style lang="scss" scoped>
.p-subscribe-to-sms {
  .c-capture-form {
    padding-left: rem(10px);
    padding-right: rem(10px);
    height: 100%;
    max-width: 33%;
    margin-left: auto;
    margin-right: auto;
    @include respond(tab-land) {
      max-width: 66%;
    }
    @include respond(tab-port) {
      max-width: 66%;
    }
    @include respond(phone) {
      max-width: 100%;
    }
  }
}
</style>
