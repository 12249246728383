<template>
  <div class="c-carousel-screen">
    <div class="c-carousel-screen__copy">
      <h2
        v-if="title"
        class="t-heading-2"
      >
        {{ title }}
      </h2>
      <p
        v-if="description"
        class="t-paragraph t-paragraph-large--base"
      >
        {{ description }}
      </p>
    </div>
    <div
      v-if="calWidths.length > 1"
      class="c-carousel-screen__widths l-flex l-flex--justify-start u-margin-bottom-medium"
    >
      <template
        v-for="(width, index) in calWidths"
      >
        <div
          :key="index"
          class="c-carousel-screen__width"
          @click="filteredWidth = width"
        >
          <ring-width
            :width="width"
            :active="filteredWidth"
          />
        </div>
      </template>
    </div>
    <component
      :is="carouselComponent"
      :key="filteredWidth"
      :products="filteredProducts"
      class="u-margin-bottom-medium"
    />
    <div class="u-text-align-center">
      <Btn
        v-if="cta"
        v-bind="cta"
      >
        {{ cta.text }}
      </Btn>
    </div>
  </div>
</template>

<script>
import Btn from '@/components/button/Btn'
import get from 'lodash/get'
import ProductCardCarousel from '@/components/carousel/ProductCardCarousel'
import RingWidth from '@/components/ringWidth/RingWidth'
import { filterProductsByWidth } from '../../utils/organizeProducts'

export default {
  name: "CarouselScreen",
  components: {
    ProductCardCarousel,
    RingWidth,
    Btn
  },
  props: {
    carouselComponent: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    widths: {
      type: Array,
      default: () => []
    },
    products: {
      type: Array,
      default: () => []
    },
    cta: {
      type: Object,
      default:null
    }
  },
  data() {
    return {
      filteredWidth: '',
      filteredProducts: [],
      widthOrder: [
        'Wide',
        'Standard',
        'Thin',
        'Halo',
        'Stackable',
        'Accent',
      ]
    }
  },
  computed: {
    calWidths() {
      if (this.widths.length > 0) {
        return this.widths
      } else {
        let widths = [];
        for (const prod of this.products) {
          let width = get(prod, 'metafieldsMap.width.value')
          if (!widths.includes(width)) {
            widths.push(width)
          }
        }
        widths.sort((a,b)=>{
          return this.widthOrder.indexOf(a) - this.widthOrder.indexOf(b)
        })
        return widths;
      }

    },
  },
  watch: {
    filteredWidth() {
      this.filterProducts();
    }
  },
  created() {
    this.filteredWidth = this.calWidths[0]
  },
  methods: {
    filterProducts() {
      console.log("widths.length ", this.widths.length)
      if (this.calWidths.length > 1) {
        this.filteredProducts = filterProductsByWidth(this.products, this.filteredWidth)
      } else {
        this.filteredProducts = this.products;
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.c-carousel-screen {
  padding: $section-padding;

  &__width {
    &:not(:last-child) {
      margin-right: rem(10px);
    }
  }
  &__copy {
    max-width: rem(800px);
  }
}
</style>
