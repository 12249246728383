<template>
  <div class="p-store-locator">
    <div
      class="nosto_page_type"
      style="display:none"
    >
      other
    </div>
    <store-locator />
  </div>
</template>

<script>
import StoreLocator from '~/components/integrations/stockist/StoreLocator'
export default {
  components: {
    StoreLocator
  }
}
</script>
