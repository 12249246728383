var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.showScreen)?_c('div',{staticClass:"c-mini-pdp-screen l-container section"},[_c('product-info',{attrs:{"product":_vm.product,"products":_vm.pdpCollectionData.products,"bundle-products":_vm.bundleProducts,"bundle-options":_vm.bundleOptions,"mini-bundle":true,"disable-filter-bar":_vm.disableFilterBar},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [(!_vm.variantAvailableLoading)?[(_vm.showBackInStock(slotProps, _vm.variantAvailable))?_c('back-in-stock',{attrs:{"product":slotProps.product,"variant":slotProps.variant}}):_c('product-add-to-cart-button',_vm._b({},'product-add-to-cart-button',{
            ...slotProps,
            disabled: _vm.disableAtcBtn(slotProps),
            shoppingGivesDetected: false,
            allOptionsSelected: _vm.allOptionsSelected(slotProps),
            variant: _vm.variantProp(slotProps),
          },false))]:_vm._e()]}},(_vm.product && _vm.product.pimSyncSourceProductId)?{key:"rating",fn:function(){return [_c('star-rating',{staticClass:"u-margin-bottom-x-small",staticStyle:{"display":"inline-block"},attrs:{"product":_vm.product}})]},proxy:true}:null],null,true)})],1):_c('loader')
}
var staticRenderFns = []

export { render, staticRenderFns }