<template>
  <div class="sbs c-sbs l-grid l-grid--2-col">
    <div
      ref="img-card"
      v-observe-visibility="{
        callback: visibilityChanged,
        once: true
      }"
      class="l-flex"
    >
      <picture>
        <source
          v-if="visibility && cloudinaryCanAutoFormat"
          :srcset="optimizeSource({ url: imageUrl, format: 'auto' })"
        >
        <source
          v-if="visibility && reformat"
          :srcset="optimizeSource({ url: imageUrl, format: 'webp' })"
          type="image/webp"
        >
        <source
          v-if="visibility && reformat"
          :srcset="optimizeSource({ url: imageUrl, format: 'jpg' })"
          type="image/jpeg"
        >
        <img
          v-if="visibility"
          :src="imageUrl"
          :alt="alt"
          @error="fallback"
        >
      </picture>
    </div>
    <div
      class="c-sbs--content l-flex l-flex--column l-container"
      :style="backgroundColor ? `background-color: ${backgroundColor}` : null"
    >
      <slot
        name="body"
        :title="title"
        :contentHtml="contentHtml"
      >
        <div class="u-center-text">
          <component
            :is="titleTag"
            class="title"
          >
            {{ title }}
          </component>
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="content"
            v-html="contentHtml"
          />
          <!-- eslint-enable vue/no-vhtml -->
        </div>
      </slot>
      <slot
        name="cta"
        :ctaUrl="ctaUrl"
        :ctaText="ctaText"
        :ctaHandler="ctaHandler"
      >
        <p
          v-if="ctaText.length > 0"
          class="u-center-text"
        >
          <cta-button
            class="c-btn--primary"
            :to="ctaUrl"
            @clicked="ctaHandler"
          >
            {{ ctaText }}
          </cta-button>
        </p>
      </slot>
    </div>
  </div>
</template>

<script>
import CtaButton from '~/components/nacelle/CtaButton';
import imageOptimize from '~/mixins/imageOptimize';
import imageVisibility from '~/mixins/imageVisibility';

export default {
  components: {
    CtaButton,
  },
  mixins: [imageOptimize, imageVisibility],
  props: {
    backgroundColor: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
    imageUrl: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    contentHtml: {
      type: String,
      default: '',
    },
    containerRef: {
      type: String,
      default: 'img-card',
    },
    ctaText: {
      type: String,
      default: '',
    },
    ctaUrl: {
      type: String,
      default: '',
    },
    ctaHandler: {
      type: Function,
      default: () => {},
    },
    reverseDesktop: {
      type: Boolean,
      default: false,
    },
    reverseMobile: {
      type: Boolean,
      default: false,
    },
    titleTag: {
      type: String,
      default: 'h3',
    },
  },
  computed: {
    columnClasses() {
      const desktopReverse = this.reverseDesktop ? 'is-column-reverse' : '';
      const mobileReverse = this.reverseMobile ? 'is-mobile-column-reverse' : '';

      return `${desktopReverse} ${mobileReverse}`;
    },
    fallbackImage() {
      return this.imageUrl;
    },
  },
};
</script>

<style lang="scss" scoped>
.c-sbs {
  &--content {
    width: 100%;
  }
}
</style>

