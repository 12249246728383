<template>
  <div class="c-engraving-upsell u-margin-bottom-large">
    <div class="u-text-align-center u-margin-bottom-small">
      <h2 class="t-heading-4 u-margin-bottom-small">
        50% Off Engraving
      </h2>
      <p>If you weren't sure, let's make it easier for you, save 50% on engraving for a limited time! Make your ring one-of-a-kind with a custom engraving for you or a special someone.</p>
    </div>
    <div class="u-text-align-center u-margin-bottom-large">
      <img
        :src="image.src"
        :alt="image.alt"
        class="c-engraving-upsell__image"
      >
    </div>
    <Btn
      class="u-margin-bottom-small"
      :button-size="['thin', 'large']"
      @click="openEngravingList"
    >
      Engrave &amp; Save
    </Btn>
    <cart-flyout-checkout-button button-type="primary-open" />
  </div>
</template>
<script>
import CartFlyoutCheckoutButton from '~/components/cart/CartFlyoutCheckoutButton'
import Btn from '@/components/button/Btn'
export default {
  name: "EngravingUpsell",
  components: {
    CartFlyoutCheckoutButton,
    Btn,
  },
  props:{
    image: {
      type: Object,
      default: ()=>{
        return {}
      }
    }
  },
  methods: {
    openEngravingList() {
      this.$emit("openEngravingList")
    }
  }
}
</script>
<style lang="scss" scoped>
.c-engraving-upsell {
  &__image {
    max-height:150px;
  }
}
</style>
