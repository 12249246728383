import { render, staticRenderFns } from "./FeaturedCollectionsScreen.vue?vue&type=template&id=8d7d42c4&scoped=true&"
import script from "./FeaturedCollectionsScreen.vue?vue&type=script&lang=js&"
export * from "./FeaturedCollectionsScreen.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d7d42c4",
  null
  
)

export default component.exports