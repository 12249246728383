<template>
  <div class="c-cart-offer-bogo">
    <div class="c-cart-offer-bogo__offer">
      <p class="t-paragraph-small u-center-text">
        {{ message }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: 'BOGO'
    }
  }
}
</script>

<style lang="scss" scoped>
.c-cart-offer-bogo {
  width: 100%;
  height: 100%;
  &__offer {
    margin: rem(20px);
    padding: rem(20px);
    @include themify($themes) {
      background-color: themed('background', 'xx-light')
    }
  }
}
</style>
