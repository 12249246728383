<template>
  <nuxt-link v-utm-adder :to="`${to}?nosto=${nostoId}`">
    <slot />
  </nuxt-link>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      default: '/'
    },
    nostoId: {
      type: String,
      default: ''
    }
  }
}
</script>
