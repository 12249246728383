<template>
  <div
    v-if="showRecos"
    class="c-rebuy-collection-carousel u-margin-bottom-medium"
  >
    <h3 class="t-heading-3 u-text-align-center">{{ collectionTitle }}</h3>
    <product-carousel
      :products="products"
    />
  </div>
</template>

<script>
import get from 'lodash/get'
import ProductCarousel from '@/components/carousel/ProductCarousel'
import uniqBy from 'lodash/uniqBy'
export default {
  name: 'RebuyCollectionCarousel',
  props: {
    collectionHandle: {
      type: String,
      required: true
    }
  },
  components: {
    ProductCarousel
  },
  data() {
    return {
      collection: null,
      recos: null
    }
  },
  computed: {
    collectionTitle() {
      return get(this.collection, 'title')
    },
    showRecos() {
      return !!(this.recos && this.collection && this.recos.length)
    },
    products() {
      const recos = this.recos
      return uniqBy(recos, 'handle')
    }
  },
  async fetch() {
    if (this.collectionHandle) {
      const { products, collections } = await this.$api.getRecosByCollection({
        handle: this.collectionHandle,
      })
      this.collection = get(collections, '[0].collection')
      this.recos = products
    }
  },
  watch: {
    collectionHandle(value, oldValue) {
      if (value !== oldValue) {
        this.$fetch()
      }
    }
  },
  // mounted() { this.$fetch() }
}
</script>
