<template>
  <featuredCollections
    v-bind="$attrs"
    :collections-data="collectionsData"
  />
</template>

<script>
import { getCollectionsFromProps } from '~/mixins/getCollectionFromProps'
import FeaturedCollections from "@/components/featuredCollections/FeaturedCollections"

/**
 * wrap the stateless HomepageFeaturedRings component in a stateful context
 */
export default {
  components: {
    FeaturedCollections,
  },
  mixins: [
    getCollectionsFromProps('collections')
  ],
  props: {
    collections: {
      type: Array,
      default: () => []
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
