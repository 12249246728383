var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-cart-item",class:[
    'l-flex',
    'l-flex--row',
    'l-flex--justify-start'
  ]},[_c('router-link',{staticClass:"c-cart-item__link l-flex",attrs:{"to":`${_vm.pathFragment}${_vm.item.handle}`},nativeOn:{"click":function($event){return _vm.hideCart.apply(null, arguments)}}},[(_vm.productThumbnail && _vm.productThumbnail.length > 0)?_c('product-image',{staticClass:"c-cart-item__link-image",attrs:{"aria-hidden":"true","source":_vm.productThumbnail,"alt":_vm.item.title}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"c-cart-item__info"},[_c('router-link',{staticClass:"c-cart-item__title-link",attrs:{"to":`${_vm.pathFragment}${_vm.item.handle}`},nativeOn:{"click":function($event){return _vm.hideCart.apply(null, arguments)}}},[_c('product-title',{staticClass:"c-cart-item__title",attrs:{"title":_vm.item.title}})],1),_vm._v(" "),_c('div',{staticClass:"c-cart-item__details",class:[
        'l-flex',
        'l-flex--column',
        'l-flex--align-start'
      ]},[_c('product-variant-title',{staticClass:"c-cart-item__variant-title",attrs:{"title":_vm.variant.title,"item":_vm.item,"engraving-fee":_vm.engravingFee,"sleeve-fee":_vm.sleeveFee}}),_vm._v(" "),(_vm.isGift || _vm.item.discountApplied)?_c('nacelle-product-price',{attrs:{"product":_vm.item,"price":_vm.item.variant.price}}):_c('product-price',{attrs:{"price":_vm._f("currency")(_vm.item.variant.price),"compare-at-price":_vm._f("currency")(_vm.item.variant.compareAtPrice)}},[(_vm.item.variant.price < _vm.item.variant.compareAtPrice)?_c('product-price-pill',{attrs:{"color":"cyan"}},[_vm._v("\n          "+_vm._s(_vm.discount)+"% off\n        ")]):_vm._e(),_vm._v(" "),(_vm.isClearanceItem)?_c('product-price-pill',{attrs:{"color":"red"}},[_vm._v("\n          Final Sale\n        ")]):_vm._e()],1),_vm._v(" "),_c('quantity-selector',{staticClass:"c-cart-item__quantity",attrs:{"item":_vm.item,"quantity":_vm.item.quantity,"disabled":_vm.canChangeQuantity}}),_vm._v(" "),_c('cart-flyout-item-remove-button',{staticClass:"c-cart-item__remove-button",attrs:{"line-item":_vm.item}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }