<template>
  <button
    class="c-main-nav-cart"
    @click="toggleCart"
  >
    <img
      class="c-main-nav-cart__icon"
      src="~/assets/icons/cart-icon.svg"
      alt="cart"
    >
    <span
      v-if="quantityTotal > 0"
      class="c-main-nav-cart__count"
      :class="{
        'c-main-nav-cart__count--double-digits': quantityTotal > 10
      }"
    >
      {{ quantityTotal }}
    </span>
  </button>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: mapGetters('cart', ['quantityTotal']),
  methods: mapActions('cart', ['toggleCart'])
}
</script>

<style lang="scss" scoped>
.c-main-nav-cart {
  position: relative;
  background: none;
  border: 0;
  outline: 0;
  cursor: pointer;
  text-align: right;
  @include themify($themes) {
    color: themed('primary', 'base');
  }
  &:hover,
  &:focus {
    opacity: 0.8;
  }
  &__icon {
    height: rem(20px);
    @include respond(tab-land) {
      height: rem(28px);
    }
  }
  &__count {
    position: absolute;
    top: rem(-5px);
    right: rem(-5px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(16px);
    height: rem(16px);
    line-height: rem(16px);
    border-radius: 50%;
    @include themify($themes) {
      color: #fff;
      background-color: themed('primary', 'base');
    }
    &--double-digits {
      font-size: smaller;
    }
  }
}

.cart-count {
}
</style>
