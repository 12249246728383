<template>
  <btn
    class="c-checkout-button"
    :class="{
      'c-checkout-button--loading': loading
    }"
    :button-size="['large', 'thin']"
    :is-disabled="loading"
    :type="buttonType"
    @click="checkout"
  >
    <span class="c-checkout-button__text">
      {{ checkoutText }} now</span
    >
  </btn>
</template>

<script>
import Btn from '@/components/button/Btn'
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
export default {
  components: {
    Btn
  },
  props: {
    checkoutText: {
      type: String,
      default: 'Checkout'
    },
    buttonType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState('redo', {
      isExchange: ({ exchange }) => exchange
    }),
    ...mapGetters('cart', {
      cartSubtotal: 'cartSubtotal',
      lineItems: 'mappedLineItems'
    }),
    cartItems() {
      return this.lineItems
        // .filter(lineItem => lineItem.handle !== 'free-unlimited-return')
        .map(lineItem => {
          return {
            id: +atob(lineItem.variant.id)
              .split('/')
              .pop(),
            quantity: lineItem.quantity
          }
        })
    }
  },
  methods: {
    ...mapMutations('cart', ['setCartError']),
    ...mapActions('checkout', ['processCheckout']),
    async checkout() {
      /* Turn off Redo */
      /* if (this.isExchange) {
        console.log('exchange-select-complete', this.cartItems)
        if (opener) {
          opener.postMessage(
            {
              type: 'redo.exchange-select.complete',
              items: this.cartItems
            },
            '*'
          )
        }

        return
      } */

      this.loading = true
      const _instance = this
      try {
        await this.processCheckout({
          async beforeCreate() {
            // Allows processing of cart before checkout creation.
          },
          async beforeRedirect() {
            // Allows processing after checkout create and before redirecting.
            _instance.loading = false
          }
        })
      } catch (err) {
        console.error('checkout', err)
        this.setCartError(err)
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.c-checkout-button {
  &--loading {
    cursor: progress;
  }
}
</style>
