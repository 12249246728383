<template>
  <div
    v-if="!engravingProduct"
    class="c-cart-item"
    :class="['l-flex', 'l-flex--col']"
  >
    <div
      :class="[
        'c-cart-item__container',
        'l-grid',
        'l-grid--2-col-second-long',
        'l-grid--align-start'
      ]"
    >
      <product-image v-bind="itemImgAttrs">
        <template v-slot:discount-pill>
          <price-pill-under-image
            v-if="item.variant.price < item.variant.compareAtPrice"
            color="cyan"
          >
            {{ discount }}% off
          </price-pill-under-image>
        </template>
        <template v-slot:default>
          <price-pill-under-image
            v-if="isClearanceItem"
            color="red"
          >
            Final Sale
          </price-pill-under-image>
        </template>
      </product-image>
      <div>
        <div
          :class="[
            'c-cart-item__details',
            'l-flex',
            'l-flex--row',
            'l-flex--justify-between',
            'l-flex__nowrap',
            'l-flex--align-start',
          ]"
        >
          <div>
            <h4>{{ item | productTitle }}</h4>
            <p v-if="!defaultTitle" class="u-margin-bottom-xx-small">
              {{ item | variantTitle }}
            </p>
          </div>
          <div class="c-cart-item__price">
            <p v-if="item.variant.compareAtPrice" class="u-strikeout">
              ${{ item.variant.compareAtPrice }}
            </p>
            <p>${{ item.variant.price }}</p>
          </div>
        </div>
        <p
          class="t-heading-9"
          @click="active = !active"
          v-if="showBundleInfo"
        >
          Bundle Details
          <svg 
            width="11" 
            height="8" 
            viewBox="0 0 11 8" 
            fill="none"
            class="c-accordian__icon"
            :style="`${active ? 'transform: rotate(180deg)' :''}`"
          >
            <path
              d="M5.5 7.07002L0 1.57002L1.1 0.47002L5.5 4.87002L9.9 0.47002L11 1.57002L5.5 7.07002Z"
              fill="#022840"
            />
          </svg>
        </p>
        <div
          v-if="!showBundleInfo"
          :class="[
            'l-flex',
            'l-flex--column',
            'l-flex--align-start'
          ]">
          <cart-item-info
            class="u-margin-bottom-small"
            :item="item"
            :fees="fees"
            :engraving-fee="engravingFee"
          />
          <div :class="[
            'c-cart-item__info',
            'l-flex',
            'l-flex--justify-between',
            'l-flex--align-center'
          ]">
            <quantity-selector
              v-if="canChangeQuantity"
              :item="item"
              :quantity="item.quantity"
            />
            <cart-flyout-item-remove-button
              class="c-cart-item__remove-button"
              :line-item="item"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="showBundleInfo" class="c-cart-item__info">
      <cart-item-info
        v-if="active"
        :show-title="false"
        class="u-margin-bottom-small"
        :item="item"
        :fees="fees"
        :engraving-fee="engravingFee"
      />
      <div class="l-flex l-flex--justify-between l-flex--align-center">
        <quantity-selector
          v-if="canChangeQuantity"
          class="c-cart-item__quantity"
          :item="item"
          :quantity="item.quantity"
        />
        <cart-flyout-item-remove-button
          class="c-cart-item__remove-button"
          :line-item="item"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CartFlyoutItemRemoveButton from '~/components/cart/CartFlyoutItemRemoveButton'
import get from 'lodash/get'
import NacelleProductPrice from '~/components/nacelle/ProductPrice'
import ProductImage from '@/components/productImage/ProductImage'
import ProductPrice from '@/components/productPrice/ProductPrice'
import PricePillUnderImage from '@/components/productPricePill/PricePillUnderImage'
import ProductTitle from '~/components/nacelle/ProductTitle'
import ProductVariantTitle from '~/components/nacelle/ProductVariantTitle'
import QuantitySelector from '~/components/nacelle/QuantitySelector'
import CartItemInfo from './CartItemInfo'
import round from 'lodash/round'
import { mapMutations, mapGetters } from 'vuex'
export default {
  components: {
    CartItemInfo,
    ProductImage,
    ProductTitle,
    ProductPrice,
    NacelleProductPrice,
    ProductVariantTitle,
    QuantitySelector,
    CartFlyoutItemRemoveButton,
    PricePillUnderImage
  },
  filters: {
    productTitle(product) {
      return get(product, 'title')
    },
    variantTitle(product) {
      return get(product, 'variant.title')
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    pathFragment: {
      type: String,
      default: '/products/'
    },
    fees: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      active: false
    }
  },
  computed: {
    ...mapGetters('cart', ['productHasGiftWithPurchase']),
    itemImgAttrs() {
      const item = this.item
      const attrs = get(item, 'variant.featuredMedia') || {}
      return {
        ...attrs,
        product: item,
        size: '100x100'
      }
    },
    isGift() {
      return this.productHasGiftWithPurchase(this.item)
    },
    isBundle() {
      const item = this.item
      const isBundle = item.productType === 'Bundle'
      return isBundle
    },
    bundleItems() {
      const isBundle = this.isBundle
      const item = this.item
      let result = []
      if (isBundle && item.customAttributes) {
        for (let attr of item.customAttributes) {
          if (attr.key === '_local_bundle_selections') {
            result = attr.value
            break
          }
        }
      }
      return result
    },
    showBundleInfo() {
      const isBundle = this.isBundle
      const bundleItems = this.bundleItems
      return isBundle && bundleItems.length > 0
    },
    defaultTitle() {
      const title = get(this.item, 'variant.title')
      let result = false
      if (title == 'Default Title') {
        result = 'Bundle'
      }
      return result
    },
    canChangeQuantity() {
      const item = this.item
      return (
        !item.customAttributes ||
        !item.customAttributes[0] ||
        item.customAttributes[0].key != '_lineGiftWithPurchase'
      )
    },
    productThumbnail() {
      const item = this.item
      return (
        get(item, 'image.thumbnailSrc') ||
        get(item, 'featuredImage.thumbnailSrc') ||
        get(item, 'featuredMedia.thumbnailSrc') ||
        get(item, 'variant.featuredMedia.thumbnailSrc') ||
        ''
      )
    },
    variant() {
      const defaultVariant = {
        id: '',
        title: '',
        price: 0
      }

      if (this.item && this.item.variant) {
        return {
          ...defaultVariant,
          ...this.item.variant
        }
      }

      return defaultVariant
    },
    engravingFee() {
      let result = this.fees.filter(fee => {
        return fee.handle === 'engraving-fee'
      })
      if (result && result[0]) {
        return result[0].variants[0]
      }
      return null
    },
    sleeveFee() {
      let result = this.fees.filter(fee => {
        return fee.handle === 'bracelet-sleeve-fee'
      })
      if (result && result[0]) {
        return result[0].variants[0]
      }
      return null
    },
    isClearanceItem() {
      const product = this.item
      const tags = get(product, 'tags') || []
      return tags.includes('clearance')
    },
    discount() {
      const salePrice = this.item.variant.price
      const comparePrice = this.item.variant.compareAtPrice
      const integerPercent = ((comparePrice - salePrice) / comparePrice) * 100
      // console.log({ integerPercent, f: Math.floor(integerPercent), r: round(integerPercent) })
      return round(integerPercent)
    },
    engravingProduct() {
      const product = this.item
      const handle = product.handle
      let result = false
      if (handle && handle == 'engraving-fee') {
        result = true
      }
      return result
    }
  },
  methods: {
    ...mapMutations('cart', ['hideCart'])
  }
}
</script>

<style lang="scss" scoped>
.c-cart-item {
  position: relative;
  padding: rem(20px);
  width: 100%;
  &__link {
    max-width: rem(100px);
    margin-right: rem(20px);
  }
  &__link-image {
    max-width: rem(100px);
    width: rem(100px);
  }
  &__info {
    width: 100%;
  }
  &__price {
    margin-left: rem(20px);
    height: 100%;
  }
  &__container {
    width: 100%;
  }
  &__quantity {
    margin-left: rem(130px);
  }
  &__details {
    position: relative;
  }
  &__title-link {
    display: block;
    text-decoration: none;
    width: calc(100% - #{rem(24px)});
    @include themify($themes) {
      color: themed('primary', 'base');
    }
  }
  .l-grid--2-col-second-long {
    grid-template-columns: 0fr 2fr;
  }
}
</style>
