<template>
  <div
    :aria-hidden="!modalOpen"
    :aria-labelledby="ariaModalTargetId"
    role="dialog"
    class="c-interface-modal"
    :class="variantClassNames"
    @close="closeModal"
    @click="closeModal"
  >
    <transition name="c-interface-modal--tslide">
      <div
        v-show="modalOpen"
        class="c-interface-modal__wrapper"
        :class="{'c-interface-modal__wrapper--full':fullScreen}"
        @click.stop
      >
        <slot
          :tabindex="modalOpen ? 0 : undefined"
          @close="closeModal"
        />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    modalOpen: {
      type: Boolean,
      default: false
    },
    align: {
      type: String,
      default: 'left'
    },
    fullScreen: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ariaModalTargetId() {
      return `${this._uid}-interface-modal`
    },
    variantClassNames() {
      const modalOpen = this.modalOpen
      const align = this.align
      let result = []
      if (modalOpen) {
        result.push('c-interface-modal--open')
      }
      switch (align) {
      case 'left':
      case 'right':
        result.push(`c-interface-modal--align-${align}`)
        break
      }
      return result
    }
  },
  watch: {
    // in some contexts. margin top should be equal to the height of the nav +
    // global notification.
    async modalOpen() {
      if (this.modalOpen == true) {
        document.body.classList.add("u-overflow-hidden")
        try {
          if (this.$refs.modalTarget && this.$refs.modalTarget.focus) {
            await this.$nextTick()
            this.$refs.modalTarget.focus()
          }
        } catch (err) {
          console.warn(err)
        }
      } else {
        document.body.classList.remove("u-overflow-hidden")
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
    /*
    clickOuter({ target }) {
      if (target === this.$refs.outer) {
        this.closeModal()
      }
    }
    */
  },
}
</script>

<style lang="scss" scoped>
.c-interface-modal {
  position: relative;
  overflow: hidden;

  &__wrapper {
    position: fixed;
    top: 0px;
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    width: rem(500px);
    border-radius: rem(2px);
    height: 100vh;
    height: -webkit-fill-available;
    max-height: -webkit-fill-available;
    overflow: hidden;
    z-index: $zindex-modal;
    cursor: default;
    @include themify($themes) {
      background-color: themed('background', 'base');
    }
  }
  &--open {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: $zindex-modal-overlay;
    width: 100vw;
    height: 100vh;
    height: -webkit-fill-available;
    max-height: -webkit-fill-available;
    cursor: pointer;
    position: fixed;
    top: 0;
    overflow: hidden;
  }
  &--open &__wrapper {
    &--full {
      width: 100vw;
    }
    @include respond('phone') {
      width: 100vw;
      left: 0;
      right: 0;
    }
  }
  &--align-right {
    left: 0;
  }
  &--align-right &__wrapper {
    right: 0;
  }
  &--align-left &__wrapper {
    left: 0;
  }
  /* transition classes */
  &--tslide-enter-active,
  &--tslide-leave-active {
    transition: 0.6s;
  }
  &--align-right &--tslide-enter,
  &--align-right &--tslide-leave-to {
    transform: translate(100%, 0);
  }
  &--align-left &--tslide-enter,
  &--align-left &--tslide-leave-to {
    transform: translate(-100%, 0);
  }
}
</style>
