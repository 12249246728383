<template>
  <div
    class="c-quantity-updater"
    :class="[
      'l-flex'
    ]"
    :aria-describedby="ariaInputId"
  >
    <button
      :aria-describedby="ariaInputId"
      :class="[
        'c-quantity-updater__switch',
        `c-quantity-updater__switch${ miniCartVariant ? '--variant' : ''}`,
        'c-quantity-updater__switch--decrement'
      ]"
      aria-label="quantity decrease"
      @mousedown="start(decrement)"
      @touchstart.prevent="start(decrement)"
      @touchend.prevent="stop"
    >
      <span aria-hidden="true" v-if="miniCartVariant">&#x2212;</span>
      <span aria-hidden="true" v-else>&#9664;</span>
    </button>
    <input
      :id="ariaInputId"
      aria-label="line item quantity selector."
      class="c-quantity-updater__input"
      :class="!miniCartVariant ? '' : 'c-quantity-updater__input--variant'"
      type="text"
      :value="quantity"
      @input="update(Number($event.target.value))"
      :disabled="disabled"
    >
    <button
      :class="[
        'c-quantity-updater__switch',
        `c-quantity-updater__switch${ miniCartVariant ? '--variant' : ''}`,
        'c-quantity-updater__switch--increment'
      ]"
      aria-label="quantity increase"
      @mousedown="start(increment)"
      @touchstart.prevent="start(increment)"
      @touchend.prevent="stop"
    >
      <span aria-hidden="true" v-if="miniCartVariant">&#x2b;</span>
      <span aria-hidden="true" v-else>&#9654;</span>
    </button>
  </div>
</template>

<script>
import isFunction from 'lodash/isFunction'
import { mapActions, mapState } from 'vuex'
const timeInterval = 100

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    quantity: {
      type: Number,
      default: 0,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      interval: null,
      startTime: null,
      handler: Function
    }
  },
  computed: {
    ...mapState('vwo',['miniCart']),
    ariaInputId() {
      return `${this._uid}-input`
    },
    miniCartVariant() {
      const miniCart = this.miniCart
      let result = false
      if(miniCart && miniCart.variant1) {
        result = true
      }
      return result
    }
  },
  methods: {
    ...mapActions('cart', ['incrementLineItem', 'decrementLineItem']),
    /**
     *  Allows us to await a setTimeout by return a Promise
     * @param milliseconds - ms argument for setTimeout
     */
    wait(milliseconds) {
      return new Promise((resolve) => setTimeout(resolve, milliseconds))
    },
    /**
     *  Emits quantity update for sync modifier on parent.
     * @param quantity - new quantity value
     */
    update(quantity) {
      this.$emit('update:quantity', quantity)
    },
    /**
     *  Increase quantity. Type coersion prevents string concatenation.
     */
    increment() {
      if (this.item) {
        this.incrementLineItem(this.item.id)
      } else {
        this.update(Number(this.quantity) + 1)
      }
    },
    /**
     *  Decrease quantity. Prevents negative number.
     */
    decrement() {
      if (this.quantity > 0) {
        if (this.item) {
          this.decrementLineItem(this.item.id)
        } else {
          this.update(this.quantity - 1)
        }
      }
    },
    /**
     * Start a repetitive call to increment and decrement method after a
     * timeInterval on mousedown event and will stop on mouseup event on
     * controls
     * @param handler - increment or decrement method
     */
    async start(handler) {
      document.addEventListener('mouseup', this.stop)
      this.startTime = new Date()
      this.handler = handler
      await this.wait(500)
      clearInterval(this.interval)
      if (this.startTime) {
        this.interval = setInterval(handler, timeInterval)
      }
    },
    /**
     * clear interval on mouseup event and remove the listener
     * @param evt - event to be removed
     */
    stop(evt) {
      document.removeEventListener(evt.type, this.stop)
      if (this.handler && isFunction(this.handler)) {
        this.handler()
      }
      clearInterval(this.interval)
      this.interval = null
      this.handler = null
      this.startTime = null
    }
  }
}
</script>

<style lang="scss" scoped>
.c-quantity-updater {
  &__input {
    width: rem(30px);
    line-height: rem(30px);
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    color: inherit;
    text-align: center;
    margin-left: rem(10px);
    margin-right: rem(10px);
    &--variant {
      @include themify($themes) {
        border: 1px solid themed('foreground', 'input-border');
      }
      width: rem(50px);
      margin: 0;
    }
  }
  &__switch {
    line-height: rem(30px);
    text-align: center;
    cursor: pointer;
    border: none;
    background: none;
    transition: background 0.2s ease;
    font-size: 8px;
    &:hover {
      background: #f5f5f5;
    }
    &--variant {
      width: rem(30px);
      @include themify($themes) {
        border: 1px solid themed('foreground', 'input-border');
      }
      font-size: 18px;
    }
  }
}
</style>
