<template>
  <div>
    <component
      :is="formType"
      :list-id="listId"
      :form-title="formTitle"
      :form-inputs="formInputs"
      :form-type="formType"
      :endpoint="endpoint"
      :show-wholesale-netsuite-form="showWholesaleNetsuiteForm"
    />
  </div>
</template>

<script>
import EmailCapture from '@/components/emailCapture/EmailCapture'
import Contact from '@/components/contact/Contact'

export default {
  name: 'InputForm',
  components: {
    EmailCapture,
    Contact
  },
  props: {
    formTitle: {
      type: String,
      default: ''
    },
    formType: {
      type: String,
      default: ''
    },
    formInputs: {
      type: Array,
      default: () => []
    },
    endpoint: {
      type: String,
      default: 'https://hooks.zapier.com/hooks/catch/1353692/o351b1g/'
    },
    listId: {
      type: String,
      default: ''
    },
    showWholesaleNetsuiteForm: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss"></style>
