<template>
  <button
    title="Remove line item."
    class="c-cart-item-remove-button"
    @click="removeLineItem(lineItem)"
  >
    <slot>
      <img
        v-if="!miniCartVariant"
        aria-hidden="true"
        class="c-cart-item-remove-button__icon"
        src="~/assets/icons/default-close-icon.svg"
        alt="Remove"
      >
      <img
        v-else
        aria-hidden="true"
        class="c-cart-item-remove-button__icon c-cart-item-remove-button__icon--variant"
        src="~/assets/icons/cart-remove-icon.svg"
        alt="Remove"
      >
    </slot>
  </button>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  props: {
    lineItem: {
      type: Object,
      required: true
    }
  },
  methods: mapActions('cart', ['removeLineItem']),
  computed: {
    ...mapState('vwo',['miniCart']),
    miniCartVariant() {
      const miniCart = this.miniCart
      let result = false
      if(miniCart && miniCart.variant1) {
        result = true
      }
      return result
    }
  },
}
</script>

<style lang="scss" scoped>
.c-cart-item-remove-button {
  border: none;
  background: none;
  cursor: pointer;
  &__icon {
    width: rem(13px);
    height: rem(13px);
    &--variant {
      width: rem(22px);
      height: rem(22px);
    }
  }
}
</style>
